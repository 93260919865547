<template>
  <div></div>
</template>

<script>
export default {
  name: 'MetaData',
  props: {
    metaTitle: {
      type: String,
      required: false,
      default: 'DTM'
    },
    metaDescription: {
      type: String,
      required: false,
      default: 'DTM'
    },
    noIndex: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  head: {
    title: function () {
      return {
        inner: this.metaTitle
      };
    },
    meta: function () {
      if (this.noIndex) {
        return [
          { name: 'description', content: this.metaDescription, id: 'description' },
          { name: 'robots', content: this.noIndex, id: 'noIndex' }
        ];
      }
      return [
        { name: 'description', content: this.metaDescription, id: 'description' }
      ];
    },
    link: function () {
      if (process.env.NODE_ENV === 'production' && window.location.href.indexOf('dtm.com') > -1) {
        if (window.location.href.indexOf('www.dtm.com') > -1) {
          return [
            { rel: 'canonical', href: `https://dtm.com${this.$router.currentRoute.path}`, id: 'canonical' }
          ];
        } else {
          return [
            { rel: 'canonical', href: `https://www.dtm.com${this.$router.currentRoute.path}`, id: 'canonical' }
          ];
        }
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped>

</style>

<template>
    <div class="livetiming margin--bottom-4 margin--top-4">
      <div class="container">
        <iframe ref="iframe" :id="frameName" :name="frameName" src="https://racing-adac.liveresults.swisstiming.com/index.html?screen=racResults&profile=ADAC&live=true" width="100%" style="height: 80vh; border: none;"></iframe>
      </div>
    </div>
</template>

<script>

export default {
  name: 'WigeLivetiming',
  props: {
    event: { type: String, default: 'dtm' }
  },
  data () {
    return {
      frameName: 'liveStats'
    };
  },
  methods: {
    resize: function (event) {
      if (this.$refs.iframe && typeof event.data === 'object' && event.data.key) {
        switch (event.data.key) {
          case 'height':
            this.$refs.iframe.style.height = `${event.data.value}px`;
            break;
          default:
            break;
        }
      }
    },
    registerDomain: function () {
      const data = {
        key: 'host',
        value: window.location.origin
      };
      if (this.$refs.iframe) {
        try {
          this.$refs.iframe.postMessage(data, '*');
        } catch (e) {
          this.$refs.iframe.contentWindow.postMessage(data, '*');
        }
      }
    }
  },
  mounted () {
    Object.defineProperty(window.document, 'referrer', { get: function () { return window.location.origin; } });
    window.addEventListener('message', this.resize, false);
    this.registerDomain();
  },
  created () {
    // this.fetchStream();
    // this.liveInterval = setInterval(this.fetchStream, this.delayLive);
  },
  destroyed () {
    window.removeEventListener('message', this.resize, false);
  }
};
</script>

<style lang="scss">
.timing {
  width: 100%;
  border-collapse: collapse;
  color: inherit;
  tr {
    td, th {
      &.tl {
        text-align: left;
      }
      &.tr {
        text-align: right;
      }
      &.tc {
        text-align: center;
      }
    }
  }
  thead {
    background: rgba($color-bg-dark, 0.5);
    font-size: 0.8em;
    font-weight: 300;
    tr {
      td, th {
        white-space: nowrap;
        text-transform: uppercase;
        padding: 3px;
        padding-top: $gap / 2;
        font-weight: 100;
        &:first-child {
          padding-left: $gap;
        }
        &:last-child {
          padding-right: $gap;
        }
      }
    }
  }
  tbody {
    font-size: 0.9em;
    tr {
      transition: all .3s;
      &:nth-child(even) {
        background: rgba(255, 255, 255, 0.05);
      }
      td {
        white-space: nowrap;
        vertical-align: center;
        padding: 1px 3px;
        transition: color .3s;
        &:first-child {
          padding-left: $gap;
        }
        &:last-child {
          padding-right: $gap;
        }
        &.padding {
          padding: 1px 6px;
        }
        &.padding-right {
          padding-right: 12px;
        }
        &.padding-left {
          padding-right: 12px;
        }
        .timing__icon {
          fill: none;
          transition: all .3s $ease-out-back;
          transform: translate(0, 3px);
          &.timing__icon--up {
            transform: rotate(90deg) translate(3px, 0);
            fill: #0f0;
          }
          &.timing__icon--down {
            transform: rotate(-90deg) translate(-3px, 0);
            fill: #f00;
          }
        }
      }
      &:hover {
        background: rgba(#fff, 0.1);
      }
    }
  }
}
.bg-white .timing {
  thead {
    color: #fff;
  }
  tbody {
    color: #111;
    tr {
      &:nth-child(even) {
        background: rgba($color-bg, 0.05);
      }
    }
  }
}
</style>

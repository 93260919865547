const settings = {
  baseUrl: 'https://www.dtm.com',
  nodeApi: {
    uri: 'https://api.dtm.com/' // https://pl7uybf77e.execute-api.eu-central-1.amazonaws.com/prod/'
  },
  LIFETIME_DEFAULT: 600000,
  LIFETIME_STATIC: 1800000,
  LIFETIME_SHORT: 60000,
  azure: {
    credentials: {
      tenantName: 'b2cdtmprod',
      clientId: 'af027687-5b37-4a88-bfd7-dcef30f26ba1'
    },
    policies: {
      signUpSignIn: 'b2c_1a_signup_signin',
      editProfile: 'b2c_1a_profileedit',
      passwordReset: 'b2c_1a_passwordrese'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://b2cdtmprod.b2clogin.com/b2cdtmprod.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_signup_signin'
      },
      editProfile: {
        authority: 'https://b2cdtmprod.b2clogin.com/b2cdtmprod.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_profileedit'
      },
      passwordReset: {
        authority: 'https://b2cdtmprod.b2clogin.com/b2cdtmprod.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_passwordreset'
      }
    }, // we generate them later
    authorityDomain: 'b2cdtmprod.onmicrosoft.com',
    loginDomain: 'b2cdtmprod.b2clogin.com',
    redirectUri: 'https://localhost:8080/redirect',
    webApiUri: '',
    metadata: {
      authority: 'login.microsoftonline.com',
      discovery: '',
      version: ''
    }
  }
};
export default settings;

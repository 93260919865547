<template>
  <div id="future-stage">
    <meta-data v-if="page && page.metaTitle"
               :key="page.metaTitle"
               :meta-title="page.metaTitle"
               :meta-description="page.metaDescription"
               :no-index="page.noIndex"/>
    <template v-for="(moduleEntry, moduleIndex) in page.modules">
      <livestream v-if="moduleEntry.module === 'livestream'" :key="`livestream-${moduleIndex}`"/>
      <teaser v-if="moduleEntry.module === 'teaser'"
              :key="`teaser-${moduleEntry.headlineId}`"
              :headlineId="moduleEntry.headlineId"
              :textId="moduleEntry.textId"/>
      <event-guide v-if="moduleEntry.module === 'event-guide'" :key="`event-guide-${moduleIndex}`"/>
      <content-slider v-if="moduleEntry.module === 'content-slider'"
                      :key="`content-slider-${moduleEntry.id}`"
                      :id="moduleEntry.id"
                      :future-stage="moduleEntry.futureStage"/>
      <walls-io v-if="moduleEntry.module === 'social'"
                :key="`social-${moduleIndex}`"
                :primary-headline="moduleEntry.primaryHeadline"
                :secondary-headline="moduleEntry.secondaryHeadline"
                :wall-name-prop="moduleEntry.wallName"/>
    </template>
  </div>
</template>

<script>
import MetaData from '../components/MetaData';
import Livestream from '../components/future-stage/Livestream';
import Teaser from '../components/future-stage/Teaser';
import EventGuide from '../components/future-stage/EventGuide';
import WallsIo from '../components/WallsIo';
import ContentSlider from '../components/ContentSlider';
import AppConst from '../config/app.const';
import Settings from '../config/settings';

export default {
  name: 'FutureStage',
  components: {
    WallsIo,
    EventGuide,
    Teaser,
    Livestream,
    ContentSlider,
    MetaData
  },
  data () {
    return {
      loading: false,
      load3d: false,
      page: {}
    };
  },
  methods: {
    async fetchPage () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=page&slug=future',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.page = response.pages[0];
          this.$store.commit(AppConst.ADD_LOCALIZATIONS, this.page.localizations);
          this.$store.commit('page/SET_RACESERIES', (this.page.raceSeries.length ? this.page.raceSeries : ['DTM']));
          this.$store.dispatch('page/raceSeriesLoaded', false);
        })
        .catch(() => {
          setTimeout(this.fetchPage, 3000);
        });
    }
  },
  watch: {
    $route () {
      this.fetchPage();
    }
  },
  created () {
    this.fetchPage();
  }
};
</script>

const settings = {
  baseUrl: 'https://sw-dev.d7mcl0ud.de',
  nodeApi: {
    uri: 'https://pl7uybf77e.execute-api.eu-central-1.amazonaws.com/prod/'
  },
  LIFETIME_DEFAULT: 0,
  LIFETIME_STATIC: 0,
  LIFETIME_SHORT: 0
};
export default settings;

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function getDefaultLang () {
  const languages = Object.getOwnPropertyNames(loadLocaleMessages());
  const navigatorLang = getNavigatorLang(languages);
  const urlLang = getUrlLang(languages);
  return urlLang || navigatorLang || 'en';
}

function getNavigatorLang (languages) {
  let matched = null;
  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}

function getUrlLang (languages) {
  let matched = null;
  const localeFromUrl = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
  if (localeFromUrl && localeFromUrl !== '/') {
    languages.forEach(lang => {
      if (localeFromUrl === lang) {
        matched = localeFromUrl;
      }
    });
  }
  return matched;
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getDateTimeFormats () {
  return {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      month: {
        month: 'short'
      },
      monthLong: {
        month: 'long'
      },
      dayAndDate: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric'
      }
    },
    de: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      month: {
        month: 'short'
      },
      monthLong: {
        month: 'long'
      },
      dayAndDate: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric'
      }
    },
    ru: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      month: {
        month: 'short'
      },
      monthLong: {
        month: 'long'
      },
      dayAndDate: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric'
      }
    }
  };
}

export default new VueI18n({
  locale: getDefaultLang() || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats: getDateTimeFormats()
});

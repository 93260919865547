<template>
  <div class="news-detail" v-if="news">
    <meta-data
      :meta-title="news.metaTitle"
      :meta-description="news.metaDescription"
      :key="news.metaTitle"/>
    <page-header
      :headline="news ? news.headline : ' '"
      :sub-headline="news ? news.topline : ' '"
      :break-after-to-white="true"></page-header>
    <div class="bg-white padding--top-4 padding--bottom-4 text-default">
      <div class="container-fluid">
        <loader v-if="loading" :inverted="true"></loader>
        <div class="row">
          <div class="col-12">
            <div class="news-detail__img-wrapper">
              <background-image
                v-if="news.image"
                :url="news.image.url"
                :title="news.headline"
                :width="news.image.width"
                :height="news.image.height"
                :scale="'16-9'"
                :scroll-scale="false"
                :loaded="true"/>
            </div>
          </div>
        </div>
        <div v-if="news" class="padding--top-2 font-small">
          <div>{{ news.newsDate }}</div>
          <div class="font-weight--bold">
            <span v-for="(tag, i) in news.tags" class="news__tag" :key="`tag-${i}`">#{{ tag.title }}</span>&nbsp;
          </div>
        </div>
        <template v-for="(moduleEntry) in sortedModules">
          <plain-text v-if="moduleEntry && moduleEntry.name === 'text'" :key="`text-${moduleEntry.id}`" :id="moduleEntry.id" :class="'text-default'"/>
          <youtube v-if="moduleEntry && moduleEntry.name === 'video'" :key="`youtube-${moduleEntry.id}`" :video-id="moduleEntry.videoId" :class="'view padding--top-4 padding--bottom-4'"/>
          <image-text v-if="moduleEntry && moduleEntry.name === 'imageText'" :key="`image-text-${moduleEntry.id}`" :id="moduleEntry.id" :class="'text-default'"/>
          <image-gallery v-if="moduleEntry && moduleEntry.name === 'gallery'" :key="`gallery-${moduleEntry.id}`" :id="moduleEntry.id" :slidesPerView="moduleEntry.slidesPerView || 1"/>
        </template>
        <template v-for="(moduleEntry, moduleIndex) in news.modules">
          <results v-if="moduleEntry.module === 'results'" :key="`results-${moduleIndex}`" :settings="moduleEntry"></results>
          <standings v-if="moduleEntry.module === 'standings'" :key="`standings-${moduleIndex}`" :settings="moduleEntry"></standings>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import PlainText from '../components/PlainText';
import ImageText from '../components/ImageText';
import MetaData from '../components/MetaData';
import Loader from '../components/layout/Loader';
import PageHeader from '../components/layout/PageHeader';
import BackgroundImage from '../components/layout/BackgroundImage';
import Youtube from '../components/Youtube';
import ImageGallery from '../components/ImageGallery';
import Settings from '../config/settings';
import Results from '@/components/Results';
import Standings from '@/components/Standings';

export default {
  name: 'NewsDetail',
  components: {
    Standings,
    Results,
    ImageGallery,
    Youtube,
    BackgroundImage,
    PageHeader,
    Loader,
    MetaData,
    ImageText,
    PlainText
  },
  data () {
    return {
      slug: this.$route.params.slug,
      news: null,
      loading: true,
      sortedModules: []
    };
  },
  methods: {
    async fetchNewsDetail () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=newsDetail&slug=${this.slug}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.news = response.newses[0];
          const published = new Date(this.news.publishingdate || this.news.updatedAt);
          this.news.newsDate = ('00' + published.getDate()).slice(-2) + '.' + this.$i18n.d(published, 'monthLong') + ' ' + published.getFullYear();
          this.loading = false;
          this.$store.commit(AppConst.ADD_LOCALIZATIONS, this.news.localizations);
          this.$store.commit('page/SET_RACESERIES', ([this.news.raceSeries ? this.news.raceSeries : 'DTM']));
          this.$store.dispatch('page/raceSeriesLoaded', false);
          this.sortModules();
        });
    },
    sortModules () {
      this.news.textModules.forEach((textEntry, key) => {
        textEntry.name = 'text';
        textEntry.order = textEntry.order ? textEntry.order : key;
        this.sortedModules[textEntry.order] = textEntry;
      });
      this.news.imageTextModules.forEach((imageTextEntry, key) => {
        imageTextEntry.name = 'imageText';
        imageTextEntry.order = imageTextEntry.order ? imageTextEntry.order : key;
        this.sortedModules[imageTextEntry.order] = imageTextEntry;
      });
      this.news.videoModules.forEach((videoEntry, key) => {
        videoEntry.name = 'video';
        videoEntry.order = videoEntry.order ? videoEntry.order : key;
        this.sortedModules[videoEntry.order] = videoEntry;
      });
      this.news.galleryModules.forEach((galleryEntry, key) => {
        galleryEntry.name = 'gallery';
        galleryEntry.order = galleryEntry.order ? galleryEntry.order : key;
        this.sortedModules[galleryEntry.order] = galleryEntry;
      });
      // sort array ASC
      this.sortedModules.sort((a, b) => a.order - b.order);
    }
  },
  watch: {
    '$route.params.slug': function () {
      this.fetchNewsDetail();
    }
  },
  mounted () {
    this.fetchNewsDetail();
  }
};
</script>

<style lang="scss">
  .news-detail {
    &__img-wrapper {
      position: relative;
      overflow: hidden;
    }
  }
</style>

<template>
  <div class="address-image bg-white margin--top-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="h4 font-weight--light text-italic margin--bottom-1">
            {{ $t('raceCalendar.arrival') }}
          </div>
          <div class="h2 margin--bottom-2">
            {{ $t('raceCalendar.howToGetThere') }}
          </div>
        </div>
        <div class="col-12 address-image__img-behind">
          <div class="overflow--hidden position-relative">
            <background-image
              :scale="'16-9'"
              :scroll-scale="false"
              :url="`https://media.graphassets.com/quality=value:70/compress/${image.handle}`"
              :loaded="true"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex align-content-center justify-content-center margin--top-2 margin--bottom-8">
          <a :href="route"
             target="_blank"
             class="button-ghost address-image__load-more">
            {{ $t('raceCalendar.planYourTrip') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '../layout/BackgroundImage';
export default {
  name: 'AddressImage',
  components: { BackgroundImage },
  props: {
    image: {
      type: Object,
      required: true
    },
    imageAlt: {
      type: String,
      required: false
    },
    imageTitle: {
      type: String,
      required: false
    },
    route: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
 .address-image {
   &__load-more {
     cursor: pointer;
     border-width: inherit;
     border-style: inherit;

     &:focus {
       outline: none;
     }
   }
   &__img-behind {
     position: relative;
     z-index: 1;
     width: 100%;
     height: 100%;
     &::after {
       content: '';
       background: $color-bg;
       position: absolute;
       top: -40px;
       bottom: 0;
       right: -25px;
       width: 40%;
       height: 90%;
       z-index: -1;
     }
   }
 }
 @media all and (max-width: map_get($grid-breakpoints, 'md')) {
   .address-image {
     &__img-behind {
       &::after {
         display: none;
       }
     }
   }
 }
</style>

export default {
  namespaced: true,
  state: {
    scrollTop: -1,
    winHeight: 0,
    winWidth: 0
  },
  getters: {
    scrollTop: (state) => state.scrollTop,
    winHeight: (state) => state.winHeight,
    winWidth: (state) => state.winWidth,
    resize: (state) => [state.winWidth, state.winHeight]
  },
  mutations: {
    scrollTop: (state, value) => {
      state.scrollTop = value;
    },
    resize: (state) => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      if (state.winHeight !== h || state.winWidth !== w) {
        state.winWidth = w;
        state.winHeight = h;
      }
    }
  },
  actions: {}
};

<template>
  <div class="event-statistic-line position-relative" :class="background">
    <div class="h4 text-primary mb-1" v-html="$t('results.course')"></div>
    <div class="font-small" v-html="$t('results.courseDescription')"></div>
    <div class="position-relative">
      <svg ref="svg" width="100%" height="500" v-scroll:in="showChart">
        <defs>
          <linearGradient id="gradient-statistic-highlight" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stop-color="#DDFE81" stop-opacity="0.2"/>
            <stop offset="50%" stop-color="#DDFE81" stop-opacity="0"/>
          </linearGradient>
        </defs>
        <g ref="gX" class="g--grid g--grid-x">
          <path v-for="(grid, i) in grid.x" :key="`grid-x-${i}`" :d="grid.d" class="path" :class="{ highlight: popup.raceNumber === i + 1 }"></path>
        </g>
        <g ref="gY" class="g--grid">
          <path v-for="(grid, i) in grid.y" :key="`grid-y-${i}`" :d="grid.d" class="path" :class="{ highlight: driverPosition === i + 1 }"></path>
          <text v-for="(grid, i) in grid.y" x="15" :y="(grid.y * 1 + 3)" :key="`text-y-${i}`" :class="{ highlight: driverPosition === i + 1 }">{{ (i + 1) }}</text>
        </g>
        <g ref="gDg" class="g--driver-gradient">
          <template v-for="(_driver, i) in drivers">
            <path :key="`driver-grad-${i}`" class="path"
                  v-if="gradients[_driver.id] && _driver.thisDriver"
                  fill="url(#gradient-statistic-highlight)"
                  :d="gradients[_driver.id]"></path>
          </template>
        </g>
        <g ref="gD" class="g--driver">
          <template v-for="(_driver, i) in drivers">
            <path :key="`driver-${i}`" class="path"
                  :id="`thisDriver: ${_driver.thisDriver} driverId: ${_driver.id}`"
                  :class="{ 'highlight': _driver.thisDriver }"
                  v-if="paths[_driver.id] && !_driver.thisDriver"
                  :d="paths[_driver.id]"></path>
          </template>
          <template v-for="(_driver, i) in drivers">
            <path :key="`driver-${i}`"
                  :class="{ 'highlight': _driver.thisDriver }"
                  v-if="paths[_driver.id] && _driver.thisDriver"
                  :id="`thisDriver: ${_driver.thisDriver} driverId: ${paths[_driver.id]}`"
                  :d="paths[_driver.id]"></path>
          </template>
        </g>
        <g ref="gBo" class="g--driverbullet--other">
          <template v-for="(race, raceI) in standings">
            <template v-for="(pos, posI) in race">
              <circle :key="`pos-${raceI}-${pos.driver}`" :cx="pos.x" :cy="pos.y"
                      @click="showPopup(posI + 1, pos, race)"
                      @mouseenter="showPopup(posI + 1, pos, race)"
                      @mouseleave="hidePopup()"
                      v-if="!pos.thisDriver"
                      :title="pos.name"></circle>
            </template>
          </template>
        </g><g ref="gB" class="g--driverbullet">
          <template v-for="(race, raceI) in standings">
            <template v-for="(pos, posI) in race">
              <circle :key="`pos-${raceI}-${pos.driver}`" :cx="pos.x" :cy="pos.y"
                      :class="{ 'active': pos.thisDriver }"
                      @click="showPopup(posI + 1, pos, race)"
                      @mouseenter="showPopup(posI + 1, pos, race)"
                      @mouseleave="hidePopup()"
                      v-if="pos.thisDriver"
                      :title="pos.name"></circle>
            </template>
          </template>
        </g>
      </svg>
      <div class="event-statistic__popup"
           :class="{ view: popup.view, bottom: popup.y > height * 0.7, left: popup.x < 150 && width > 450, right: popup.x > width - 150 && width > 450 }"
           :style="{ left: width > 450 ? `${popup.x}px` : '50%', top: `${popup.y}px` }">
        <div class="event-statistic__popup__inner" :class="{ short: popup.position < 10 }">
          <div class="font-weight--bold driver" v-html="popup.driver"></div>
          <div v-html="popup.text"></div>
          <div v-html="`#${popup.raceNumber} ${popup.race}`"></div>
          <div class="font-weight--bold pos" v-html="popup.position"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WigeDataModel from '@/entity/WigeDataModel';
import anime from 'animejs';

export default {
  name: 'EventStatisticLine',
  props: {
    driver: {
      type: Object
    },
    data: {
      type: WigeDataModel
    },
    background: {
      type: String,
      default: ''
    },
    darkMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      height: 0,
      width: 0,
      grid: {
        x: [],
        y: []
      },
      standings: {},
      paths: {},
      gradients: {},
      lastRaceWithValues: -1,
      highlight: '',
      driverPosition: 0,
      drivers: {},
      loaded: false,
      animating: false,
      animated: false,
      popup: {
        show: false,
        view: false,
        driver: '',
        position: 0,
        raceNumber: -1,
        race: '',
        x: '50%',
        y: '50%',
        left: false,
        right: false,
        top: false,
        bottom: true
      }
    };
  },
  methods: {
    resize () {
      const width = this.$refs.svg.clientWidth;
      const height = this.$refs.svg.clientHeight;
      if (width !== this.width || height !== this.height) {
        this.width = width;
        this.height = height;
        this.calculateGrid();
      }
    },
    calculateGrid () {
      const startX = 30;
      const endX = 15;
      const startY = 30;
      if (this.data && this.data._event && this.drivers.length) {
        const gridX = [];
        const gridY = [];
        const amountX = this.width < 600 ? Object.keys(this.standings).length : this.drivers[0].races.length;
        this.grid.amountX = amountX;
        const amountY = this.drivers.length;
        this.grid.amountY = amountY;
        const gapY = ((this.height - (startY * 2)) / (amountY - 1)).toFixed(1);
        const gapX = ((this.width - startX - endX) / (amountX - 1)).toFixed(1);
        for (let i = 0; i < amountY; i++) {
          const x0 = startX - 10;
          const x1 = this.width;
          const y0 = (startY + (i * gapY)).toFixed(1);
          const y1 = y0;
          const line = {
            title: this.drivers[i].name,
            d: `M ${x0},${y0} ${x1},${y1} z`,
            y: y0
          };
          gridY.push(line);
        }
        const lastY = gridY[gridY.length - 1].y;
        for (let i = 0; i < amountX; i++) {
          const x0 = (startX + (i * gapX)).toFixed(1);
          const x1 = x0;
          const y0 = lastY;
          const y1 = startY;
          const line = {
            title: this.drivers[0].races[0].name,
            d: `M ${x0},${y0} ${x1},${y1} z`,
            x: x0
          };
          gridX.push(line);
        }
        this.grid.x = gridX;
        this.grid.y = gridY;
        const paths = {};
        this.drivers.forEach(driver => {
          paths[driver.id] = '';
        });
        Object.keys(this.standings).forEach((raceName, raceI) => {
          const race = this.standings[raceName];
          race.forEach((pos, i) => {
            const driver = this.drivers.find((d) => d.id === pos.driver);
            pos.x = (startX + (raceI * gapX)).toFixed(1);
            pos.y = (startY + (i * gapY)).toFixed(1);
            if (driver) {
              if (paths[pos.driver] === '') {
                paths[pos.driver] = 'M ';
              }
              paths[pos.driver] = `${paths[pos.driver]} ${pos.x},${pos.y}`;
            }
          });
        });
        this.drivers.forEach(driver => {
          if (paths[driver.id] && driver.thisDriver) {
            const split = paths[driver.id].split(' ');
            const last = split[split.length - 1].split(',');
            const first = split[2].split(',');
            const lowest = Math.max(last[1] * 1, first[1] * 1);
            let lowestY = lowest + this.height * 0.6;
            if (lowestY > this.height) {
              lowestY = this.height;
            }
            this.gradients[driver.id] = `${paths[driver.id]} ${last[0]},${lowestY} ${first[0]},${lowestY} z`;
          }
        });
        this.paths = paths;
        this.$nextTick(() => {
          if (!this.loaded) {
            this.loaded = true;
          }
          if (this.animated) {
            anime({
              targets: this.$refs.gD.querySelectorAll('path'),
              strokeDashoffset: [anime.setDashoffset, 0],
              duration: 0
            });
          }
        });
      }
    },
    showChart () {
      if (this.loaded && !this.animating) {
        this.animating = true;
        const inter = setInterval(() => {
          if (this.$refs.gD) {
            const paths = this.$refs.gD.querySelectorAll('path');
            if (paths && paths.length) {
              clearInterval(inter);
              this.$nextTick(() => {
                // anime({
                //   targets: this.$refs.gX.querySelectorAll('path'),
                //   strokeOpacity: 1,
                //   delay: (el, i) => i * 50,
                //   duration: 300,
                //   easing: 'linear'
                // });
                anime({
                  targets: this.$refs.gY.querySelectorAll('path'),
                  strokeOpacity: 0.5,
                  delay: (el, i) => i * 20,
                  duration: 300,
                  easing: 'linear'
                });
                anime({
                  targets: this.$refs.gD.querySelectorAll('path'),
                  stokeOpacity: 1,
                  strokeDashoffset: [anime.setDashoffset, 0],
                  duration: Math.max(800, 200 * Object.keys(this.standings).length),
                  delay: (el, i) => 500 + i * 20,
                  easing: 'easeOutCubic',
                  begin: (anim) => {
                    anim.animatables.forEach(el => {
                      el.target.classList.add('view');
                    });
                  },
                  complete: () => {
                    anime({
                      targets: this.$refs.gDg.querySelectorAll('path'),
                      fillOpacity: 1,
                      duration: 500,
                      easing: 'linear'
                    });
                  }
                });
                anime({
                  targets: this.$refs.gB.querySelectorAll('circle'),
                  r: [0, 5],
                  strokeWidth: [0, 12],
                  fillOpacity: 1,
                  strokeOpacity: 0.3,
                  duration: 400,
                  easing: 'easeOutCubic',
                  delay: (el, i) => 100 + this.drivers.length * 20,
                  begin: (anim) => {
                    anim.animatables.forEach(el => {
                      el.target.classList.remove('interactive');
                    });
                  },
                  complete: (anim) => {
                    anim.animatables.forEach(el => {
                      el.target.classList.add('interactive');
                    });
                    this.animated = true;
                  }
                });
                anime({
                  targets: this.$refs.gBo.querySelectorAll('circle'),
                  r: [0, 2],
                  strokeWidth: [0, 6],
                  fillOpacity: 1,
                  strokeOpacity: 0.3,
                  duration: 400,
                  easing: 'easeOutCubic',
                  delay: (el, i) => 100 + this.drivers.length * 20,
                  begin: (anim) => {
                    anim.animatables.forEach(el => {
                      el.target.classList.remove('interactive');
                    });
                  },
                  complete: (anim) => {
                    anim.animatables.forEach(el => {
                      el.target.classList.add('interactive');
                    });
                    this.animated = true;
                  }
                });
              });
            }
          }
        }, 10);
      }
    },
    calculateRankings () {
      const standings = {};
      this.lastRaceWithValues = -1;
      const drivers = [];
      this.data._event.drivers.forEach(driver => {
        let raceIndex = 0;
        driver.races.forEach((race, raceI) => {
          if (race.points * 1 > 0 && this.lastRaceWithValues < raceIndex) {
            this.lastRaceWithValues = raceIndex;
          }
          raceIndex++;
        });
        drivers.push(driver);
      });
      drivers.forEach((driver) => {
        let currentPoints = 0;
        let currentRacePoints = 0;
        let raceIndex = 0;
        driver.d = '';
        driver.thisDriver = this.data.getDriverSlug(driver, this.data._event) === this.driver.slug;
        if (driver.thisDriver) {
          this.driverPosition = driver.position * 1;
        }
        driver.races.forEach((race, raceI) => {
          if (raceIndex <= this.lastRaceWithValues) {
            currentPoints += race.points * 1;
            currentRacePoints += race.racepoints * 1;
            if (!(raceIndex in standings)) {
              standings[raceIndex] = [];
            }
            standings[raceIndex].push({
              driver: driver.id,
              raceNumber: raceIndex + 1,
              race: race.name,
              thisDriver: driver.thisDriver,
              name: driver.name,
              firstName: driver.firstName,
              lastName: driver.lastName,
              points: currentPoints * 1,
              racePoints: currentRacePoints * 1,
              differencePoints: race.points * 1
            });
          }
          raceIndex++;
        });
      });
      Object.keys(standings).forEach((raceName, i) => {
        // do not sort the last race
        if (i < Object.keys(standings).length - 1) {
          standings[raceName].sort((a, b) => (b.points - a.points || a.name.localeCompare(b.name)));
        }
      });
      this.drivers = drivers;
      this.standings = standings;
    },
    showPopup (position, driver, race) {
      this.popup.position = position;
      this.popup.driver = `${driver.firstName} ${driver.lastName}`;
      this.popup.text = `${driver.points} ${this.$t('results.points')} (${driver.differencePoints > 0 ? '+' : ''}${driver.differencePoints})`;
      this.popup.raceNumber = driver.raceNumber;
      this.popup.race = driver.race;
      this.popup.x = driver.x;
      this.popup.y = driver.y;
      this.$nextTick(() => {
        this.popup.show = true;
        this.popup.view = true;
      });
    },
    hidePopup () {
      this.popup.show = false;
      setTimeout(() => {
        if (this.popup.show === false) {
          this.popup.view = false;
        }
      }, 500);
    }
  },
  mounted () {
    this.calculateRankings();
    window.addEventListener('resize', this.resize);
    this.resize();
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resize);
  }
};
</script>

<style lang="scss" scoped>
.event-statistic__popup {
  position: absolute;
  background: rgba($color-bg-dark, 0.7);
  backdrop-filter: blur(3px);
  padding: $gap;
  border-radius: 3px;
  box-shadow: 0 3px 10px rgba($color-bg-dark, 0.4);
  transition: all .4s $ease-out-cubic;
  pointer-events: none;
  opacity: 0;

  &.view {
    opacity: 1;
  }

  .pos {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: right;
    width: 70px;
    transition: all .3s;
    transform: translate(0, -50%);
    font-size: 3em;
    color: $color-primary;
  }

  &__inner {
    position: relative;
    padding-left: 80px;
    white-space: nowrap;
    transition: all .3s;
    &.short {
      padding-left: 50px;
      .pos {
        width: 40px;
      }
    }
  }

  --tx: -50%;
  --ty: 20px;
  &.bottom {
    --ty: calc(-100% - 20px);
  }
  &.left {
    --tx: 0;
  }
  &.right {
    --tx: -100%;
  }

  transform: translate(var(--tx), var(--ty));
}
.event-statistic-line {
  svg {
    .g--grid {
      .path {
        fill: none;
        stroke: rgba(255, 255, 255, 0.1);
        stroke-width: 10px;
        stroke-opacity: 0;
        &:hover {
          stroke: rgba(255, 255, 255, 0.2);
        }
        &.highlight {
          stroke: rgba($color-primary, 0.3);
        }
      }
      &-x {
        .path {
          stroke-width: 1px;
        }
      }
    }
    .g--driver {
      path {
        fill: none;
        stroke: rgb(71, 99, 112);
        stroke-width: 1px;
        stroke-opacity: 0;
        pointer-events: none;
        &.view {
          stroke-opacity: 1;
        }
        &.highlight {
          stroke-width: 2px;
          stroke: $color-primary;
        }
      }
    }
    .g--driver-gradient {
      path {
        fill-opacity: 0;
      }
    }
    .g--driverbullet--other {
      circle {
        fill: $color-bg-light;
        stroke: $color-bg-light;
        fill-opacity: 0;
        stroke-opacity: 0;
        &.active {
          fill: $color-primary;
          stroke: $color-primary;
        }
        &.interactive {
          transition: r .3s, stroke-width .3s, fill .3s, stroke .3s;
          cursor: pointer;
          &:hover {
            fill: #fff;
            r: 12px;
            stroke-width: 5px !important;
            stroke-opacity: 0.5;
          }
        }
      }
    }
    .g--driverbullet {
      circle {
        fill: rgb(255, 255, 255);
        stroke: rgb(255, 255, 255);
        fill-opacity: 0;
        stroke-opacity: 0;
        &.active {
          fill: $color-primary;
          stroke: $color-primary;
        }
        &.interactive {
          transition: r .3s, stroke-width .3s, fill .3s, stroke .3s;
          cursor: pointer;
          &:hover {
            fill: #fff;
            r: 12px;
            stroke-width: 5px !important;
            stroke-opacity: 0.5;
          }
        }
      }
    }
    text {
      font-family: ProximaNova, Roboto, sans-serif;
      fill: rgba(255, 255, 255, 0.5);
      font-size: 10px;
      text-anchor: end;
      &.highlight {
        fill: $color-primary;
      }
    }
  }
}
</style>

export default {
  namespaced: true,
  state: {
    raceSeries: [],
    raceSeriesNavigation: 'headerNavigation',
    raceSeriesManufacturer: 'manufacturers',
    raceSeriesDrivers: 'drivers',
    raceSeriesNews: 'news',
    raceSeriesNewsHighlights: 'newsHighlights',
    raceSeriesNewsCount: 'newsCount',
    raceSeriesHomeSlug: 'home2020',
    raceSeriesEventBanner: 'eventCountDown',
    raceSeriesFooter: 'footerNavigation',
    newsAll: 'newsAll',
    liveStatus: '',
    futureStage: false,
    raceSeriesLoading: true
  },
  getters: {
    raceSeries: state => {
      return state.raceSeries;
    },
    raceSeriesNavigation: state => {
      return state.raceSeriesNavigation;
    },
    raceSeriesManufacturer: state => {
      return state.raceSeriesManufacturer;
    },
    raceSeriesDrivers: state => {
      return state.raceSeriesDrivers;
    },
    raceSeriesNews: state => {
      return state.raceSeriesNews;
    },
    raceSeriesNewsHighlights: state => {
      return state.raceSeriesNewsHighlights;
    },
    raceSeriesNewsCount: state => {
      return state.raceSeriesNewsCount;
    },
    raceSeriesHomeSlug: state => {
      return state.raceSeriesHomeSlug;
    },
    raceSeriesEventBanner: state => {
      return state.raceSeriesEventBanner;
    },
    raceSeriesFooter: state => {
      return state.raceSeriesFooter;
    },
    liveStatus: state => {
      return state.liveStatus;
    },
    isFutureStage: state => {
      return state.futureStage;
    },
    newsAll: state => {
      return state.newsAll;
    },
    raceSeriesLoading: state => {
      return state.raceSeriesLoading;
    }
  },
  mutations: {
    SET_RACESERIES: (state, raceSeries) => {
      state.raceSeries = raceSeries;
      state.raceSeriesNavigation = state.raceSeries.includes('DTM') ? 'headerNavigation' : 'headerNavigationTrophy';
      state.raceSeriesManufacturer = state.raceSeries.includes('DTMTrophy') ? 'manufacturersTrophy' : 'manufacturers';
      state.raceSeriesDrivers = 'drivers'; // state.raceSeries.includes('DTMTrophy') ? 'driversTrophy' : 'drivers';
      state.raceSeriesNews = state.raceSeries.includes('DTMTrophy') ? 'newsTrophy' : 'news';
      state.raceSeriesNewsHighlights = state.raceSeries.includes('DTMTrophy') ? 'newsHighlightsTrophy' : 'newsHighlights';
      state.raceSeriesNewsCount = state.raceSeries.includes('DTMTrophy') ? 'newsCountTrophy' : 'newsCount';
      state.raceSeriesHomeSlug = state.raceSeries.includes('DTMTrophy') ? 'homeTrophy' : 'home2020';
      state.raceSeriesEventBanner = state.raceSeries.includes('DTMTrophy') ? 'eventCountDownTrophy' : 'eventCountDown';
      state.raceSeriesFooter = state.raceSeries.includes('DTMTrophy') ? 'footerNavigationTrophy' : 'footerNavigation';
    },
    SET_FUTURE_STAGE: (state, isFutureStage) => {
      state.futureStage = isFutureStage;
    },
    SET_RACESERIES_LOADING: (state, isLoading) => {
      state.raceSeriesLoading = isLoading;
    }
  },
  actions: {
    async raceSeriesLoaded ({ commit }, isLoading) {
      commit('SET_RACESERIES_LOADING', isLoading);
    }
  }
};

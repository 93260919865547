<template>
  <div class="event-list">
    <div class="row">
      <template v-for="event in events">
        <div :key="event.id"
             :class="{ 'view': event.viewPort }"
             v-scroll:in="() => event.viewPort = true"
             v-scroll:out="() => event.viewPort = false"
             class="col-12 col-sm-6 col-md-4 col-lg-3 margin--bottom-2 fade-in fade-in--bottom">
          <div class="event-list__container bgimg--hover box-shadow" @click="$router.push({ name: $t('navigation.eventsDetail'), params: { lang: $i18n.locale, slug: event.slug} })">
            <div :class="{ 'bg-primary': event.eventCategory.indexOf('DTM') > -1, 'bg-medium text-white': event.eventCategory.indexOf('DTM') === -1 }"
              class="padding-1 text-align--center font-small text-uppercase">
              {{ event.eventCategory }}
            </div>
            <div :class="background" class="padding-1 text-align--center text-white" v-if="event.startTime && event.endTime">
              <div v-if="!event.hideEventDate" class="h4">{{ displayDays(event.startTime, event.endTime) }}</div>
              <div v-if="!event.hideEventDate" class="text-uppercase text-italic text-condensed">{{ displayMonths(event.startTime, event.endTime) }}</div>
              <div v-if="event.hideEventDate" class="h4 text-transparent">-</div>
              <div v-if="event.hideEventDate" class="text-uppercase text-italic text-condensed text-transparent">-</div>
            </div>
            <div :class="background" class="overflow--hidden position-relative text-white" v-if="event.tileImage">
              <background-image
                :url="`https://media.graphassets.com/resize=w:335,fit:crop/output=format:jpg/quality=value:70/compress/${event.tileImage.handle}`"
                :full="true"
                :loaded="true"
                :overlay="true"
                :overlay-color="overlayColor"></background-image>
              <div class="position-relative padding-2 padding--left-1 padding--right-1 text-align--center">
                <img :src="event.country.appCountryFlag.url" v-if="event.country.appCountryFlag" class="margin--bottom-1" width="36" :title="event.country.countryCode" :alt="event.country.countryCode">
                <div class="text-italic text-uppercase text-condensed">
                  <span v-if="event.eventNumber && !event.hideEventDate" class="text-primary font-weight--bold ">{{ ('00' + event.eventNumber).slice(-2) }}</span>
                  {{ event.name }}
                </div>
                <div class="padding--top-2 padding--bottom-2">
                  <img v-if="event.racetrack && event.racetrack.circuit" :src="event.racetrack.circuit.url" class="event-list__circuit">
                </div>
                <router-link class="link--more" :to="{ name: $t('navigation.eventsDetail'), params: { lang: $i18n.locale, slug: event.slug} }">
                  {{ $t('raceCalendar.moreInfo') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '../layout/BackgroundImage';
export default {
  name: 'EventList',
  components: { BackgroundImage },
  props: {
    events: { type: Array, default: () => [] },
    background: {
      type: String,
      default: 'bg-default'
    },
    overlayColor: {
      type: String,
      default: 'bg-strong'
    }
  },
  methods: {
    displayDays: function (start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return ('00' + startDate.getDate()).slice(-2) + '.' + (startDate.getDate() !== endDate.getDate() ? ' - ' + ('00' + endDate.getDate()).slice(-2) + '.' : '');
    },
    displayMonths: function (start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return (this.$i18n.d(startDate, 'month') + (startDate.getMonth() !== endDate.getMonth() ? ' / ' + this.$i18n.d(endDate, 'month') : ''));
    }
  }
};
</script>

<style lang="scss" scoped>
  .event-list {
    &__circuit {
      height: 80px;
    }
    &__container {
      cursor: pointer;
    }
  }
</style>

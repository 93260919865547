<template>
  <div class="driver-stage padding--top-3">
    <div class="row" :class="{ 'view': visible }"
         v-scroll:in="() => visible = true">
      <div class="col-12">
        <div class="driver-stage__number-wrapper text-align--center position-absolute">
          <div class="fade-in-child fade-in--left">
            <h2 class="h2 driver-stage__number text-italic primary m-0" v-html="Math.abs(driverData.driverNumber) || '&nbsp;'"/>
          </div>
          <div class="fade-in-child fade-in--left delay--100">
            <h2 class="h2 driver-stage__first-last-name text-italic m-0 position-relative" v-text="`${driverData.firstName} ${driverData.lastName}`"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 mt-lg-2" :class="{ 'view': visible }">
      <div class="col-12" v-if="driverData.driverSeasons.length">
        <div class="d-flex justify-content-center align-items-center">
          <img v-if="driverData.country && driverData.country.appCountryFlag"
               class="driver-stage__flag fade-in-child fade-in--left delay--300"
               width="48"
               height="48"
               :src="driverData.country.appCountryFlag.url"
               :title="driverData.country.name"
               :alt="driverData.country.name">
          <img v-if="driverData.raceSeries === 'DTM' && driverData.team && driverData.team.manufacturer"
               class="driver-stage__team ml-3 fade-in-child fade-in--left delay--400"
               height="48"
               :src="driverData.team.manufacturer.image.url"
               :title="driverData.team.name"
               :alt="driverData.team.name">
          <img v-if="driverData.raceSeries === 'DTMTrophy' && driverData.manufacturerTrophy"
               class="driver-stage__team ml-3 fade-in-child fade-in--left delay--400"
               height="48"
               :src="driverData.manufacturerTrophy.image.url"
               :title="driverData.manufacturerTrophy.name"
               :alt="driverData.manufacturerTrophy.name">
        </div>
        <div class="d-flex justify-content-center align-items-center mt-3">
          <span class="h5 font-weight--bold" v-if="driverData.team && driverData.team.name" v-text="driverData.team.name"/>
          <span class="h5 font-weight--bold" v-if="driverData.teamTrophy && driverData.teamTrophy.name" v-text="driverData.teamTrophy.name"/>
        </div>
      </div>
    </div>
    <div class="row padding--top-4 padding--bottom-4"
         :class="{ 'view': careerVisible }"
         v-scroll:in="() => careerVisible = true">
      <div class="col-12 fade-in-child fade-in--bottom delay--100 mb-3">
        <span class="text-uppercase">{{ driverData.age ? $t('driverSlider.age') : $t('driverSlider.short') }}</span> <b class="text-primary">{{ driverData.age ? driverData.age : driverData.driverThreeLetterCode }}</b>
      </div>
      <div class="col-12 fade-in-child fade-in--bottom delay--100 mb-3">
        <span class="text-uppercase">{{ driverData.appDtmDebut ? $t('driverSlider.debut') : $t('driverSlider.number') }}</span> <b class="text-primary">{{ driverData.appDtmDebut ? driverData.appDtmDebut : driverData.driverNumber }}</b>
      </div>
      <div class="col-12 fade-in-child fade-in--bottom delay--200">
        <div class="driver-stage__career" v-text="driverData.appCareer"></div>
      </div>
      <div class="col-12 fade-in-child fade-in--bottom delay--300">
        <div v-if="driverData.appDriverSocialMedia" class="margin--top-2 text-align--right">
          <a :href="driverData.appDriverSocialMedia.instagramUrl" v-if="driverData.appDriverSocialMedia.instagramUrl" target="_blank"
             class="driver-stage__social d-inline-block margin--right-1">
            <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
              <rect width="2902.7" height="2902.7" :mask="'url(#icon-instagram)'"></rect>
            </svg>
          </a>
          <a :href="driverData.appDriverSocialMedia.facebookUrl" v-if="driverData.appDriverSocialMedia.facebookUrl" target="_blank"
             class="driver-stage__social d-inline-block margin--right-1">
            <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
              <rect width="2902.7" height="2902.7" :mask="'url(#icon-facebook)'"></rect>
            </svg>
          </a>
          <a :href="driverData.appDriverSocialMedia.twitterUrl" v-if="driverData.appDriverSocialMedia.twitterUrl" target="_blank"
             class="driver-stage__social d-inline-block margin--right-1">
            <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
              <rect width="2902.7" height="2902.7" :mask="'url(#icon-twitter)'"></rect>
            </svg>
          </a>
          <a :href="driverData.appDriverSocialMedia.tiktokUrl" v-if="driverData.appDriverSocialMedia.tiktokUrl" target="_blank"
             class="driver-stage__social d-inline-block margin--right-1">
            <svg width="32" height="32" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              <rect width="448" height="512" :mask="'url(#icon-tiktok)'"></rect>
            </svg>
          </a>
          <a :href="driverData.appDriverSocialMedia.twitchUrl" v-if="driverData.appDriverSocialMedia.twitchUrl" target="_blank"
             class="driver-stage__social d-inline-block margin--right-1">
            <svg width="32" height="32" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <rect width="512" height="512" :mask="'url(#icon-twitch)'"></rect>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DriverStage',
  props: {
    driver: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      careerVisible: false
    };
  },
  computed: {
    driverData: function () {
      return { ...this.driver, ...this.driver.driverSeasons[0] };
    }
  }
};
</script>

<style scoped lang="scss">
.driver-stage {

  &__number-wrapper {
    bottom: -60px;
    z-index: 2;
    width: 100%;
    left: 0;
    right: 0;
    height: auto;
  }

  &__number {
    font-size: 14rem;
  }

  &__first-last-name {
    font-size: 4rem;
    top: -70px;
    z-index: 3;
    text-shadow: 0 0 20px rgba($color-bg-dark, 0.2);
  }

  &__career {
    width: 100%;
    column-count: 2;
    column-gap: 1rem;
    text-align: justify;
  }

  &__social {
    svg {
      path, rect, polygon {
        fill: #fff;
      }
    }
    &:hover {
      svg {
        path, rect, polygon {
          fill: $color-primary;
        }
      }
    }
  }
}
@media all and (max-width: map_get($grid-breakpoints, 'lg')) {
  .driver-stage {

    &__number-wrapper {
      bottom: -100px;
    }

    &__first-last-name {
      font-size: 3rem;
    }

    &__career {
      column-count: 1;
    }
  }
}

@media (max-width: map_get($grid-breakpoints, 'md')) {
  .driver-stage {
    &__number-wrapper {
      bottom: -3rem;
    }
    &__number {
      font-size: 10rem;
    }
    &__first-last-name {
      font-size: 2rem;
      top: -45px;
      text-shadow: 0 0 20px rgba($color-bg-dark, 0.8);
    }
  }
}
</style>

<template>
  <div class="position-relative"
       v-scroll:in="() => { visible = true; loaded = true }"
       v-scroll:out="() => visible = false">
    <div class="container-fluid" v-if="native" :class="{'view': visible}">
      <h2 v-if="primaryHeadline"
          class="future-stage-headline text-uppercase text-italic text-primary fade-in-child fade-in--bottom delay--100"
          v-text="primaryHeadline"/>
      <h3 v-if="secondaryHeadline"
          class="future-stage-headline future-stage-headline--indent-left text-italic text-uppercase fade-in-child fade-in--bottom delay--200"
          v-text="secondaryHeadline"/>
      <div class="wallsio" ref="wallsio"/>
    </div>
    <div class="position-relative container-fluid overflow-hidden margin--top-2 margin--bottom-4" v-if="!native">
      <div v-masonry
           transition-duration="1s"
           item-selector=".wallsio__card"
           column-width=".wallsio__sizer"
           class="wallsio">
        <div class="wallsio__sizer"></div>
        <div v-masonry-tile
             class="wallsio__card fade-in"
             v-for="(item, index) in cards"
             :key="'social-' + item.id"
             v-scroll:in="() => { item.visible = true; item.loaded = true; }"
             v-scroll:out="() => item.visible = false"
             :class="{ 'wallsio__card--x2': index % 6 === 0, 'view': item.visible, 'clicked': item.clicked }">
          <div class="wallsio__card-content">
            <background-image :url="item.post_image" :video="item.post_video ? { url: item.post_video } : null" :scale="'original'" :loaded="item.loaded" @loaded="redrawGrid"></background-image>
            <div class="wallsio__card-content__overlay text-condensed" @click="item.clicked = !item.clicked">
              {{ item.comment }}
            </div>
            <div class="wallsio__card-content__icon">
              <a :href="item.post_link" target="_blank">
                <svg width="16" height="16" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
                  <rect width="2902.7" height="2902.7" :mask="'url(#icon-' + item.type.toLowerCase() + ')'"></rect>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <break-after v-if="breakAfter" :key="'breakAfter'" :is-yellow="isDtmTrophy" :background-class="isDtmTrophy ? 'svg-fill-default' : ''"></break-after>
  </div>
</template>

<script>
import BreakAfter from './layout/BreakAfter';
import AppConst from '../config/app.const';
import BackgroundImage from './layout/BackgroundImage';
import Settings from '../config/settings';

export default {
  name: 'WallsIo',
  components: { BackgroundImage, BreakAfter },
  props: {
    breakAfter: { type: Boolean, default: true },
    count: { type: Number, default: 20 },
    primaryHeadline: {
      type: String,
      required: false
    },
    secondaryHeadline: {
      type: String,
      required: false
    },
    wallNameProp: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      native: true,
      visible: false,
      loaded: false,
      offset: '',
      cards: [],
      limit: 8
    };
  },
  computed: {
    wallName: function () {
      if (this.wallNameProp) {
        return this.wallNameProp;
      }
      return this.$store.getters['page/raceSeries'] === AppConst.DTM_TROPHY ? AppConst.DTM_TROPHY_WALL : AppConst.DTM_WALL;
    },
    isDtmTrophy: function () {
      return this.$store.getters['page/raceSeries'] === 'dtm-trophy';
    }
  },
  methods: {
    async fetchPosts () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `social?query=posts&count=${this.count}&offset=${this.offset}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          if (response.length) {
            for (let i = response.length - 1; i >= 0; i--) {
              const item = response[i];
              item.visible = false;
              item.loaded = false;
              item.clicked = false;
              if (item.post_image || item.post_video) {
                this.cards.unshift(item);
              }
            }
          }
          if (this.cards.length > this.limit) {
            this.cards.splice(this.limit - 1, this.cards.length - this.limit - 1);
          }
          // redo after 10 seconds
        });
    },
    mountWall () {
      if (!this.visible) {
        this.visible = true;
        const wallsScript = document.createElement('script');
        wallsScript.setAttribute('src', 'https://walls.io/js/wallsio-widget-1.2.js');
        wallsScript.dataset.wallurl = `https://walls.io/${this.wallName}?nobackground=1&amp;initial_posts=6&amp;theme=grid&amp;show_header=0&amp;lang=de_DE`;
        wallsScript.dataset.title = `#${this.wallName}`;
        wallsScript.dataset.width = '100%';
        wallsScript.dataset.height = '800';
        wallsScript.dataset.autoheight = '1';
        wallsScript.dataset.lazyload = '1';
        this.$refs.wallsio.appendChild(wallsScript);
      }
    },
    redrawGrid () {
      if (typeof this.$redrawVueMasonry === 'function') {
        setTimeout(this.$redrawVueMasonry, 150);
      }
    }
  },
  watch: {
    $route () {
      if (this.isNative) {
        this.mountWall();
      }
    }
  },
  mounted () {
    if (!this.native) {
      this.fetchPosts();
    } else {
      this.mountWall();
    }
  }
};
</script>

<style lang="scss">
  .wallsio {
    $root: &;
    margin: 0 -$gap;

    &__sizer {
      width: 33.333%;
    }
    &__card {
      width: 33.333%;
      padding: $gap;

      &--x2 {
        width: 66.666%;
      }

      &-content {
        position: relative;
        overflow: hidden;

        &__overlay {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.7);
          padding: $gap;
          color: #fff;
          font-size: .8em;
          opacity: 0;
          transition: all .3s;
        }

        &__icon {
          position: absolute;
          right: $gap;
          bottom: $gap / 2;

          rect {
            fill: #fff;
          }
        }
      }

      &:hover, &.clicked {
        #{$root}__card-content__overlay {
          opacity: 1;
        }
      }
    }
  }

  // Responsive stuff here
  @media (max-width: map_get($grid-breakpoints, 'lg')) {
    .wallsio {
      &__card, &__sizer, &__card--x2 {
        width: 50%;
      }
    }
  }

  @media (max-width: map_get($grid-breakpoints, 'sm')) {
    .wallsio {
      &__card, &__sizer, &__card--x2 {
        width: 100%;
      }
    }
  }
</style>

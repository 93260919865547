<template>
  <div class=" d-none d-md-block promotion-banner bg-primary" v-if="data && data.headline">
    <div class="d-flex justify-content-center" v-for="(link, i) in data.links" :key="`promotion-link-${i}`">
      <a class="text-default text-center primary dark" v-if="link.url" :href="link.url">
        <h4 class="promotion-banner__text text bg-primary" v-text="data.headline"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotionBanner',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>

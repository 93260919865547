<template>
  <div class="margin--top-2 margin--bottom-8 supporting-series"
       :class="{ 'view': visible }"
       v-scroll:in="() => { visible = true; loaded = true; }"
       v-scroll:out="() => visible = false">
    <div class="bg-track text-white">
      <div class="container-fluid padding--top-3 padding--bottom-8">
        <div class="h4 font-weight--light text-italic margin--bottom-1 fade-in-child fade-in--bottom">
          {{ $t('raceCalendar.dtmEvents') }}
        </div>
        <div class="h2 fade-in-child fade-in--bottom delay--200">
          {{ $t('raceCalendar.supportingSeries') }}
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="bg-white box-shadow supporting-series__white fade-in-child fade-in--bottom delay--500">
        <div class="row">
          <div class="col-sm col-4" v-for="item in entries" :key="item.name">
            <img :src="item.logo.url" height="50" :alt="item.imageAlt" :title="item.imageTitle">
          </div>
        </div>
        <swiper :ref="swiper" :options="options">
          <!-- Slides -->
          <swiper-slide v-for="item in entries" :key="item.name">
            <div>
              <div class="bgimg--hover">
                <div class="fade-in-child">
                  <background-image
                    :scale="'16-9'"
                    :url="`https://media.graphassets.com/resize=w:1200,fit:crop/output=format:jpg/quality=value:70/compress/${item.image.handle}`"
                    :loaded="loaded"/>
                </div>
                <div class="supporting-series__text">
                  <div class="fade-in-child delay--100">
                    <div class="text h3 bg-primary bg-primary--condensed topline">
                      <span>{{ item.headline }}</span>
                    </div>
                  </div>
                  <div class="fade-in-child delay--200">
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '../layout/BackgroundImage';
export default {
  name: 'SupportingSeries',
  components: { BackgroundImage },
  props: {
    entries: Array
  },
  data () {
    return {
      visible: false,
      loaded: false,
      swiper: false,
      options: {
        autoplay: {
          delay: 4000
        }
      }
    };
  }
};
</script>

<style lang="scss">
  .supporting-series {
    padding-bottom: $gap * 3;
    &__white {
      margin-top: $gap * -6;
      padding: $gap * 3 $gap * 8;
    }
    &__text {
      margin: -$gap $gap * 3 0;
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'lg')) {
    .supporting-series {
      &__white {
        padding-left: $gap * 3;
        padding-right: $gap * 3;
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'sm')) {
    .supporting-series {
      &__white {
        padding-left: $gap;
        padding-right: $gap;
      }
      &__text {
        margin: -$gap $gap 0;
      }
    }
  }
</style>

<template>
  <footer class="footer text-white" :class="{'footer--future-stage': isFutureStage}">
    <div class="container-fluid">
      <div class="row">
        <!--INFOPAGES LINKS HERE-->
        <div class="col-12 col-md-6 col-lg-4">
          <h4 v-text="this.title" />
          <ul class="footer__nav-items">
            <li v-for="(item, index) in navItems" :key="index">
              <a v-if="item.external && item.url" :href="item.url" target="_blank" v-text="item.title"/>
              <router-link v-if="!item.external && item.url" :to="`/${$i18n.locale}/${item.url}`">
                {{ item.title }}
              </router-link>
            </li>
            <li>
              <a @click.prevent="openConsentManager()" href="#openConsentManager">{{ $t('common.openConsent') }}</a>
            </li>
          </ul>
        </div>
        <!--SOCIAL HERE-->
        <div class="col-12 col-md-6 col-lg-4">
          <h4 v-text="$t('footer.followUs')" />
          <ul class="footer__nav-items footer__social-items">
            <li v-for="(item, index) in socialItems" :key="index">
              <a :href="item.link.url" target="_blank">
                {{ item.name }}
                <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
                  <rect width="2902.7" height="2902.7" :mask="'url(#icon-' + item.name.toLowerCase() + ')'"></rect>
                </svg>
              </a>
            </li>
          </ul>
          <a href="https://www.adac-motorsport.de" target="_blank"><svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" class="adac__LogoAdac-sc-ve67c5-0 hYkQcZ logo logo-position-right" variant="header" negative="false"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 64H64V0H0V64Z" fill="#FACA22"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M3.02686 22.6769H6.92704L7.73822 18.7457H12.0574L12.7904 22.6769H17.5539L13.5754 3.24121H7.39825L3.02686 22.6769ZM9.98914 7.39005H10.0421L11.4028 15.3062H8.39287L9.98914 7.39005Z" fill="black"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M18.8363 22.6769H24.6311C30.4521 22.6769 32.7217 18.1995 32.7217 12.6582C32.7217 6.95388 29.9442 3.24121 24.0433 3.24121H18.8363V22.6769ZM23.643 6.68077H24.0433C27.2212 6.68077 27.5416 10.2288 27.5416 12.4675C27.5416 15.0331 27.5416 19.2373 24.177 19.2373H23.643V6.68077Z" fill="black"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M32.1469 22.6769H36.1652L37.0017 18.7457H41.4514L42.2063 22.6769H47.1141L43.015 3.24121H36.6511L32.1469 22.6769ZM39.3203 7.39005H39.3741L40.7763 15.3062H37.6751L39.3203 7.39005Z" fill="black"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M56.5432 10.0385C56.5432 8.7284 56.2228 6.4628 54.4879 6.4628C52.4587 6.4628 52.1644 9.38305 52.1644 12.9865C52.1644 16.9723 52.3519 19.4555 54.5947 19.4555C56.3573 19.4555 56.7576 17.9824 56.8644 15.5521L61.0295 15.9344C61.0295 20.1933 58.5471 23.059 54.3811 23.059C48.5602 23.059 46.9851 18.0093 46.9851 12.9596C46.9851 8.29142 48.5333 2.85938 54.3811 2.85938C58.9734 2.85938 61.0295 6.02583 61.0295 10.0385H56.5432Z" fill="black"></path></svg></a>
        </div>
        <!--MANUFACTURERS HERE-->
        <div class="col-12 col-md-12 col-lg-4">
          <h4 v-text="$t('footer.premiumManufacturers')" />
          <div class="row">
            <div v-for="(item, index) in manufacturers" :key="index" :class="{ 'col-6': manufacturers.length <= 2, 'col-4': manufacturers.length > 2 }">
              <img class="footer__manufacturer-item" width="100%"
                :alt="item.imageAlt"
                :title="item.imageTitle"
                :src="item.brandImage ? item.brandImage.url : item.image ? item.image.url : pixel"/>
            </div>
          </div>
          <h4 v-text="'Charity-Partner'" />
          <div class="row padding--bottom-1">
            <a href="#" target="_blank">
              <img class="footer__manufacturer-item margin--left-1" width="100%" alt="b.waterMISSION"
                   src="/images/b.waterMISSION_Logo.png"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';

export default {
  name: 'Footer',
  data () {
    return {
      title: null,
      navItems: [],
      socialItems: [],
      manufacturers: [],
      pixel: AppConst.PIXEL,
      dtm: AppConst.DTM,
      dtmTrophy: AppConst.DTM_TROPHY,
      retry: {
        navigation: 0,
        social: 0,
        manufacturers: 0
      }
    };
  },
  computed: {
    queryNameManufacturers: function () {
      return this.$store.getters['page/raceSeriesManufacturer'];
    },
    queryNameFooter: function () {
      return this.$store.getters['page/raceSeriesFooter'];
    },
    queryParamSocialChannels: function () {
      const raceSeries = this.$store.getters['page/raceSeries'][0];
      if (raceSeries !== 'DTM' && raceSeries !== 'DTMTrophy') {
        return 'DTM';
      }
      return raceSeries;
    },
    isFutureStage: function () {
      return this.$store.getters['page/isFutureStage'];
    }
  },
  methods: {
    async fetchFooterNavigation () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=${this.queryNameFooter}`,
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          response.navigations.forEach(navigation => {
            this.title = navigation.title;
            this.navItems = navigation.navItems;
          });
        })
        .catch(() => {
          if (this.retry.navigation < 1) {
            this.retry.navigation++;
            setTimeout(this.fetchFooterNavigation, 3000);
          }
        });
    },
    async fetchFooterSocial () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=socialChannels&raceSeries=${this.queryParamSocialChannels}`,
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          this.socialItems = response.socialchannels;
        })
        .catch(() => {
          if (this.retry.social < 1) {
            this.retry.social++;
            setTimeout(this.fetchFooterSocial, 3000);
          }
        });
    },
    async fetchManufacturers () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=${this.queryNameManufacturers}`,
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          this.manufacturers = response.manufacturers ? response.manufacturers : response.manufacturerTrophies ? response.manufacturerTrophies : [];
        })
        .catch(() => {
          if (this.retry.manufacturers < 1) {
            this.retry.manufacturers++;
            setTimeout(this.fetchManufacturers, 3000);
          }
        });
    },
    openConsentManager () {
      window.location.hash = '#open-consent-manager';
    }
  },
  watch: {
    $route () {
      this.fetchFooterNavigation();
      this.fetchFooterSocial();
      this.fetchManufacturers();
    }
  },
  created () {
    // fetch the navigation items
    this.fetchFooterNavigation();
    this.fetchFooterSocial();
    this.fetchManufacturers();
  }
};
</script>

<style lang="scss">
  .footer {
    background-color: $color-bg-med;
    overflow: hidden;
    width: 100%;

    &--future-stage {
      background: $color-bg-future-stage;
    }

    h4 {
      margin-top: 55px;
      text-transform: uppercase;
      font-style: italic;
      font-size: 20px;
    }

    &__nav-items {
      padding: 0;
      list-style-type: none;
      a {
        display: inline-block;
        position: relative;
        font-family: var(--font-condensed);
        text-transform: uppercase;
        font-weight: 600;
        font-style: italic;
        padding: $gap / 2 0;
        letter-spacing: 0.25px;
        transition: letter-spacing .4s linear;
        &:hover {
          letter-spacing: 0.5px;
        }
      }
    }

    &__social-items {
      svg {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -54%);
        rect {
          fill: #fff;
          transition: all .3s;
        }
      }
      a {
        padding: $gap 0 $gap 32px + $gap;
        &:hover {
          svg rect {
            fill: $color-primary;
          }
        }
      }
    }

    &__manufacturer-item {
      max-width: 200px;
      height: auto;
    }
  }
</style>

<template>
  <div v-if="content && content.frontendComponent" class="generic">
    <template>
      <html-content v-if="content.frontendComponent === 'html-content'" :data="content"/>
      <mydtm-benefits v-if="content.frontendComponent === 'mydtm-benefits'" :data="content"/>
      <mydtm-preferences v-if="content.frontendComponent === 'mydtm-preferences'" :data="content"/>
      <mydtm-voting v-if="content.frontendComponent === 'mydtm-voting'" :data="content"/>
      <mydtm-survey v-if="content.frontendComponent === 'mydtm-survey'" :data="content"/>
      <mydtm-news v-if="content.frontendComponent === 'mydtm-news'" :data="content"/>
      <promotion-banner v-if="content.frontendComponent === 'promotion-banner'" :data="content"/>
      <app-store-badges v-if="content.frontendComponent === 'app-store-badges'" :data="content"/>
      <autohero-searchslider v-if="content.frontendComponent === 'autohero-searchslider'" :data="content"></autohero-searchslider>
    </template>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';
import MydtmBenefits from './generic/MydtmBenefits';
import MydtmPreferences from '@/components/generic/MydtmPreferences';
import MydtmVoting from '@/components/generic/MydtmVoting';
import MydtmSurvey from '@/components/generic/MydtmSurvey';
import MydtmNews from '@/components/generic/MydtmNews';
import PromotionBanner from '@/components/generic/PromotionBanner';
import AppStoreBadges from '@/components/generic/AppStoreBadges';
import AutoheroSearchslider from '@/components/generic/AutoheroSearchslider';
import HtmlContent from '@/components/generic/HtmlContent';

export default {
  name: 'Generic',
  components: {
    AutoheroSearchslider,
    AppStoreBadges,
    PromotionBanner,
    MydtmNews,
    MydtmPreferences,
    MydtmBenefits,
    MydtmVoting,
    MydtmSurvey,
    HtmlContent
  },
  props: {
    id: String
  },
  data () {
    return {
      content: null
    };
  },
  methods: {
    async fetchModuleGeneric () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleGeneric&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.content = response.moduleGeneric;
        });
    }
  },
  created () {
    if (this.id) {
      this.fetchModuleGeneric();
    }
  }
};
</script>

<style lang="scss" scoped>
.generic {
  color: #333;
}
</style>

<template>
  <script v-html="JSON.stringify(json)" type="application/ld+json"></script>
</template>

<script>
export default {
  name: 'JsonLdEvent',
  props: {
    name: String,
    url: String,
    description: String,
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    address: String,
    city: String,
    country: String,
    mapUrl: String,
    imageUrl: String,
    postalCode: String
  },
  computed: {
    json: function () {
      return {
        '@context': 'https://www.schema.org',
        '@type': 'Event',
        name: this.name,
        url: `https://www.dtm.com${this.url}`,
        description: this.description,
        startDate: this.startDate,
        endDate: this.endDate,
        image: this.imageUrl,
        eventAttendanceMode: 'https://schema.org/MixedEventAttendanceMode',
        eventStatus: 'https://schema.org/EventScheduled',
        location: {
          '@type': 'Place',
          name: this.name,
          url: this.mapUrl,
          address: {
            '@type': 'PostalAddress',
            streetAddress: this.address,
            addressLocality: this.city,
            addressCountry: this.country,
            addressRegion: this.addressRegion,
            postalCode: this.postalCode
          }
        },
        performer: {
          '@type': 'PerformingGroup',
          name: 'ITR GmbH'
        },
        organizer: {
          '@type': 'Organization',
          name: 'ITR GmbH',
          url: 'https://www.dtm.com'
        }
      };
    }
  }
};
</script>

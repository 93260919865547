<template>
  <div class="news">
    <meta-data v-if="page.metaTitle"
      :key="page.metaTitle"
      :meta-title="page.metaTitle"
      :meta-description="page.metaDescription"
      :no-index="page.noIndex"/>
    <page-header class="bg-default text-white" :headline="$t('news.title') + (this.raceSeries ? $t('news.' + this.raceSeries) : $t('news.platform'))" :sub-headline="$t('news.subTitle')" :break-after-to-white="true"></page-header>
    <div class="text-default bg-white padding--top-4 padding--bottom-8">
      <div class="container-fluid">
        <!-- News Navigation -->
        <news-navigation/>
        <!-- Highlight news here -->
        <div class="row margin--bottom-4">
          <div v-for="(highlight, index) in news.highlights"
            :key="index"
            class="col-12">
            <div
              class="news__item bgimg--hover"
              @click="$router.push({ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: highlight.slug} })"
              :class="{ 'view': highlight.visible }"
              v-scroll:in="() => { highlight.visible = true; highlight.loaded = true }"
              v-scroll:out="() => highlight.visible = false">
              <div class="fade-in-child fade-in--right delay--100">
                <background-image
                  :scale="'16-9'"
                  :overlay="true"
                  :overlay-direction="'right'"
                  :url="highlight.image ? highlight.image.url : highlight.videoPosterImage ? highlight.videoPosterImage.url : null"
                  :video="highlight.cardVideo"
                  :loaded="highlight.loaded"/>
              </div>
              <div class="news__item--highlight__title">
                <h3 class="bg-primary"><span>{{ highlight.headline }}</span></h3>
                <div class="text padding--top-1 font-weight--bold topline"><span v-text="highlight.topline"></span></div>
              </div>
            </div>
          </div>
        </div>
        <!-- NEWS HERE -->
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 margin--bottom-2" v-for="(news, index) in news.entries"
            :key="`news-entry-${index}`">
            <div
              :class="{ 'view': news.visible }"
              v-scroll:in="() => { news.visible = true; news.loaded = true }"
              v-scroll:out="() => news.visible = false">
                <news-entry :news="news"/>
            </div>
          </div>
        </div>
        <div
          class="row margin--top-2"
          :class="{ 'view': news.visible }"
          v-scroll:in="() => { news.visible = true; news.loaded = true }"
          v-scroll:out="() => news.visible = false">
          <div class="col-12 d-flex align-content-center justify-content-center fade-in-child fade-in--bottom">
            <button class="button-ghost news__load-more" v-if="this.currentCount !== this.totalCount" @click="loadMore()">
              {{ $t('news.loadMore') }}
            </button>
          </div>
          <div class="col-12 d-flex align-content-center justify-content-center margin--top-1 fade-in-child fade-in--bottom">
            <span>{{ this.currentCount }} {{ $t('news.of') }} {{ this.totalCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import MetaData from '../components/MetaData';
import BackgroundImage from '../components/layout/BackgroundImage';
import PageHeader from '../components/layout/PageHeader';
import Settings from '../config/settings';
import NewsEntry from '../components/news/NewsEntry';
import NewsNavigation from '@/components/news/NewsNavigation';

const OFFSET = 0;
const TOTAL_COUNT = 0;
const COUNT = 9;

export default {
  name: 'News',
  components: { NewsNavigation, NewsEntry, PageHeader, BackgroundImage, MetaData },
  data () {
    return {
      news: {
        highlights: [],
        entries: []
      },
      offset: OFFSET,
      totalCount: TOTAL_COUNT,
      count: COUNT,
      raceSeries: 'DTM',
      page: {},
      retryMax: 1,
      retryCount: 0
    };
  },
  watch: {
    $route (to, from) {
      this.updateRoute();
    }
  },
  computed: {
    newsLeft: function () {
      return this.totalCount - this.currentCount;
    },
    currentCount: function () {
      return this.news.entries.length;
    },
    currentOffset: function () {
      return this.totalCount - this.newsLeft;
    },
    queryNameNews: function () {
      return this.$store.getters['page/raceSeriesNews'];
    },
    queryNameNewsHighlights: function () {
      return this.$store.getters['page/raceSeriesNewsHighlights'];
    },
    queryNameNewsCount: function () {
      return this.$store.getters['page/raceSeriesNewsCount'];
    }
  },
  methods: {
    async fetchSystemPage () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=systemPages&slug=news',
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          this.page = response.systemPages[0];
        })
        .catch(() => {
          if (this.retryCount < this.retryMax) {
            this.retryCount++;
            setTimeout(this.fetchSystemPage, 3000);
          }
        });
    },
    getExcludes () {
      const exclude = [];
      this.news.highlights.forEach(news => {
        exclude.push(news.id);
      });
      return exclude;
    },
    getPath (count = 1, offset = 0) {
      let path = '';
      if (this.raceSeries) {
        path = `data?query=newsByRaceSeries&raceSeries=${this.raceSeries}&count=${count}&offset=${offset}&exclude=${this.getExcludes().join(',')}`;
      } else {
        path = `data?query=newsAll&count=${count}&offset=${offset}&exclude=${this.getExcludes().join(',')}`;
      }
      return path;
    },
    getPathHighlight (count) {
      let path = '';
      if (this.raceSeries) {
        path = `data?query=newsHighlightsRaceSeries&raceSeries=${this.raceSeries}&count=${count}`;
      } else {
        path = `data?query=newsHighlights&count=${count}`;
      }
      return path;
    },
    getPathCount () {
      let path = '';
      if (this.raceSeries) {
        path = `data?query=newsCountRaceSeries&raceSeries=${this.raceSeries}&exclude=${this.getExcludes().join(',')}`;
      } else {
        path = `data?query=newsCount&exclude=${this.getExcludes().join(',')}`;
      }
      return path;
    },
    async fetchHighlightNews () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: this.getPathHighlight(1),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
          });
          this.news.highlights = response.newses;
          this.fetchNewsCount();
          this.fetchNews();
        })
        .catch(() => {
          // setTimeout(this.fetchHighlightNews, 3000);
        });
    },
    async fetchNews () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      // exclude highlight news
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: this.getPath(this.count, this.offset),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
          });
          this.news.entries = response.newses;
          const raceSeries = this.raceSeries ? [this.raceSeries] : ['DTM'];
          this.$store.commit('page/SET_RACESERIES', (raceSeries));
          this.$store.dispatch('page/raceSeriesLoaded', false);
        })
        .catch(() => {
          // setTimeout(this.fetchNews, 3000);
        });
    },
    async fetchNewsCount () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: this.getPathCount(),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.totalCount = response.newsesConnection.aggregate.count;
        })
        .catch(() => {
          // setTimeout(this.fetchNewsCount, 3000);
        });
    },
    async loadMore () {
      this.offset = this.currentOffset;
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: this.getPath(this.count, this.offset),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
            this.news.entries.push(news);
          });
          // handle some left over news and modify the default count and offset
          if (this.newsLeft < this.count) {
            this.count = this.newsLeft;
          }
          this.offset = this.offset + this.count;
        })
        .catch(() => {
          // setTimeout(this.loadMore, 3000);
        });
    },
    updateRoute () {
      this.raceSeries = null;
      const raceSeriesPathPosition = this.$route.name.indexOf('raceseries');
      if (raceSeriesPathPosition > -1) {
        this.raceSeries = this.$route.name.substr(raceSeriesPathPosition + 11);
      }
      this.news.highlights = [];
      this.news.entries = [];
      this.offset = OFFSET;
      this.count = COUNT;
      this.totalCount = TOTAL_COUNT;
      this.fetchHighlightNews();
    }
  },
  mounted () {
    this.fetchSystemPage();
    this.updateRoute();
  }
};
</script>

<template>
  <div class="teaser padding--top-4 padding--bottom-8"
       v-if="headlineData && textData"
       v-scroll:in="() => { visible = true; wasVisible = true; }"
       v-scroll:out="() => visible = false">
    <div class="position-relative">
      <div class="container-fluid" :class="{'view': visible}">
        <h2 v-if="headlineData.primary"
            class="future-stage-headline text-uppercase text-italic text-primary text-align--right fade-in-child fade-in--bottom delay--100"
            v-text="headlineData.primary"/>
        <h3 v-if="headlineData.secondary"
            class="future-stage-headline future-stage-headline--indent-right text-italic text-uppercase text-align--right fade-in-child fade-in--bottom delay--200"
            v-text="headlineData.secondary"/>
        <div v-if="textData.text"
             class="teaser__text fade-in-child fade-in--bottom delay--200"
             v-html="textData.text.html"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';

export default {
  name: 'Teaser',
  props: {
    headlineId: {
      type: String,
      required: true
    },
    textId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      headlineData: null,
      textData: null
    };
  },
  methods: {
    async fetchHeadline () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=headline&id=${this.headlineId}`,
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          this.headlineData = response.headline ? response.headline : null;
        });
    },
    async fetchText () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleText&id=${this.textId}`,
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          this.textData = response.moduleText ? response.moduleText : null;
        });
    }
  },
  watch: {
    $route () {
      this.fetchHeadline();
      this.fetchText();
    }
  },
  created () {
    this.fetchHeadline();
    this.fetchText();
  }
};
</script>

<style scoped lang="scss">
  .teaser {
    background: url('~@/assets/images/wheel-track-horizontal.svg') fixed bottom repeat-x;
    width: 100vw;
    height: auto;

    &__text {
      max-width: 70%;
    }
  }

  @media all and (max-width: map_get($grid-breakpoints, 'md')) {
    .teaser {

      &__text {
        max-width: 100%;
      }
    }
  }

</style>

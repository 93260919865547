<template>
  <div class="racetrack-city bg-white margin--top-2 padding--bottom-8"
       :class="{ 'view': visible }"
       v-scroll:in="() => { visible = true; loaded = true; }"
       v-scroll:out="() => visible = false">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="h4 font-weight--light text-italic margin--bottom-1">
            {{ $t('raceCalendar.arrival') }}
          </div>
          <div class="h2 margin--bottom-2">
            {{ $t('raceCalendar.outsideOfTheTrack') }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="order-1 order-md-0 col-8 col-md-4 offset-4 offset-md-0 racetrack-city__about">
          <div class="racetrack-city__about-text padding-2 bg-white fade-in--left fade-in-child">
            <div class="h4 font-weight--light text-italic margin--bottom-1" v-text="city.name"/>
            <div class="h3 margin--bottom-2" v-text="city.headline"/>
            <div v-text="city.description"/>
          </div>
        </div>
        <div class="order-0 order-md-1 col-12 col-md-8">
          <div class="overflow--hidden position-relative bgimg--hover fade-in--bottom fade-in-child" v-if="city && city.image">
            <background-image
              :scale="'16-9'"
              :url="`https://media.graphassets.com/resize=w:1200,fit:crop/quality=value:70/compress/${city.image.handle}`"
              :loaded="true"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '../layout/BackgroundImage';
export default {
  name: 'RacetrackCity',
  components: { BackgroundImage },
  props: {
    city: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      visible: false
    };
  }
};
</script>

<style lang="scss" scoped>
  .racetrack-city {
    &__about-text {
      border-top: 12px solid $color-primary;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    &__about {
      z-index: 1;
      top: 20px;
      left: 80px;
      &::after {
        content: '';
        background: $color-bg;
        position: absolute;
        top: 40px;
        bottom: 0;
        left: -25px;
        right: 0;
        width: 70%;
        height: 100%;
        z-index: -1;
      }
    }
  }

  @media all and (max-width: map_get($grid-breakpoints, 'md')) {
    .racetrack-city {
      &__about-text {
        border-top: none;
        border-bottom: 12px solid $color-primary;
      }

      &__about {
        position: inherit;
        top: 0;
        left: 0;
        &::after {
          display: none;
        }
      }
    }
  }
</style>

export default {
  HEADER_ACCEPT: 'application/json',
  FETCH_PATH_NAME: 'fetchByPath',
  ADD_LANGUAGE: 'language/ADD_LANGUAGE',
  ADD_LOCALIZATIONS: 'language/ADD_LOCALIZATIONS',
  ADD_LOCALE: 'language/ADD_LOCALE',
  REMOVE_LOCALE: 'language/REMOVE_LOCALE',
  GET_LANGUAGE_SLUG: 'language/slug',
  GET_LANGUAGE_SLUGS: 'language/slugs',
  GET_LOCALES: 'language/locales',
  ADD_GLOBALS: 'globals/ADD_GLOBALS',
  STORAGE_NAME: 'DTM_ACCEPT_COOKIES',
  PIXEL: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
  DTM_TROPHY: 'dtm-trophy',
  DTM: 'dtm',
  DTM_TROPHY_WALL: 'dtmtrophy',
  DTM_WALL: 'tomorrowisnow',
  FUTURE_STAGE: 'futureStage'
};

<template>
  <div class="tickets">
    <swiper :ref="swiper" :options="options">
      <!-- Slides -->
      <swiper-slide v-for="item in categories" :key="item.name">
        <div>
          <div
            class="text-align--center font-weight--bold text-uppercase text-condensed padding-1"
            :class="{ 'bg-default text-white': !item.highlight, 'bg-primary': item.highlight }">{{ item.name }}</div>
          <div
            class="padding--top-2 padding--left-1 padding--bottom-2 padding--right-1"
            :class="{ 'bg-gray': !item.highlight, 'bg-default text-white': item.highlight }">
            <div>
              <div class="row justify-content-between">
                <div class="col-4 font-small text-condensed text-uppercase">{{ $t('ticket.category') }}</div>
                <div class="col-4 text-align--right font-small text-condensed text-uppercase">{{ $t('ticket.price') }}</div>
                <template v-for="cat in item.categories">
                  <div :key="cat.id" class="col-12 tickets__spacer"></div>
                  <div :key="cat.id" v-if="cat.categoryId" class="col-auto tickets__category text-condensed" :class="'tickets__category--' + cat.categoryId"><span></span> {{ cat.categoryId }}</div>
                  <div :key="cat.id" v-if="cat.name" class="col-auto text-condensed" v-text="cat.name"/>
                  <div :key="cat.id" v-if="!cat.priceOld" class="col text-align--right text-condensed">{{ cat.price }}</div>
                  <div :key="cat.id" v-if="cat.priceOld" class="col text-align--right text-condensed">
                    <del>{{ cat.priceOld }}</del><span class="text-red"> {{ cat.price }}</span>
                  </div>
                </template>
              </div>
              <div class="text-align--center font-small tickets__info">
                {{ item.infotext }}
              </div>
              <a
                :class="{ 'button-ghost': !item.highlight, 'button-primary': item.highlight }"
                :href="item.linkUrl"
                target="_blank"
                :title="item.linkText">{{ item.linkText }}</a>
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper>
    <div v-if="discountChild || discountTeenager || discountHandicap" class="tickets__discount" @click="discountOpen = !discountOpen">
      <div class="bg-gray text-align--center font-weight--bold margin--top-2 padding-1 text-uppercase text-condensed">
        {{ $t('ticket.discount') }}
      </div>
      <div class="bg-default text-white font-small tickets__discount-text" :class="{ 'open': discountOpen }">
        <div class="padding-2 padding--bottom-1">
          <div v-if="discountChild" class="padding--bottom-1">{{ discountChild }}</div>
          <div v-if="discountTeenager" class="padding--bottom-1">{{ discountTeenager }}</div>
          <div v-if="discountHandicap" class="padding--bottom-1">{{ discountHandicap }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tickets',
  props: {
    categories: Array,
    discountChild: {
      required: false,
      type: String
    },
    discountTeenager: {
      required: false,
      type: String
    },
    discountHandicap: {
      required: false,
      type: String
    }
  },
  data () {
    return {
      discountOpen: false,
      swiper: false,
      options: {
        autoplay: {
          delay: 4000
        },
        slidesPerView: 'auto',
        spaceBetween: 15,
        breakpoints: {
          576: {
            slidesPerView: 2,
            spaceBetween: 15
          },
          980: {
            slidesPerView: 4,
            spaceBetween: 15,
            autoplay: true
          }
        }
      }
    };
  }
};
</script>

<style lang="scss">
  .tickets {
    $root: &;
    &__category {
      padding-left: $gap * 2.7;
      position: relative;
    }
    &__category span {
      position: absolute;
      width: $gap * 1.3;
      height: $gap * 1.3;
      display: inline-block;
      background: #fff;
      left: $gap;
      top: 50%;
      transform: translate(0, -50%);
    }
    &__spacer { height: 8px; }
    &__category--1 span { background: #ffd500; }
    &__category--2 span { background: #981b77; }
    &__category--3 span { background: #124a85; }
    &__category--4 span { background: #d3111a; }
    &__category--5 span { background: #f8ab29; }
    &__category--6 span { background: rgb(91, 174, 222); }
    &__category--7 span { background: rgb(178, 193, 21); }
    &__info {
      min-height: 4em;
      margin-top: $gap * 3;
      line-height: 1.1em;
    }
    &__discount {
      cursor: pointer;
    }
    &__discount-text {
      max-height: 0;
      overflow: hidden;
      transition: all .4s $ease-out-circ;
    }
    &__discount {
      &:hover #{$root}__discount-text {
        max-height: 2em;
        transition: all .4s $ease-out-back;
      }
      #{$root}__discount-text.open {
        transition: all .4s $ease-in-circ;
        max-height: 100vh;
      }
    }
  }
</style>

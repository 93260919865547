<template>
  <div class="cta text-default">
    <div class="row padding--top-4 padding--bottom-4">
      <div class="col-12 d-flex justify-content-center"
        :class="{ 'view': visible }"
        v-scroll:in="() => { visible = true; loaded = true }"
        v-scroll:out="() => visible = false">
        <h5 class="text-uppercase" v-text="cta.subHeadline"/>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <h3 v-text="cta.headline"/>
      </div>
      <div class="col-12 d-flex justify-content-center text-align--center">
        <p v-text="cta.text"/>
      </div>
      <div class="col-12 d-inline-flex justify-content-center">
        <a v-if="cta.link && cta.link.external"
          :href="cta.link.url"
          target="_blank"
          class="button-primary button-primary--to-dark text-uppercase padding--top-1 padding--bottom-1 padding--right-2 padding--left-2"
          v-text="cta.link.title"/>
        <router-link
          class="button-primary button-primary--to-dark text-uppercase padding--top-1 padding--bottom-1 padding--right-2 padding--left-2"
          v-if="cta.link && !cta.link.external"
          v-text="cta.link.title"
          :to="{ path: cta.link.url, params: { lang: $i18n.locale} }"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';

export default {
  name: 'Cta',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      cta: {},
      loaded: false,
      visible: false
    };
  },
  methods: {
    async fetchCta () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleCta&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.cta = response.moduleCTA;
        });
    }
  },
  created () {
    this.fetchCta();
  }
};
</script>

import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import SwObserver from './swobserver';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueHead from 'vue-head';
// azure
import Emitter from 'tiny-emitter';
import MsalPlugin from './msal-plugin';
// import { VueMasonryPlugin } from 'vue-masonry'; to enable add "vue-masonry" to package.json

import './styles/global.scss';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['model-viewer'];

// azure setup
// vue 3.0 would be:
Vue.prototype.$emitter = new Emitter();
Vue.use(new MsalPlugin());

// IntersectionObserver - this is the scroll observer (fires at 150px in viewport)
// details in swobserver/index.js
Vue.prototype.$observer = new SwObserver({ rootMargin: '-150px 0px' });
Vue.directive('scroll', {
  bind: function (el, binding, vnode) {
    vnode.context.$root.$observer.add(el, binding, vnode);
  },
  unbind: function (el, binding, vnode) {
    vnode.context.$root.$observer.remove(el, binding, vnode);
  }
});

// IntersectionObserver - this is the second observer (fires directly when object "touches" the viewport)
Vue.prototype.$observer = new SwObserver();
Vue.directive('intersect', {
  bind: function (el, binding, vnode) {
    vnode.context.$root.$observer.add(el, binding, vnode);
  },
  unbind: function (el, binding, vnode) {
    vnode.context.$root.$observer.remove(el, binding, vnode);
  }
});

Vue.use(VueAwesomeSwiper);
Vue.use(VueHead);
// Vue.use(VueMasonryPlugin);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

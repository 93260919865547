<template>
  <div class="content-slider padding--top-4 padding--bottom-8"
       v-if="loaded"
       v-scroll:in="() => { visible = true }"
       v-scroll:out="() => visible = false">
    <div class="position-relative" :class="{'view': visible}">
      <div class="container-fluid">
        <h2 v-if="primaryHeadline"
            class="future-stage-headline text-uppercase text-italic text-align--right text-primary fade-in-child fade-in--bottom delay--100"
            :class="{ 'future-stage-headline': futureStage }"
            v-text="primaryHeadline"/>
        <h3 v-if="secondaryHeadline"
            class="text-italic text-uppercase fade-in-child fade-in--bottom delay--200 text-align--right"
            :class="{ 'future-stage-headline future-stage-headline--indent-right': futureStage }"
            v-text="secondaryHeadline"/>
      </div>
      <div class="container-fluid">
        <div class="row d-none d-md-flex margin--top-4 margin--bottom-4" v-if="slider">
          <div class="col d-flex justify-content-center" v-for="(item, index) in slider.contentSliderSlides" :key="'contentSliderTitle-' + id + '-' + index">
            <a class="content-slider__title-wrapper" @click="changeActiveSliderItem(item, index);">
              <img :class="{ 'content-slider__title-image--active': activeItem === item }"
                   class="content-slider__title-image" v-if="item.titleImage"
                   :alt="item.title || ''"
                   :src="item.titleImage.url"/>
              <h4 v-if="!item.titleImage && item.title"
                  class="content-slider__title"
                  :class="{ 'content-slider__title--active': activeItem === item }"
                  v-text="item.title"/>
            </a>
          </div>
        </div>
        <swiper class="row content-slider__swiper-container d-sm-flex d-md-none margin--top-2 margin--bottom-2" :ref="'titleContentSlider'" :options="titleContentSliderOptions" v-if="slider">
          <swiper-slide v-for="(item, index) in slider.contentSliderSlides" :key="`titleContentSlider-${id}-${index}`">
            <div class="col-auto d-flex justify-content-center">
              <a class="content-slider__title-wrapper" @click="changeActiveSliderItem(item, index);">
                <img v-if="item.titleImage"
                     class="content-slider__title-image"
                     :alt="item.title || ''"
                     :src="item.titleImage.url"/>
                <h4 v-if="!item.titleImage && item.title"
                    class="content-slider__title"
                    v-text="item.title"/>
              </a>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div>
        <swiper class="row content-slider__header margin--top-2 margin--bottom-2" :ref="'headerContentSlider'" :options="headerContentSliderOptions" v-if="slider">
          <swiper-slide v-for="(item, index) in slider.contentSliderSlides" :key="`headerContentSlider-${id}-${index}`">
            <div class="text-align--center">
              <img v-if="item.headerImage"
                   class="content-slider__header-image"
                   :alt="item.title || ''"
                   :src="item.headerImage.preloadedUrl || 'https://media.graphassets.com/resize=h:600,fit:crop/quality=value:70/compress/' + item.headerImage.handle"/>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="container-fluid">
        <swiper class="row" :ref="'contentSlider'" :options="sliderOptions" v-if="slider">
          <swiper-slide class="bgimg--hover" v-for="(item, index) in slider.contentSliderSlides" :key="'contentSlider-' + id + '-' + index">
            <div class="content-slider__swiper-slide fade-in-child fade-in--right delay--200">
              <div class="container-fluid">
                <template v-for="(moduleEntry, moduleIndex) in item.modules">
                  <plain-text v-if="moduleEntry.module === 'text'" :key="`text-${moduleEntry.id}`" :id="moduleEntry.id"/>
                  <youtube v-if="moduleEntry.module === 'video'" :key="`youtube-${moduleEntry.id}`" :id="moduleEntry.id" :class="'view padding--top-4 padding--bottom-4'"/>
                  <image-text v-if="moduleEntry.module === 'imageText'" :key="`image-text-${moduleEntry.id}`" :id="moduleEntry.id"/>
                  <image-gallery v-if="moduleEntry.module === 'gallery'" :key="`gallery-${moduleEntry.id}`" :id="moduleEntry.id" :slidesPerView="moduleEntry.slidesPerView || 1"/>
                  <info-boxes v-if="moduleEntry.module === 'infoBoxes'" :key="`info-boxes-${moduleEntry.id}`" :ids="moduleEntry.ids"/>
                  <map-image v-if="moduleEntry.module === 'map'" :key="`map-${moduleEntry.id}`" :id="moduleEntry.id" :has-container="true"/>
                  <cta v-if="moduleEntry.module === 'CTA'" :key="`cta-${moduleEntry.id}`" :id="moduleEntry.id"/>
                  <results v-if="moduleEntry.module === 'results'" :key="`results-${moduleIndex}`" :settings="moduleEntry"></results>
                  <standings v-if="moduleEntry.module === 'standings'" :key="`standings-${moduleIndex}`" :settings="moduleEntry"></standings>
                  <channel-list v-if="moduleEntry.module === 'channelList'" :key="`standings-${moduleIndex}`" :country-code="moduleEntry.countryCode || 'DE'" class="text-default"/>
                  <event-benefit v-if="moduleEntry.module === 'eventBenefit'" :key="`event-benefit-${moduleIndex}`" :id="moduleEntry.id"/>
                  <partner v-if="moduleEntry.module === 'partner'" :key="`partner-${moduleIndex}`" :name="moduleEntry.name"/>
                  <div v-if="moduleEntry.module === 'model-viewer'" :key="`modelViewer-${moduleIndex}`">
                    <div class="text-align--center" v-if="!item.load3d">
                      <button class="primary button-ghost d-inline-block button-3d" @click="item.load3d = true">
                        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.54541 5.409V12.3635C1.54521 12.6859 1.7451 12.9745 2.04691 13.0876L8.22873 15.4058C8.40325 15.4703 8.59511 15.4703 8.76964 15.4058L14.9515 13.0876C15.2539 12.975 15.4545 12.6863 15.4545 12.3635V5.409C15.4547 5.08668 15.2548 4.79809 14.953 4.68495L8.77118 2.36677C8.59666 2.30222 8.4048 2.30222 8.23027 2.36677L2.04846 4.68495C1.74603 4.79758 1.54545 5.08628 1.54541 5.409ZM13.909 11.828L9.27268 13.5667V8.26268L13.909 6.52404V11.828ZM8.49996 3.91609L12.481 5.409L8.49996 6.90191L4.51886 5.409L8.49996 3.91609ZM3.09086 6.52404L7.72723 8.26268V13.5667L3.09086 11.828V6.52404Z"/>
                          <path d="M0.772727 3.86412C1.19949 3.86412 1.54545 3.51816 1.54545 3.0914V1.54594H3.09091C3.51767 1.54594 3.86364 1.19998 3.86364 0.773216C3.86364 0.34645 3.51767 0.000488281 3.09091 0.000488281H0.772727C0.345962 0.000488281 0 0.34645 0 0.773216V3.0914C0 3.51816 0.345962 3.86412 0.772727 3.86412Z"/>
                          <path d="M13.909 1.54545H15.4544V3.09091C15.4544 3.51767 15.8004 3.86364 16.2271 3.86364C16.6539 3.86364 16.9999 3.51767 16.9999 3.09091V0.772727C16.9999 0.345962 16.6539 0 16.2271 0H13.909C13.4822 0 13.1362 0.345962 13.1362 0.772727C13.1362 1.19949 13.4822 1.54545 13.909 1.54545Z"/>
                          <path d="M0.772727 18.5453H3.09091C3.51767 18.5453 3.86364 18.1993 3.86364 17.7725C3.86364 17.3458 3.51767 16.9998 3.09091 16.9998H1.54545V15.4544C1.54545 15.0276 1.19949 14.6816 0.772727 14.6816C0.345962 14.6816 0 15.0276 0 15.4544V17.7725C0 18.1993 0.345962 18.5453 0.772727 18.5453Z"/>
                          <path d="M16.2271 14.6816C15.8004 14.6816 15.4544 15.0276 15.4544 15.4544V16.9998H13.909C13.4822 16.9998 13.1362 17.3458 13.1362 17.7725C13.1362 18.1993 13.4822 18.5453 13.909 18.5453H16.2271C16.6539 18.5453 16.9999 18.1993 16.9999 17.7725V15.4544C16.9999 15.0276 16.6539 14.6816 16.2271 14.6816Z"/>
                        </svg>
                        Load 3d model
                      </button>
                    </div>
                    <model-viewer
                      v-if="item.load3d"
                      src="/model-viewer/DTM_v10.glb"
                      ar
                      shadow-intensity="4" shadow-softness="0.2"
                      camera-controls
                      camera-orbit="-60deg 70deg 80%"
                      min-camera-orbit="-Infinity 50deg auto"
                      max-camera-orbit="Infinity 90deg auto"
                      class="model-viewer"></model-viewer>
                  </div>
                </template>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <break-after/>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';
import BreakAfter from './layout/BreakAfter';
import Partner from '@/components/Partner';
import EventBenefit from '@/components/events/EventBenefit';
import ChannelList from '@/components/events/ChannelList';
import Standings from '@/components/Standings';
import Results from '@/components/Results';
import Youtube from '@/components/Youtube';
import MapImage from '@/components/MapImage';
import ImageGallery from '@/components/ImageGallery';
import Cta from '@/components/Cta';
import InfoBoxes from '@/components/InfoBoxes';
import PlainText from '@/components/PlainText';
import ImageText from '@/components/ImageText';

export default {
  name: 'ContentSlider',
  components: {
    BreakAfter,
    Partner,
    EventBenefit,
    ChannelList,
    Standings,
    Results,
    Youtube,
    MapImage,
    ImageGallery,
    Cta,
    InfoBoxes,
    PlainText,
    ImageText
  },
  props: {
    id: String,
    futureStage: { type: Boolean, default: false }
  },
  data () {
    return {
      visible: false,
      loaded: false,
      items: [],
      primaryHeadline: null,
      secondaryHeadline: null,
      slider: null,
      activeItem: null,
      sliderOptions: {
        autoHeight: true,
        lazy: true,
        slidesPerView: 1,
        centeredSlides: true,
        shortSwipes: false,
        longSwipes: false,
        followFinger: false,
        initialSlide: 1
      },
      titleContentSliderOptions: {
        centeredSlides: true,
        slidesPerView: 2,
        initialSlide: 1,
        on: {
          slideChange: () => {
            this.$refs.contentSlider.$swiper.slideToLoop(this.$refs.titleContentSlider.$swiper.realIndex);
            this.$refs.headerContentSlider.$swiper.slideToLoop(this.$refs.titleContentSlider.$swiper.realIndex);
          }
        }
      },
      headerContentSliderOptions: {
        centeredSlides: true,
        slidesPerView: 'auto',
        initialSlide: 1,
        shortSwipes: false,
        longSwipes: false,
        followFinger: false,
        speed: 600
      }
    };
  },
  methods: {
    async fetchSlider () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=contentSlider&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          if (response.contentSlider && response.contentSlider.contentSliderSlides) {
            this.sliderOptions.initialSlide = response.contentSlider.initialSlide;
            this.headerContentSliderOptions.initialSlide = this.sliderOptions.initialSlide;
            this.titleContentSliderOptions.initialSlide = this.sliderOptions.initialSlide;
            const preloadImages = [];
            let preloaded = 0;
            response.contentSlider.contentSliderSlides.forEach((slide, index) => {
              if (slide.headerImage) {
                const img = new Image();
                img.onload = () => {
                  slide.headerImage.preloadedUrl = img.src;
                  preloaded++;
                  /**
                   * PRELOAD DONE!!!
                   */
                  if (preloaded === preloadImages.length) {
                    this.slider = response.contentSlider;
                    this.primaryHeadline = this.slider.headline;
                    this.secondaryHeadline = this.slider.subHeadline;
                    this.loaded = true;
                  }
                };
                img.src = 'https://media.graphassets.com/resize=h:600,fit:crop/quality=value:70/compress/' + slide.headerImage.handle;
                preloadImages.push(img);
              }
              if (slide.modules.length) {
                slide.modules.forEach(module => {
                  if (module.module === 'model-viewer') {
                    response.contentSlider.contentSliderSlides[index].load3d = true;
                    this.addModelViewer();
                  }
                });
              }
              if (response.contentSlider.initialSlide === index) {
                this.activeItem = slide;
              }
              response.contentSlider.contentSliderSlides.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0));
            });
          }
        });
    },
    changeActiveSliderItem (item, index) {
      this.activeItem = item;
      this.$refs.contentSlider.$swiper.slideToLoop(index);
      this.$refs.titleContentSlider.$swiper.slideToLoop(index);
      this.$refs.headerContentSlider.$swiper.slideToLoop(index);
    },
    addModelViewer () {
      if (!document.querySelector('#model-viewer-script')) {
        const date = new Date();
        const modelViewerScript = document.createElement('script');
        modelViewerScript.setAttribute('src', '/model-viewer/model-viewer.js?nocache=' + date.getMilliseconds());
        modelViewerScript.setAttribute('type', 'module');
        modelViewerScript.id = 'model-viewer-script';
        document.head.appendChild(modelViewerScript);
      }
    }
  },
  watch: {
    $route () {
      this.fetchSlider();
    }
  },
  created () {
    this.fetchSlider();
  }
};
</script>

<style lang="scss" scoped>
  .model-viewer {
    width: 100%;
    height: 80vh;
    max-height: 500px;
    --poster-color: transparent;
    canvas {
      &, &:focus, &:active {
        outline: none;
      }
    }
  }

  .button-3d {
    position: relative;
    padding-left: $gap * 4;

    svg {
      position: absolute;
      transform: translate(-$gap * 2, 0);

      path {
        fill: $color-primary;
        transition: all .3s;
      }
    }

    &:hover {

      svg {

        path {
          fill: $color-bg;
        }
      }
    }
  }

  .model-viewer {
    width: 100%;
    height: 80vh;
    max-height: 500px;
    --poster-color: transparent;
    canvas {
      &, &:focus, &:active {
        outline: none;
      }
    }
    button {
      position: absolute;
      top: $gap;
      left: $gap;
      z-index: +1;
    }
  }

  .content-slider {
    $root: &;

    &__title-wrapper {
      cursor: pointer;
    }

    &__title {
      transition: color 0.3s, opacity 0.3s;
      opacity: 0.4;
      font-style: italic;

      &--active {
        color: $color-primary;
        opacity: 1;
      }

      &:hover {
        opacity: 1;
      }
    }

    .swiper-slide-active {
      #{$root}__title {
        color: $color-primary;
        opacity: 1;
      }

      #{$root}__title-image {
        filter: saturate(1);
        opacity: 1;
      }
    }

    &__title-image {
      filter: saturate(0);
      transition: filter 0.3s, opacity 0.3s;
      opacity: 0.4;

      &:hover {
        filter: saturate(1);
        opacity: 1;
      }

      &--active {
        filter: saturate(1);
        opacity: 1;
      }
    }

    &__header {
      .swiper-slide {
        width: auto;
        img {
          height: 100%;
          max-height: 50vh;
          filter: brightness(0.3);
          opacity: 0.4;
          transform: scale(0.6);
          transition: filter .6s, opacity .6s, transform .6s $ease-out-cubic;
        }
      }
      .swiper-slide-active {
        img {
          filter: brightness(1);
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  @media all and (max-width: map_get($grid-breakpoints, 'lg')) {
    .content-slider {
      $root: &;

      &__title-image {
        max-width: 130px;
      }
    }
  }

  @media (max-width: 500px) {
    .content-slider {
      &__header {
        .swiper-slide {
          img {
            max-height: 80vw;
          }
        }
      }
    }
  }
</style>

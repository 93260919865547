<template>
  <div
    class="news__item bgimg--hover text-default"
    @click="$router.push({ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: news.slug} })">
    <div class="fade-in-child fade-in--bottom">
      <background-image
        v-if="news.image || news.videoPosterImage"
        :scale="'16-9'"
        :url="news.image ? `https://media.graphassets.com/resize=w:800,fit:crop/output=format:jpg/quality=value:70/compress/${news.image.handle}` : `https://media.graphassets.com/resize=w:800,fit:crop/output=format:jpg/quality=value:70/compress/${news.videoPosterImage.handle}`"
        :video="news.cardVideo"
        :overlay="!news.image"
        :loaded="news.loaded"/>
      <div v-if="!news.image && news.videoPosterImage" class="play-button">
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
          <path d="M24,18 45,32 24,46z"></path>
        </svg>
      </div>
    </div>
    <div class="fade-in-child fade-in--bottom delay--100">
      <div class="text bg-primary bg-primary--condensed topline"><span>{{ news.topline }}</span></div>
    </div>
    <h4 v-text="news.headline" class="fade-in-child fade-in--bottom delay--200"/>
  </div>
</template>

<script>
import BackgroundImage from '../../components/layout/BackgroundImage';
export default {
  name: 'NewsEntry',
  components: { BackgroundImage },
  props: {
    news: {}
  }
};
</script>

<style lang="scss">
.news {
  &__tag {
    display: inline-block;
    padding: 0 1em 0 0;
  }
  &__item {
    cursor: pointer;
    .topline {
      margin-top: -1em;
      margin-left: $gap;
      text-transform: uppercase;
      font-size: 19px;
    }
    &--highlight {
      box-shadow: 0 0 0 3px $color-primary;
      &__title {
        margin: 0 $gap * 5 0 $gap * 2;
        transform: translate(0, -2em);
      }
    }
    &.text-white {
      color: #fff;
    }
  }

  &__load-more {
    cursor: pointer;
    border-width: inherit;
    border-style: inherit;

    &:focus {
      outline: none;
    }
  }
}
@media (max-width: 600px) {
  .news {
    &__item {
      &--highlight {
        &__title {
          margin: 0 $gap 0 $gap * 2;
        }
      }
    }
  }
}
</style>

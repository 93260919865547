<template>
  <div class="teams-detail">
    <div>
      <meta-data
        :meta-title="$t('teams.title')"
        :key="''"/>
      <page-header
        class="bg-default text-white"
        :headline="$t('teams.title')"
        :class-list="''"
        :background-image="''"/>
      <div class="bg-default text-default">
        <team-list :race-series="'DTM'"></team-list>
      </div>
    </div>
  </div>
</template>

<script>
import MetaData from '@/components/MetaData';
import PageHeader from '@/components/layout/PageHeader';
import TeamList from '@/components/driver/TeamList';

export default {
  name: 'Teams',
  components: { TeamList, PageHeader, MetaData },
  data () {
    return {
      slug: this.$route.params.slug,
      currentYear: 0
    };
  },
  methods: {},
  watch: {},
  mounted () {}
};
</script>

<style lang="scss">
</style>

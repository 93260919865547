<template>
  <div class="mydtm-news news">
    <div class="container-fluid">
      <div class="col-12 text-align--center">
        <h3 class="bg-primary h1-sm"><span>{{ data.headline }}</span></h3>
      </div>
      <div class="row margin--bottom-4">
        <div v-for="(highlight, index) in news.highlights"
             :key="index"
             class="col-12">
          <div
            class="news__item bgimg--hover"
            @click="$router.push({ name: $t('navigation.newsDetail'), params: { lang: $i18n.locale, slug: highlight.slug} })"
            :class="{ 'view': highlight.visible }"
            v-scroll:in="() => { highlight.visible = true; highlight.loaded = true }"
            v-scroll:out="() => highlight.visible = false">
            <div class="fade-in-child fade-in--right delay--100 box-shadow">
              <background-image
                :scale="'16-9'"
                :overlay="true"
                :overlay-direction="'right'"
                :url="highlight.image ? highlight.image.url : highlight.videoPosterImage ? highlight.videoPosterImage.url : null"
                :video="highlight.cardVideo"
                :loaded="highlight.loaded"/>
            </div>
            <div class="news__item--highlight__title">
              <h3 class="bg-primary"><span>{{ highlight.headline }}</span></h3>
              <div class="text padding--top-1 font-weight--bold topline text-white"><span v-text="highlight.topline"></span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 margin--bottom-2" v-for="(news, index) in news.entries"
             :key="`my-dtm-news-entry-${index}`">
          <div
            :class="{ 'view': news.visible }"
            v-scroll:in="() => { news.visible = true; news.loaded = true }"
            v-scroll:out="() => news.visible = false">
            <news-entry :news="news" :class="'text-white'"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid margin--bottom-8">
      <div class="row justify-content-center">
        <div class="col-auto">
          <router-link :to="`/${$i18n.locale}/news`"
                       class="text-uppercase button-ghost primary">
            {{ $t('newsOverview.newsReadMore') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Settings from '@/config/settings';
import Axios from 'axios';
import AppConst from '@/config/app.const';
import NewsEntry from '@/components/news/NewsEntry';
import BackgroundImage from '@/components/layout/BackgroundImage';

export default {
  name: 'MydtmNews',
  components: {
    BackgroundImage,
    NewsEntry
  },
  props: {
    id: {
      type: String
    },
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      loaded: false,
      visible: false,
      userPreferences: [],
      preferenceKey: 'MYDTM_PREFERENCE',
      loadedSavedPreferences: false,
      userTags: [],
      news: {
        highlights: [],
        entries: []
      }
    };
  },
  computed: {
    tagIds: function () {
      return this.userTags.join();
    },
    excludes: function () {
      const excludes = [];
      this.news.highlights.forEach(news => {
        excludes.push(news.id);
      });
      return excludes;
    }
  },
  watch: {
    '$msal.isAuthenticated': function () {
      if (this.page && this.page.gated && !this.$msal.isAuthenticated) {
        this.$router.push(`/${this.$i18n.locale}/mydtm`);
      }
    },
    $route () {
      this.fetchHighlightNews();
      this.fetchUserPreferences();
    }
  },
  methods: {
    async fetchUserPreferences () {
      await Axios.get(
        `${Settings.nodeApi.uri}getPreference?userId=${this.$msal.userId}&userPreference=${this.preferenceKey}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          const responseData = response.data && response.data.data && response.data.data.length ? response.data.data : null;
          if (responseData) {
            this.userPreferences = responseData;
            this.userPreferences.forEach(preferenceData => {
              this.userTags.push(preferenceData.t);
            });
            this.fetchUserNews();
          }
        });
    },
    async fetchUserNews () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=newsByTags&tagIds=${this.tagIds}`,
        lifetime: 0
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
          });
          this.news.entries = response.newses;
          if (response.newses.length < 3) {
            this.fetchNewsAll();
          }
        })
        .catch(() => {
          // setTimeout(this.fetchUserNews, 3000);
        });
    },
    async fetchHighlightNews () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=newsHighlights&count=1',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach(news => {
            news.visible = false;
            news.loaded = false;
          });
          this.news.highlights = response.newses;
        })
        .catch(() => {
          // setTimeout(this.fetchHighlightNews, 3000);
        });
    },
    async fetchNewsAll () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=newsAll&count=6&offset=0&exclude=${this.excludes}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
            this.news.entries.push(news);
          });
        })
        .catch(() => {
          // setTimeout(this.fetchNewsAll, 3000);
        });
    }
  },
  created () {
    if (!this.$msal.isAuthenticated) {
      this.$router.push(`/${this.$i18n.locale}/mydtm`);
    } else {
      this.fetchHighlightNews();
      this.fetchUserPreferences();
    }
  }
};
</script>

<style scoped>

</style>

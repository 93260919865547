import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import RaceCalendar from '../views/RaceCalendar';
import PageNotFound from '../views/PageNotFound';
import News from '../views/News';
import Page from '../views/Page';
import i18n from '../i18n';
import RaceCalendarDetail from '../views/RaceCalendarDetail';
import NewsDetail from '../views/NewsDetail';
import redirects from '../assets/json/redirects.json';
import FutureStage from '../views/FutureStage';
import DriverDetail from '@/views/DriverDetail';
import Teams from '@/views/Teams';

Vue.use(VueRouter);

const routes = [];
// add redirects from static json list
redirects.forEach(entry => {
  routes.push(entry);
});
// add internal routes after redirects
routes.push(
  {
    path: '/:lang',
    name: 'home',
    component: Home
  },
  {
    path: '/:lang/dtm-trophy',
    name: 'homeTrophy',
    component: Home
  },
  {
    path: '/:lang/esports',
    name: 'homeEsports',
    component: Home
  },
  {
    path: '/:lang/future',
    name: 'futureStage',
    component: FutureStage
  },
  {
    path: `/:lang/${i18n.t('navigation.events')}`,
    name: `${i18n.t('navigation.events')}`,
    component: RaceCalendar
  },
  {
    path: `/:lang/${i18n.t('navigation.events')}/:slug`,
    name: `${i18n.t('navigation.eventsDetail')}`,
    component: RaceCalendarDetail
  },
  {
    path: `/:lang/${i18n.t('navigation.news')}`,
    name: `${i18n.t('navigation.news')}`,
    component: News
  },
  {
    path: `/:lang/dtm-trophy/${i18n.t('navigation.news')}`,
    name: `${i18n.t('navigation.newsTrophy')}`,
    component: News
  },
  {
    path: `/:lang/${i18n.t('navigation.news')}/dtm`,
    name: `${i18n.t('navigation.news')}-raceseries-DTM`,
    component: News
  },
  {
    path: `/:lang/${i18n.t('navigation.news')}/dtmtrophy`,
    name: `${i18n.t('navigation.news')}-raceseries-DTMTrophy`,
    component: News
  },
  {
    path: `/:lang/${i18n.t('navigation.news')}/dtmesports`,
    name: `${i18n.t('navigation.news')}-raceseries-DTMEsports`,
    component: News
  },
  {
    path: `/:lang/${i18n.t('navigation.news')}/dtmclassic`,
    name: `${i18n.t('navigation.news')}-raceseries-DTMClassic`,
    component: News
  },
  {
    path: `/:lang/${i18n.t('navigation.news')}/:slug`,
    name: `${i18n.t('navigation.newsDetail')}`,
    component: NewsDetail
  },
  {
    path: `/:lang/${i18n.t('navigation.driver')}`,
    name: `${i18n.t('navigation.driver')}-main`,
    component: DriverDetail,
    props: { raceSeries: 'DTM' }
  },
  {
    path: `/:lang/${i18n.t('navigation.driver')}/:slug`,
    name: `${i18n.t('navigation.driverDetail')}`,
    component: DriverDetail,
    props: { raceSeries: 'DTM' }
  },
  {
    path: `/:lang/${i18n.t('navigation.driverTrophy')}`,
    name: `${i18n.t('navigation.driverTrophy')}-main`,
    component: DriverDetail,
    props: { raceSeries: 'DTMTrophy' }
  },
  {
    path: `/:lang/${i18n.t('navigation.teams')}`,
    name: `${i18n.t('navigation.teams')}`,
    component: Teams
  },
  {
    path: '/:lang/*',
    name: 'page',
    component: Page
  },
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '*',
    component: PageNotFound
  }
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  const lang = to.params.lang;
  if (to.name !== i18n.t('navigation.eventsDetail') && ['ru', 'sv', 'it', 'nl'].includes(lang)) {
    router.replace({ name: to.name, params: { lang: 'en', pathMatch: to.params.pathMatch } });
    router.go(0);
  }
  if (i18n.locale !== lang) {
    i18n.locale = lang;
  }
});

export default router;

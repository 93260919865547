import localSettings from './local.env.js';
import devSettings from './dev.env.js';
import testSettings from './test.env.js';
import prodSettings from './prod.env.js';
import merge from 'webpack-merge';

let settings = merge({}, prodSettings);

if (process.env.NODE_ENV === 'development') {
  settings = merge(settings, localSettings);
}
if (window.location.href.indexOf('sw-test.d7mcl0ud.de') > -1 || process.env.NODE_ENV === 'test') {
  settings = merge(settings, testSettings);
}
if (window.location.href.indexOf('sw-dev.d7mcl0ud.de') > -1) {
  settings = merge(settings, devSettings);
}

export default settings;

<template>
  <div v-if="news.entries.length > 0" class="row">
    <div class="col-12 col-sm-6 col-md-4 margin--bottom-2"
         v-for="(news, index) in news.entries"
         :key="`race-series-news-${index}`">
      <div
        :class="{ 'view': news.visible }"
        v-scroll:in="() => { news.visible = true; news.loaded = true }"
        v-scroll:out="() => news.visible = false">
          <news-entry :news="news"/>
        </div>
    </div>
    <div class="container-fluid margin--bottom-8">
      <div class="row justify-content-center">
        <div class="col-auto">
          <router-link :to="`/${$i18n.locale}/news`"
                       class="text-uppercase button-ghost--inverted">
            {{ $t('newsOverview.newsReadMore') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';
import NewsEntry from '../../components/news/NewsEntry';

export default {
  name: 'RaceSeriesNews',
  components: { NewsEntry },
  data () {
    return {
      news: {
        entries: []
      },
      retryMax: 1,
      retryCount: 0
    };
  },
  props: {
    raceSeries: {
      type: String,
      default: 'DTM',
      required: true
    },
    limit: {
      type: Number,
      default: 3,
      required: true
    }
  },
  methods: {
    async fetchNewsByRaceSeries () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=newsByRaceSeries&raceSeries=${this.raceSeries}&limit=${this.limit}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          response.newses.forEach((news) => {
            news.visible = false;
            news.loaded = false;
          });
          this.news.entries = response.newses;
        })
        .catch(() => {
          if (this.retryCount < this.retryMax) {
            this.retryCount++;
            setTimeout(this.fetchNewsByRaceSeries, 3000);
          }
        });
    }
  },
  mounted () {
    this.fetchNewsByRaceSeries();
  }
};
</script>

<template>
  <div class="timetable margin--top-2 margin--bottom-8 padding--top-4 padding--bottom-4 bg-gray">
    <div class="container-fluid">
      <div class="text-align--center">
        <div class="text-align--center h4 font-weight--light text-italic margin--bottom-1">
          {{ title }}
        </div>
        <div class="text-align--center h2 margin--bottom-2">
          {{ $t('raceCalendar.timeTable') }}
        </div>
        <div v-if="pdf && pdf.url" class="margin--bottom-2">
          <a :href="pdf.url" target="_blank" class="button-primary button-primary--to-dark primary d-inline">
            {{ $t('raceCalendar.savePdf') }}
          </a>
        </div>
      </div>
      <div class="row justify-content-between">
        <div v-for="(day, dayName) in days" :key="dayName" class="col-12 col-md-4">
          <div class="bg-default text-white text-align--center padding-1 h4 position-relative" @click="toggle(dayName)">
            <svg fill="currentColor" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
                 :class="{ 'timetable__fold-icon--view': dayName in mobileDaysOpen && mobileDaysOpen[dayName] }"
                 class="timetable__fold-icon">
              <g stroke="none" stroke-width="1" fill-rule="evenodd"><g transform="translate(8.000000, 5.500000)">
                <polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon>
              </g></g>
            </svg>
            {{ day.name }}
          </div>
          <div class="timetable__fold margin--bottom-1" :class="{ 'timetable__fold--view': dayName in mobileDaysOpen && mobileDaysOpen[dayName] }">
            <div class="bg-white text-align--center margin--top-1" v-for="(event, ei) in day.events" :key="dayName + ' ' + ei">
              <div class="bg-medium text-white padding-1 font-weight--bold">{{ event.time }}</div>
              <div class="padding-1">
                <div class="font-weight--bold margin--bottom-1 text-uppercase">{{ event.headline }}</div>
                <div>{{ event.label }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timetable',
  props: {
    entries: Array,
    title: String,
    pdf: Object
  },
  data () {
    return {
      mobileDaysOpen: {}
    };
  },
  computed: {
    days: function () {
      const days = {};
      this.entries.forEach((entry) => {
        const start = new Date(entry.start);
        const end = new Date(entry.end);
        const key = start.getFullYear() + '' + (start.getMonth() + 1) + '' + ('00' + start.getDate()).slice(-2);
        const dayName = this.$i18n.d(start, 'dayAndDate');
        if (!(key in days)) {
          days[key] = {
            name: dayName,
            events: []
          };
        }
        entry.startDate = start;
        entry.endDate = end;
        entry.dayName = dayName;
        entry.time = ('00' + start.getHours()).slice(-2) + ':' + ('00' + start.getMinutes()).slice(-2) + ' - ' + ('00' + end.getHours()).slice(-2) + ':' + ('00' + end.getMinutes()).slice(-2);
        days[key].events.push(entry);
      });
      return days;
    }
  },
  methods: {
    toggle: function (key) {
      const days = Object.assign({}, this.mobileDaysOpen);
      if (key in days) {
        days[key] = !days[key];
      } else {
        days[key] = true;
      }
      this.mobileDaysOpen = days;
    }
  }
};
</script>

<style lang="scss">
  .timetable {
    &__fold-icon {
      display: none;
    }
  }

  @media (max-width: map_get($grid-breakpoints, 'md')) {
    .timetable {
      $root: &;
      &__fold {
        overflow: hidden;
        max-height: 0;
        transition: all .4s $ease-out-cubic;
        &--view {
          max-height: 1000vh;
          transition: all .4s $ease-in-cubic;
        }
      }
      &__fold-icon {
        display: block;
        position: absolute;
        right: $gap;
        top: 50%;
        transform: translate(0, -50%) rotate(-90deg);
        transition: all .3s $ease-out-back;
        &--view {
          transform: translate(0, -50%) rotate(-270deg);
        }
      }
    }
  }
</style>

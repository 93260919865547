<template>
  <div v-if="records.length > 0"
    class="racetrack-record margin--top-2 margin--bottom-8 padding--bottom-8 padding--top-8 bg-default">
    <div class="container-fluid">
      <div class="row padding--bottom-4">
        <div class="col-12">
          <div class="h4 font-weight--light text-italic text-uppercase text-white">
            {{ $t('raceCalendar.dtmRecords')}}
          </div>
          <h2 class="text-white">
            {{ $t('raceCalendar.trackRecords')}}
          </h2>
        </div>
      </div>
      <swiper class="row" :ref="recordSlider.swiper" :options="recordSlider.options">
        <swiper-slide class="col-12 col-md-4 d-flex flex-column" v-for="(record, index) in records" :key="index">
          <div class="h4 racetrack-record__text text-align--center text-primary font-weight--bold text-uppercase margin--bottom-1"
               v-text="record.headline"/>
          <div class="h4 racetrack-record__text text-align--center text-center text-white font-weight--bold text-uppercase margin--bottom-1"
               v-text="record.value"/>
          <div class="racetrack-record__driver d-flex flex-column justify-content-between" v-if="record.driver">
            <div class="racetrack-record__img-wrapper d-flex align-items-end">
              <img class="racetrack-record__img"
                   :src="`https://media.graphassets.com/resize=w:960,fit:crop/quality=value:70/compress/${record.driver.image.handle}`"
                   :alt="record.driver.imageAlt"
                   :title="record.driver.imageTitle"/>
            </div>
            <div class="racetrack-record__button bg-white font-weight--bold text-uppercase text-align--center padding-1">
              {{ record.driver.firstName}} {{ record.driver.lastName}}
            </div>
          </div>
          <div class="racetrack-record__manufacturer d-flex flex-column justify-content-between" v-if="record.manufacturer">
            <div class="racetrack-record__img-wrapper d-flex align-items-center">
              <img class="racetrack-record__img racetrack-record__img--manufacturer"
                   :src="`https://media.graphassets.com/resize=w:960,fit:crop/quality=value:70/compress/${record.manufacturer.image.handle}`"
                   :alt="record.manufacturer.imageAlt"
                   :title="record.manufacturer.imageTitle"/>
            </div>
            <div class="racetrack-record__button bg-white font-weight--bold text-uppercase text-align--center padding-1">
              {{ record.manufacturer.name}}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RacetrackRecords',
  props: {
    records: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      recordSlider: {
        options: {
          breakpoints: {
            576: {
              slidesPerView: 1,
              autoplay: true
            },
            980: {
              slidesPerView: 3,
              autoplay: true
            }
          }
        }
      }
    };
  }
};
</script>

<style lang="scss">
  .racetrack-record {
    .swiper-container, .swiper-wrapper, .swiper-slide {
      box-sizing: inherit;
    }
    &__img-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
    }
    &__img {
      max-width: 100%;
      width: 240px;
      height: 240px;
      &--manufacturer {
        height: 95px;
      }
    }
    &__driver {
      height: 300px;
    }
    &__manufacturer {
      height: 300px;
    }
    &__text {
      height: 50px;
    }
    &__button {
      width: 70%;
      margin: 0 auto;
    }
  }
</style>

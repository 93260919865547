export default class DateProcessor {
  constructor (props = {}) {
    this._targetDate = (props.date ? new Date(props.date) : null);
    this._dd = 0;
    this._hh = 0;
    this._mm = 0;
    this._ss = 0;
    this._view = false;
    this._ended = false;
  }

  setDate (date) {
    this._targetDate = new Date(date);
    this._ended = false;
  }

  updateCountdown () {
    if (this._targetDate !== null) {
      const now = new Date();
      if (now < this._targetDate) {
        let delta = (this._targetDate - now) / 1000; // delta in seconds
        const dd = Math.floor(delta / 86400); // 24 hours in seconds
        delta -= dd * 86400;
        const hh = Math.floor(delta / 3600);
        delta -= hh * 3600;
        const mm = Math.floor(delta / 60);
        delta -= mm * 60;
        const ss = Math.floor(delta % 60);
        this._dd = dd;
        this._hh = hh;
        this._mm = mm;
        this._ss = ss;
        this._view = true;
      } else {
        this._ended = true;
      }
    }
  }

  get dd () {
    return this._dd;
  }

  get hh () {
    return this._hh;
  }

  get mm () {
    return this._mm;
  }

  get ss () {
    return this._ss;
  }

  get view () {
    return this._view;
  }

  get countdownEnded () {
    return this._ended;
  }
}

<template>
  <header :class="{ 'scrolled': headerScrolled }">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-auto align-content-center">
          <router-link to="/" class="--logo-a"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1010 410" class="logo-svg">
            <rect width="1010" height="410" rx="53" ry="53"/>
            <path d="M480.09,330l52-160.07-71.15,37.84c-12.6,6.7-22.1,18.03-26.49,31.6l-29.37,90.62h75.02Z"/>
            <path d="M350.92,108.09c-16.97-22.37-40.91-28.09-65.91-28.09h-133.88l-21.13,65h125c40,0,31.79,27.93,26.24,44.99-3.34,10.26-13.43,41.38-14.29,44-7.85,23.93-21.32,31-38.17,31-11.35,0-44.86,0-62.63,0l27.62-85.01-71.15,37.84c-12.6,6.7-22.1,18.03-26.49,31.6l-26.12,80.59h175.62c53.19,0,77.81-38.41,89.76-75.68,10.86-33.84,13.46-41.94,24.85-76.58s-.04-57.42-9.32-69.65Z"/>
            <path d="M834.06,80l-83.85,145.47-29.5-113.99c-4.8-18.53-21.52-31.48-40.66-31.48h-278.69l-21.16,65.11h309.7l-58.86,31.31c-12.6,6.7-22.1,18.03-26.49,31.6l-39.54,121.98h75.01l38.91-119.81,31.06,119.81h55s69.32-120.03,69.32-120.03l.68,120.03h75V122c0-23.2-18.8-42-42-42h-33.94Z"/>
          </svg></router-link>
        </div>
        <div class="col align-self-center">
          <div class="d-block hide-desktop text-align--right">
            <div @click="menuOpen = true" class="main-menu__open-button" :class="{ 'main-menu__open-button--open': menuOpen }">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="main-menu" :class="{ 'main-menu--open': menuOpen }">
            <div class="d-block hide-desktop">
              <div @click="menuOpen = false" class="main-menu__close-button">
                <div></div>
                <div></div>
              </div>
            </div>
            <div class="main-menu__container--mobile">
              <div class="row no-gutters justify-content-start justify-content-lg-end align-items-lg-center flex-column flex-md-row main-menu__container">
                <div v-for="(item, index) in navItems" :key=index  class="col col-lg-auto main-menu__item" :class="{ 'hover': item.hover }">
                  <a class="main-menu__link main-menu__link--primary" :class="{ 'button-ghost': item.button }"  v-if="item.external && item.url" :href="item.url" target="_blank"><span>{{ item.title }}</span></a>
                  <router-link class="main-menu__link main-menu__link--primary" :class="{ 'button-ghost primary': item.button }" v-if="!item.external && item.url && !item.switch" :to="`/${$i18n.locale}/${item.url}`">
                    <span>{{ item.title }}</span>
                  </router-link>
                  <div class="main-menu__icon dtm-dropdown__arrow-container"
                       v-if="item.headerSubNavItems.length"
                       @click="mobileToggleNavEntry(item)">
                    <svg class="dtm-dropdown__arrow" fill="currentColor" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
                      <g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd">
                        <g id="Icon-24px" transform="translate(8.000000, 5.500000)">
                          <polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div v-if="item.headerSubNavItems.length" class="main-menu__subnav">
                    <div v-for="(subitem, subindex) in item.headerSubNavItems" :key="`${index}-${subindex}`" class="main-menu__subnavitem">
                      <a class="main-menu__subnavlink" :class="{ 'button-ghost': subitem.button }" v-if="subitem.external && subitem.url" :href="subitem.url" target="_blank">
                        {{ subitem.title }}
                      </a>
                      <router-link
                        class="main-menu__subnavlink"
                        v-if="!subitem.external && subitem.url && (subitem.url !== $i18n.locale )"
                        :to="`/${$i18n.locale}/${subitem.url}`">
                        {{ subitem.title }}
                      </router-link>
                      <router-link
                        class="main-menu__subnavlink"
                        v-if="!subitem.external && subitem.url && (subitem.url === $i18n.locale)"
                        :to="`/${$i18n.locale}`">
                        {{ subitem.title }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <LanguageSwitcher class="padding--top-1 padding--top-lg-0" />
                <adb2c v-if="enableAdb2c"></adb2c>
              </div>
            </div>
            <div class="main-menu__close-div" @click="menuOpen = false"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Premium partner banner -->
    <div v-if="partners.length > 0" class="partner">
      <div class="bg-white text-default">
        <div class="container-fluid">
          <swiper class="row justify-content-between" :options="options">
            <swiper-slide v-for="(partner, index) in premiumPartners" :key="index">
              <a class="partner__header-link" :href="partner.link && partner.link.url ? partner.link.url : '#'" target="_blank" :title="partner.link ? partner.link.title : ''">
                <img
                  class="partner__image"
                  :alt="partner.link ? partner.link.title : ''"
                  :width="partner.logo.width"
                  :height="partner.logo.height"
                  :src="partner.logo.url"/>
              </a>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import AppConst from '../config/app.const';
import LanguageSwitcher from './LanguageSwitcher.vue';
import Settings from '../config/settings';
import Adb2c from '@/components/crm/Adb2c';

export default {
  name: 'Header',
  components: {
    Adb2c,
    LanguageSwitcher
  },
  data () {
    return {
      enableAdb2c: false,
      account: undefined,
      headerScrolled: false,
      navItems: [],
      partners: [],
      menuOpen: false,
      options: {
        autoplay: {
          delay: 4000
        },
        slidesPerView: 3,
        breakpoints: {
          480: {
            slidesPerView: 5
          },
          1160: {
            slidesPerView: 8
          }
        },
        centerInsufficientSlides: true
      },
      queryType: this.$store.getters['page/raceSeriesNavigation'],
      switchToggle: true,
      containers: [],
      retryMax: 1,
      retryCount: 0
    };
  },
  watch: {
    '$route' () {
      this.menuOpen = false;
      this.fetchHeaderNavigation();
      this.fetchPartners();
    }
  },
  computed: {
    currentPath: function () {
      let path = this.$route.path;
      if (path.substr(-1) === '/') {
        path = path.substr(0, path.length - 1);
      }
      if (this.$route.params && this.$route.params.slug) {
        path = path.substr(0, path.lastIndexOf('/'));
      }
      return path;
    },
    queryNamePartner: function () {
      return this.$store.getters['page/raceSeries'][0] || 'DTM';
    },
    premiumPartners: function () {
      return this.partners.filter(partner => {
        return partner.premiumPartner;
      });
    }
  },
  methods: {
    async fetchHeaderNavigation () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=navigationByType&type=Header2022',
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          response.navigations.forEach(navigation => {
            navigation.navItems.forEach(item => {
              item.hover = false;
            });
            this.navItems = navigation.navItems;
          });
        })
        .catch(() => {
          if (this.retryCount < this.retryMax) {
            this.retryCount++;
            setTimeout(this.fetchHeaderNavigation, 3000);
          }
        });
    },
    mobileToggleNavEntry (item) {
      if (item.hover) {
        item.hover = false;
      } else {
        this.navItems.forEach((nav) => {
          nav.hover = false;
        });
        item.hover = true;
      }
    },
    async fetchPartners () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=partnersByRaceSeries&raceSeries=${this.queryNamePartner}&supplier=0`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          if (response.partners) {
            this.partners = response.partners;
          }
        });
    }
  },
  created () {
    // fetch the navigation items
    this.fetchHeaderNavigation();
    this.fetchPartners();
    // we watch the scrollTop value
    this.unwatchScrollTop = this.$store.watch(() => this.$store.getters['scroll/scrollTop'], scrollTop => {
      // set headerScrolled true/false if threshold is reached
      this.headerScrolled = scrollTop > 50;
    });
  },
  beforeDestroy () {
    this.unwatchScrollTop();
  }
};
</script>

<style lang="scss">
.logo-svg {
  margin: 15px 15px 15px 0;
  transition: all .5s $ease-out-cubic;
  width: 160px;
  rect {
    fill: black;
  }
  path {
    fill: $color-primary;
  }
  &:hover {
    rect {
      fill: $color-primary;
      transition: all .3s;
    }
    path {
      fill: black;
      transition: all .3s;
    }
  }
}
.logo-a {
  outline: none;
  line-height: 0;
  font-size: 0;
  display: block;

}
.logo-b {
  outline: none;
  line-height: 0;
  font-size: 0;
  display: inline-block;
  .logo-svg rect {
    fill: $color-bg-trophy;
  }
  &:hover {
    .logo-svg rect {
      fill: $color-bg-dark-trophy;
    }
  }
}
.main-menu {
  $mainmenu: &;
  //font-family: var(--font-condensed);
  font-size: 15px;
  z-index: 10;
  &__link {
    text-decoration: none;
    text-transform: uppercase;
    //font-style: italic;
    transition: color 0.3s ease;
    color: #000;
    display: inline-block;
    position: relative;
    padding: 5px 15px;
    cursor: pointer;
    span {
      position: relative;
      display: block;
    }
    &:before, &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      right: 100%;
      background: $color-primary;
      transform: skewX(-10deg);
      transition: right 0s linear .3s,left .3s cubic-bezier(.25,.46,.45,.94);
    }
    &:after {
      display: none;
      z-index: -1;
      left: 0;
      right: 0;
      background: #000;
    }
    &:hover {
      color: #000;
    }
    &--dark {
      color: #fff;
      &:hover {
        color: #000;
      }
      &:after {
        display: block;
      }
    }
  }
  &__item {
    position: relative;
  }
  &__item:hover #{$mainmenu}__link::before, &__link.active::before, &__link.router-link-active::before, {
    left: 0;
    right: 0;
    transition: right .3s cubic-bezier(.25,.46,.45,.94),left 0s linear 0s;
  }
}
header {
  background: linear-gradient(0deg, #eee, #fff);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 900;
  transition: background .3s linear;

  &.scrolled {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    .logo-svg {
      margin: 10px 10px 10px 0;
      width: 126px;
    }
  }

  .partner {
    height: 50px;
    &__header-link {
      width: 100%;
      display: block;
      text-align: center;
    }

    &__image {
      width: auto;
      height: 50px;
      max-width: 100%;
    }

    &__hr {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      border: 0;
      border-top: 1px solid $color-bg;
      opacity: 0.8;
    }

    &__second-level {
      transform: scale(0.75);
    }
    .swiper-container {
      line-height: 0;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .main-menu {
    $mainmenu: &;
    &__subnav {
      position: absolute;
      right: 0;
      top: 100%;
      min-width: 100%;
      padding: $gap 0 $gap / 2 0;
      transform: translate(0, -1px);
      pointer-events: none;
      transition: all .3s $ease-out-quad;
      z-index: 11;
      &:before {
        content: '';
        position: absolute;
        left: 100%;
        top: $gap / 2;
        bottom: 0;
        right: 100%;
        transition: right 0s linear .3s,left .3s $ease-out-quad, box-shadow .3s linear;
        background: rgba($color-bg-dark, 1);
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        //transform: skewX(-13deg);
        z-index: -1;
      }
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: $gap;
        top: 0;
        border-style: solid;
        border-width: 0 5px 10px 5px;
        border-color: transparent transparent $color-bg-dark transparent;
        opacity: 0;
        transition: all .1s ease-out;
      }
    }
    &__subnavitem {
      position: relative;
      text-align: right;
      opacity: 0;
      transition: all .2s linear;
      a, span {
        display: block;
        cursor: pointer;
        padding: $gap / 2 $gap $gap / 2 $gap * 2;
        text-transform: initial;
        white-space: nowrap;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 0;
          background: $color-primary;
          transition: all .3s linear;
        }
        &:hover, &.router-link-active, &.router-link-exact-active, &.active {
          color: $color-primary;
          &:before {
            width: 5px;
          }
        }
      }
      //&:first-child {
      //  a, span {
      //    &:before {
      //      top: -$gap / 2;
      //    }
      //  }
      //}
      //&:last-child {
      //  a, span {
      //    &:before {
      //      bottom: -$gap / 2;
      //    }
      //  }
      //}
    }
    &__icon {
      display: none;
    }
    &__item {
      &:hover {
        #{$mainmenu}__subnavitem {
          opacity: 1;
          transition: all .3s linear .3s;
        }
        #{$mainmenu}__subnav {
          pointer-events: all;
          &:before {
            right: 0;
            left: 0;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
            transition: right .3s $ease-out-quad,left 0s linear 0s, box-shadow .3s linear .2s;
          }
          &:after {
            opacity: 1;
            transition: all .3s linear .3s;
          }
        }
      }
    }
  }
}

@media (max-width: map_get($grid-breakpoints, 'lg')) and (min-width: map_get($grid-breakpoints, 'md')) {
  header {
    .logo-svg, &.scrolled .logo-svg {
      margin: 10px 10px 10px 0;
      width: 100px;
    }
    /* Style for NavItems in Dtm-Trophy in burger menu */
    &.dtm-trophy {
      .main-menu {
        &__link {
          color: #000;
          &:hover {
            color: $color-primary-trophy;
          }
        }
      }
    }
  }

  .main-menu {
    $root: &;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    background: rgba($color-bg-dark, 0);
    padding: $gap $gap $gap $gap * 2;
    text-align: right;
    transform: translate(100%, 0);
    transition: transform .4s $ease-in-quad .2s, background .2s linear;
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $color-bg-dark;
    }
    &__container--mobile {
      padding-right: $gap * 2;
      height: calc(100vh - 60px);
      overflow: auto;
    }
    .row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: masonry;
      .col-auto {
        opacity: 0;
        transform: translate($gap, 0);
        transition: all .2s linear .4s;
      }
    }
    &__open-button {
      transform: translate(0, 4px);
      width: 48px;
      height: 48px;
      display: inline-block;
      position: relative;
      div {
        height: 2px;
        background: #000;
        width: 24px;
        position: absolute;
        left: 12px;
        transition: all .3s $ease-out-quad;
        &:nth-child(1) {
          top: 16px;
          transition-delay: .7s;
        }
        &:nth-child(2) {
          top: 50%;
          transform: translate(0, -50%);
          transition-delay: .75s;
        }
        &:nth-child(3) {
          bottom: 16px;
          transition-delay: .8s;
        }
      }
      &--open {
        div {
          width: 0;
          &:nth-child(1) {
            transition-delay: 0s;
          }
          &:nth-child(2) {
            transition-delay: .05s;
          }
          &:nth-child(3) {
            transition-delay: .1s;
          }
        }
      }
    }
    &__close-div {
      position: absolute;
      left: 0;
      width: $gap * 2;
      top: 0;
      bottom: 0;
    }
    &__close-button {
      width: 48px;
      height: 40px;
      display: inline-block;
      position: relative;
      div {
        position: absolute;
        width: 0;
        height: 2px;
        background: #fff;
        transition: all .3s $ease-out-quad;
        &:nth-child(1) {
          top: 10px;
          left: 12px;
          transform: rotate(45deg);
          transform-origin: left center;
          transition-delay: 0s;
        }
        &:nth-child(2) {
          bottom: 10px;
          left: 12px;
          transform: rotate(-45deg);
          transform-origin: left center;
          transition-delay: .1s;
        }
      }
    }
    &__item {
      padding-bottom: $gap * 4;
    }
    &__link {
      display: inline-block;
      padding: $gap * .8 $gap * 1.5 $gap * .8 $gap;
      margin-bottom: 4px;
      font-weight: bold;
      white-space: nowrap;
      color: #fff;
      &.button-ghost {
        display: inline-block;
      }
      &.switch {
        padding: 0;
      }
      &.router-link-active, &:hover {
        color: #000;
      }
    }
    &__icon {
      display: block;
      position: absolute;
      pointer-events: none;
      top: $gap;
      right: 0;
      width: $gap * 2;
      padding-left: 3px;
      height: 1.34em;
      transform: translate(8px, -3px);
      line-height: 0;
      overflow: hidden;
      text-align: left;
      svg {
        transition: all .4s $ease-out-back;
        transform: rotate(-90deg);
      }
    }
    &__subnav {
      position: relative;
      transition: all .3s $ease-out-quad;
      padding-right: $gap;
      z-index: +1;
      border-right: 1px solid $color-primary;
    }
    &__subnavitem {
      a, span {
        display: block;
        position: relative;
        padding: $gap / 2 $gap / 2 $gap / 2 $gap * 2;
        &:before {
          content: '';
          position: absolute;
          right: -$gap;
          top: 0;
          bottom: 0;
          width: 0;
          background: $color-primary;
          transition: all .3s linear;
        }
        &.router-link-active, &.router-link-exact-active, &.active, &:hover {
          color: $color-primary;
          &:before {
            width: 5px;
          }
        }
      }
    }
    .row.adb2c {
      grid-template-columns: repeat(1, 1fr);
      position: absolute;
      right: $gap * 3;
      bottom: $gap;
    }
    &--open {
      background: rgba($color-bg-dark, .7);
      transform: translate(0, 0);
      transition: transform .4s $ease-out-quad .3s, background .3s linear .7s;
      #{$root}__close-button {
        div {
          width: 26px;
          &:nth-child(1) {
            transition-delay: .7s;
          }
          &:nth-child(2) {
            transition-delay: .8s;
          }
        }
      }
      .row {
        .col-auto {
          opacity: 1;
          transform: translate(0, 0);
          transition: all .3s $ease-out-quad .7s;
        }
      }
    }
  }
}

@media (max-width: map_get($grid-breakpoints, 'md')) {
  header {
    .logo-svg, &.scrolled .logo-svg {
      margin: 10px 10px 10px 0;
      width: 100px;
    }
    /* Style for NavItems in Dtm-Trophy in burger menu */
    &.dtm-trophy {
      .main-menu {
        &__link {
          color: #ffffff;
          &:hover {
            color: $color-primary-trophy;
          }
        }
      }
    }
  }
  .main-menu {
    $mainmenu: &;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    background: rgba($color-bg-dark, 0);
    padding: $gap $gap $gap $gap * 2;
    text-align: right;
    transform: translate(100%, 0);
    transition: transform .4s $ease-in-quad .2s, background .2s linear;
    .row {
      margin: 0;
    }
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $color-bg-dark;
    }
    &__container--mobile {
      overflow: hidden;
      overflow-y: auto;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      left: 30%;
      right: -50%;
      top: 0;
      bottom: 0;
      transform: skewX(7deg) translate(50%, 0);
      z-index: -1;
      transition: all 0s linear .5s;
    }
    &__close-div {
      position: absolute;
      left: 0;
      width: $gap * 2;
      top: 0;
      bottom: 0;
    }
    &__close-button {
      width: 48px;
      height: 40px;
      display: inline-block;
      position: relative;
      div {
        position: absolute;
        width: 0;
        height: 2px;
        background: #fff;
        transition: all .3s $ease-out-quad;
        &:nth-child(1) {
          top: 10px;
          left: 12px;
          transform: rotate(45deg);
          transform-origin: left center;
          transition-delay: 0s;
        }
        &:nth-child(2) {
          bottom: 10px;
          left: 12px;
          transform: rotate(-45deg);
          transform-origin: left center;
          transition-delay: .1s;
        }
      }
    }
    &__open-button {
      transform: translate(0, 4px);
      width: 48px;
      height: 48px;
      display: inline-block;
      position: relative;
      div {
        height: 2px;
        background: #000;
        width: 24px;
        position: absolute;
        left: 12px;
        transition: all .3s $ease-out-quad;
        &:nth-child(1) {
          top: 16px;
          transition-delay: .7s;
        }
        &:nth-child(2) {
          top: 50%;
          transform: translate(0, -50%);
          transition-delay: .75s;
        }
        &:nth-child(3) {
          bottom: 16px;
          transition-delay: .8s;
        }
      }
      &--open {
        div {
          width: 0;
          &:nth-child(1) {
            transition-delay: 0s;
          }
          &:nth-child(2) {
            transition-delay: .05s;
          }
          &:nth-child(3) {
            transition-delay: .1s;
          }
        }
      }
    }
    &__link {
      display: block;
      color: rgba(255, 255, 255, 0.8);
      padding: $gap;
      &:before, &:after {
        display: none;
      }
      &--primary {
        font-size: 18px;
      }
      &.switch {
        display: inline-block;
      }
      &.router-link-active {
        color: $color-primary;
      }
    }
    &__item {
      position: relative;
      //margin-right: $gap * 2;
      padding-right: $gap * 3 !important;
      width: 100%;
    }
    &__icon {
      display: block;
      position: absolute;
      top: $gap;
      right: 0;
      width: $gap * 3.5;
      padding-left: 3px;
      height: 1.34em;
      transform: translate(0, 3px);
      line-height: 0;
      overflow: hidden;
      text-align: left;
      svg {
        transition: all .4s $ease-out-back;
        transform: rotate(-90deg);
      }
    }
    &__subnav {
      position: relative;
      pointer-events: none;
      transition: all .3s $ease-out-quad;
      transform: translate($gap / 2, 0);
      max-height: 0;
      overflow: hidden;
      z-index: +1;
      border-right: 1px solid $color-primary;
    }
    &__subnavitem {
      position: relative;
      text-align: right;
      opacity: 0;
      transform: translate(-10px, 0);
      transition: opacity .2s linear, transform 0s linear .2s;
      a, span {
        display: block;
        cursor: pointer;
        padding: $gap / 2 $gap * 1.5 $gap / 2 $gap * 2;
        text-transform: initial;
        white-space: nowrap;
        position: relative;
        color: rgba(255, 255, 255, 0.8);
        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 0;
          background: $color-primary;
          transition: all .3s linear;
        }
        &.router-link-active, &.router-link-exact-active, &.active {
          color: $color-primary;
          &:before {
            width: 5px;
          }
        }
      }
    }
    &__item {
      &.hover {
        #{$mainmenu}__icon {
          svg {
            transform: rotate(90deg);
          }
        }
        #{$mainmenu}__subnavitem {
          opacity: 1;
          transform: translate(0, 0);
          transition: all .3s ease-out .3s;
          @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
              transition-delay: .3s + (.1s * $i);
            }
          }
        }
        #{$mainmenu}__subnav {
          transition: all .3s $ease-in-cubic;
          pointer-events: all;
          max-height: 100vh;
        }
      }
    }
    &__item.col {
      opacity: 0;
      transform: translate($gap, 0);
      transition: all 0s linear .5s;
    }
    &--open {
      background: rgba($color-bg-dark, .7);
      transform: translate(0, 0);
      transition: transform .4s $ease-out-quad .3s, background .3s linear .7s;
      #{$mainmenu}__close-button {
        div {
          width: 26px;
          &:nth-child(1) {
            transition-delay: .7s;
          }
          &:nth-child(2) {
            transition-delay: .8s;
          }
        }
      }
      //.row {
      //  .col-auto {
      //    opacity: 1;
      //    transform: translate(0, 0);
      //    transition: all .3s $ease-out-quad .7s;
      //  }
      //}
      #{$mainmenu}__item.col {
        opacity: 1;
        transform: translate(0, 0);
        transition: all .4s $ease-out-cubic .8s;
        @for $i from 1 through 15 {
          &:nth-child(#{$i}) {
            transition-delay: .8s + (0.1s * $i);
          }
        }
      }
      &:after {
        transition: all .4s ease-out .6s;
        transform: skewX(-7deg);
      }
    }
  }
}

@media all and (min-width: 769px) {
  .hide-desktop {
    display: none;
  }
}
</style>

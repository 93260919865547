<template>
  <div class="news-navigation padding--bottom-4">
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-auto py-2 py-md-0" v-for="(item, index) in navItems" :key=index>
        <router-link class="button-ghost small" v-if="!item.external && item.url && !item.switch" :to="`${item.url}`">
          {{ item.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '@/config/app.const';
import Settings from '@/config/settings';

export default {
  name: 'NewsNavigation',
  data () {
    return {
      navItems: [],
      retryCount: 0,
      retryMax: 1
    };
  },
  watch: {
    '$route' () {
      this.fetchNewsNavigation();
    }
  },
  methods: {
    async fetchNewsNavigation () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=navigationByType&type=News',
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          response.navigations.forEach(nav => {
            this.navItems = nav.navItems;
          });
        })
        .catch(() => {
          if (this.retryCount < this.retryMax) {
            this.retryCount++;
            setTimeout(this.fetchNewsNavigation, 3000);
          }
        });
    }
  },
  created () {
    this.fetchNewsNavigation();
  }
};
</script>

<style scoped lang="scss">
.news-navigation {
  .router-link-active, .router-link-exact-active {
    color: #fff;
    background: $color-bg;
  }
}
</style>

<template>
  <div
    class="bgimg"
    :class="classObject"
    :title="title"
    :style="styleObject"
    v-scroll:in="isIntersecting"
  ><div
    class="bgimg__image"
    :class="{ 'bgimg__image--loaded': imageLoaded, 'bgimg__image--scroll-scale': scrollScale }"
    :style="{ 'background-image': 'url(' + ((loaded || (this.intersect && this.intersected)) && imageLoaded ? imageSrc : '') + ')' }"><video v-if="video && video.url && loaded" muted autoplay loop playsinline="true" :src="video.url"></video>
  </div></div>
</template>

<script>
export default {
  name: 'BackgroundImage',
  props: {
    url: { type: String, default: '' },
    urlMobile: { type: String, default: '' },
    video: { type: Object, default: null },
    classList: { type: String, default: '' },
    preloadWhite: { type: Boolean, default: false },
    overlay: { type: Boolean, default: false },
    overlayDirection: String,
    overlayColor: { type: String, default: '' },
    scrollScale: { type: Boolean, default: true },
    scale: String, // examples: '16-9', '1-1', 'original'
    loaded: { type: Boolean, default: false },
    title: { type: String, default: 'dtm.com' },
    full: { type: Boolean, default: false },
    fixed: { type: Boolean, default: false },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    intersect: { type: Boolean, default: false }
  },
  data () {
    return {
      imageLoaded: false,
      imageLoading: true,
      imageSrc: '',
      imageHeight: 9,
      imageWidth: 16,
      image: null,
      winWidth: 0,
      winHeight: 0,
      intersected: false
    };
  },
  computed: {
    classObject: function () {
      const classes = {};
      if (this.preloadWhite) {
        classes['bgimg--preload-white'] = true;
      }
      // overlay
      classes.overlay = this.overlay || this.overlayDirection || this.overlayColor;
      if (this.overlayDirection) {
        classes['overlay--' + this.overlayDirection] = true;
      }
      if (this.overlayColor) {
        classes['overlay--' + this.overlayColor] = true;
      }
      if (this.scale) {
        classes['scale_' + this.scale] = true;
      }
      if (this.full) {
        classes['position-absolute position-absolute--full'] = true;
      } else if (!this.scale) {
        if (!this.width && !this.height) {
          classes['scale_16-9'] = true;
        }
      }
      if (this.fixed) {
        classes['bgimg--fixed'] = true;
      }
      // own classList
      if (this.classList) {
        classes[this.classList] = true;
      }
      return classes;
    },
    styleObject: function () {
      const styles = {};
      if (!this.full && !this.scale && this.width && this.height) {
        styles['padding-top'] = (this.height * 100 / this.width) + '%';
      }
      if (this.scale === 'original') {
        styles['padding-top'] = (this.imageHeight * 100 / this.imageWidth) + '%';
      }
      return styles;
    }
  },
  methods: {
    isIntersecting () {
      this.intersected = true;
    },
    animate () {
      // preload image
      const w = window.innerWidth;
      if (w !== this.winWidth) {
        this.winWidth = w;
      }
      const url = this.winWidth < 768 && this.urlMobile ? this.urlMobile : this.url;
      if (this.loaded || (this.intersect && this.intersected)) {
        if (this.imageSrc !== url) {
          this.imageSrc = url + '';
          this.imageLoading = true;
          this.imageLoaded = false;
          setTimeout(() => {
            this.$emit('loaded');
          }, 50);
        }
        if (this.imageLoading && !this.imageLoaded) {
          window.cancelAnimationFrame(this.animate);
          this.image.src = url;
          this.imageLoading = false;
        }
      }
      window.requestAnimationFrame(this.animate);
    }
  },
  watch: {
    url: function (val) {
      if (val !== this.imageSrc) {
        window.requestAnimationFrame(this.animate);
      }
    }
  },
  created () {
    this.image = new Image();
    this.image.onload = () => {
      this.imageHeight = this.image.height;
      this.imageWidth = this.image.width;
      this.imageLoaded = true;
      this.imageLoading = false;
    };
    window.requestAnimationFrame(this.animate);
  },
  destroyed () {
    window.cancelAnimationFrame(this.animate);
  }
};
</script>

<style scoped>

</style>

<template>
  <div class="dtm-dropdown col-auto">
    <div class="main-menu__item d-none d-lg-block">
      <div class="text-uppercase">
        <span class="main-menu__languagename main-menu__link main-menu__link--dark">
          <span>
            {{ $i18n.locale }}
          </span>
        </span>
      </div>
      <div class="main-menu__subnav">
        <div class="main-menu__subnavitem text-uppercase text-condensed"
             v-for="(language, id) in languages" :key="'langugagex-' + id"
             @click="changeLanguage(language.locale)">
          <span :class="{ 'active': language.locale === $i18n.locale }">{{ language.lang }}</span>
        </div>
      </div>
    </div>
    <div class="d-lg-none text-align--right padding--bottom-1 text-condensed">
      <span v-for="(language, id) in languages" :key="'langugagex-mobile-' + id"
            @click="changeLanguage(language.locale)">
        <span :class="{ 'text-primary': language.locale === $i18n.locale }" class="padding--left-1 padding--right-1">{{ language.lang }}</span>
      </span>
    </div>
<!--    <div class="dtm-dropdown__trigger font-weight&#45;&#45;bold text-uppercase">-->
<!--      <span class="dtm-dropdown__locale">{{ $i18n.locale }}</span>-->
<!--      <div class="dtm-dropdown__arrow-container">-->
<!--        <svg class="dtm-dropdown__arrow" fill="currentColor" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">-->
<!--          <g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd">-->
<!--            <g id="Icon-24px" transform="translate(8.000000, 5.500000)">-->
<!--              <polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon>-->
<!--            </g>-->
<!--          </g>-->
<!--        </svg>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="dtm-dropdown__content text-align&#45;&#45;center">-->
<!--      <div class="dtm-dropdown__link-container">-->
<!--        <span class="dtm-dropdown__link text-uppercase text-condensed"-->
<!--        v-for="(language, id) in languages" :key="'langugage-' + id"-->
<!--        @click="changeLanguage(language.locale)">-->
<!--          {{ language.lang }}-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import settings from '../config/settings';

export default {
  name: 'LanguageSwitch',
  data () {
    return {
      languages: [],
      languageNames: {
        de: 'Deutsch',
        en: 'English',
        ru: 'русский',
        sv: 'svenska',
        it: 'italiano',
        nl: 'Nederlands'
      }
    };
  },
  methods: {
    changeLanguage (locale) {
      const slug = this.$store.getters[AppConst.GET_LANGUAGE_SLUG](locale);
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        if (slug) {
          if (this.$route.name === 'page') {
            // e.g. dtm-trophy
            this.$router.push({ path: `/${this.$i18n.locale}/${slug}` });
            // driver detail with second level translations. We have to check and translate two paths
          } else if (this.$route.name === 'driver-detail') {
            this.$router.push({ path: `/${this.$i18n.locale}/${this.$t('navigation.driver')}/${slug}` });
          } else if (this.$route.name === 'fahrer-main' || this.$route.name === 'driver-main' || this.$route.name === 'driver-dtm-trophy-main' || this.$route.name === 'fahrer-dtm-trophy-main') {
            // driver overview
            this.$router.push({ path: `/${this.$i18n.locale}/${slug}` });
          } else {
            // push against current route with slug
            // e.g. news-detail
            this.$router.push({ name: this.$route.name, params: { lang: locale, slug: slug } });
          }
        } else {
          // push against current route and just change locale
          // e.g. news
          this.$router.push({ name: this.$route.name, params: { lang: locale } });
        }
        this.$router.go(0);
      }
    },
    setLocales () {
      const languages = [];
      this.$store.getters[AppConst.GET_LOCALES].forEach(val => {
        languages.push({ locale: val, lang: this.languageNames[val] });
      });
      this.languages = languages;
    },
    /**
     * create <link> tags for canonical language URLs inside <head>
     */
    setHeadLinks () {
      // delete existing link tags
      const links = document.querySelectorAll('link[hreflang]');
      if (links) {
        links.forEach(node => node.remove());
      }
      const locales = this.$store.getters[AppConst.GET_LOCALES];
      const slugs = this.$store.getters[AppConst.GET_LANGUAGE_SLUGS];
      const currentRoute = this.$router.currentRoute;
      const headDOM = document.querySelector('head');
      // for each locale (en, de, ...)
      locales.forEach(locale => {
        const slugKey = 'slug' + locale.toUpperCase();
        const params = currentRoute.params;
        params.lang = locale;
        if (slugKey in slugs) {
          // set the slug if it exists
          params.pathMatch = slugs[slugKey];
          params.slug = slugs[slugKey];
        }
        // create the href link via router
        let newRoute;
        if (slugs) {
          if (currentRoute.name === 'page') {
            newRoute = this.$router.resolve({ path: `/${locale}/${params.slug}` });
          } else {
            newRoute = this.$router.resolve({ name: currentRoute.name, params: params });
          }
        } else {
          newRoute = this.$router.resolve({ name: currentRoute.name, params: { lang: locale } });
        }
        // create dom element
        const linkDOM = document.createElement('link');
        linkDOM.href = settings.baseUrl + newRoute.href;
        linkDOM.rel = 'alternate';
        linkDOM.hreflang = locale;
        headDOM.appendChild(linkDOM);
      });
    }
  },
  created () {
    this.unwatchLocales = this.$store.watch(
      (state, getters) => getters[AppConst.GET_LOCALES],
      () => {
        this.setLocales();
        this.setHeadLinks();
      }
    );
    this.unwatchLanguages = this.$store.watch(
      (state, getters) => getters[AppConst.GET_LANGUAGE_SLUGS],
      (slugs) => {
        this.setHeadLinks();
      }
    );
    this.setLocales();
    this.setHeadLinks();
  },
  beforeDestroy () {
    this.unwatchLocales();
    this.unwatchLanguages();
  }
};
</script>

<style lang="scss" scoped>
  .dtm-dropdown {
    $root: &;

    &:hover {
      #{$root}__content {
        visibility: visible;
        opacity: 1;
      }

      #{$root}__arrow {
        transform: rotate(90deg) translate(-2px, 0);
      }

      #{$root}__locale {
        color: $color-primary;
      }
    }

    &__locale {
      margin-right: 3px;
      transition: color 0.3s ease;
    }

    &__arrow-container {
      display: inline;
      margin-right: $gap;
    }

    &__arrow {
      transform: rotate(-90deg) translate(2px, 0);
      position: absolute;
      transition: 0.5s;
    }

    &__content {
      visibility: hidden;
      position: absolute;
      right: 0;
      top: 100%;
      min-width: 110px;
      z-index: 20;
      transition: opacity .4s ease-in-out;
      opacity: 0;
      padding-top: 10px;
    }

    &__link-container {
      background: $color-bg-med;
      padding: 17px 0;
      border-top: solid 12px $color-primary;
    }

    &__link {
      display: block;
      padding: 10px 0;
      cursor: pointer;
      &:hover {
        color: $color-primary;
      }
    }
  }

  /* Style for Language Switcher in Dtm-Trophy */
  .dtmTrophyStyle {
    &:hover {
      .dtm-dropdown__locale {
        color: $color-bg-dark-trophy;
      }
    }

    .dtm-dropdown__locale {
      color: $color-bg-trophy;
     }

    .dtm-dropdown__link-container {
      border-top: solid 12px $color-bg-dark-trophy;
    }

    .dtm-dropdown__arrow {
        color: $color-bg-trophy;
      }

    .dtm-dropdown__content {
        color: #ffffff;
      }
  }

  @media (max-width: map_get($grid-breakpoints, 'lg')) {
    .dtm-dropdown {
      position: fixed;
      top: 10px;
      left: 0;
      span {
        cursor: pointer;
      }
      .main-menu__icon {
        display: none;
      }
      .main-menu__subnav {
        display: inline-block;
        opacity: 1;
        pointer-events: all;
      }
      &__trigger {
        padding: $gap;
      }
      &__arrow-container {
        margin-right: $gap;
      }
      &__content {
        right: $gap;
        top: auto;
        bottom: 2.5em;
      }
    }
  }
</style>

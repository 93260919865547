<template>
  <div class="mydtm-benefits">
    <div class="mydtm-benefits__image">
      <background-image
        v-if="data.imageOne && data.imageOne.handle"
        :scale="'original'"
        :url="data.imageOne.url"
        :loaded="true"
        :full="true"
        :title="data.imageOne.alt"/>
      <div class="mydtm-benefits__overlay"></div>
    </div>
    <div class="position-relative" v-if="data">
      <div class="container-custom padding--top-6 padding--bottom-6"
           :class="{ 'view': visible }"
           v-scroll:in="() => { visible = true; loaded = true }"
           v-scroll:out="() => visible = false">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-5">
            <div class="fade-in-child fade-in--bottom delay--100" v-if="data.headline">
              <h3 class="bg-primary h1-sm"><span>{{ data.headline }}</span></h3>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 offset-lg-3">
            <h4 v-html="data.subHeadline" class="padding--top-4 fade-in-child fade-in--bottom delay--200 h3-sm"></h4>
            <div class="padding--top-4">
              <ul class="mydtm-benefits__list fade-in-child fade-in--bottom delay--300">
                <li v-for="(line, i) in singleText" :key="`sl-${i}`">
                  <div class="icon" v-if="line.icon"><img :src="`/mydtm/${line.icon}.svg`" alt=""></div>
                  {{ line.text }}
                </li>
              </ul>
            </div>
            <div class="padding--top-2 fade-in-child fade-in--bottom delay--400" v-html="data.richText.html" v-if="data.richText && data.richText.html"></div>
            <div class="row padding--top-4 fade-in-child fade-in--bottom delay--500">
              <div class="col-md col-12 padding--bottom-1" v-for="(link, i) in data.links" :key="`link-${i}`">
                <a v-if="link.external" :class="{ 'button-ghost primary': i === 0, 'button-primary': i > 0 }" :href="link.url" v-html="link.title"></a>
                <button type="button" @click="signIn" v-if="!link.external" :class="{ 'button-ghost primary': i === 0, 'button-primary': i > 0 }" :to="link.url" v-html="link.title"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '@/components/layout/BackgroundImage';

export default {
  name: 'MydtmBenefits',
  components: { BackgroundImage },
  props: {
    id: String,
    data: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      loaded: false,
      visible: false,
      singleText: []
    };
  },
  computed: {
    lineContent: function () {
      return this.$store.getters['page/raceSeries'] === 'dtm-trophy';
    },
    isAuthenticated () {
      return this.$msal.isAuthenticated;
    },
    username () {
      return this.$msal.username;
    }
  },
  methods: {
    async signIn () {
      await this.$msal.signIn();
    }
  },
  watch: {
    '$msal.isAuthenticated': function () {
      if (this.$msal.isAuthenticated) {
        this.$router.push(`/${this.$i18n.locale}/mydtm/home`);
      }
    }
  },
  mounted () {
    if (this.data && this.data.singleText) {
      const singleText = [];
      this.data.singleText.forEach((line) => {
        let icon = '';
        let text = line;
        if (text.substr(0, 4) === 'icon') {
          icon = line.substr(0, line.indexOf(';'));
          text = line.substr(line.indexOf(';') + 1);
        }
        singleText.push({ icon: icon, text: text });
      });
      this.singleText = singleText;
    }
    if (this.$msal.isAuthenticated) {
      this.$router.push(`/${this.$i18n.locale}/mydtm/home`);
    }
  },
  created () {
    // none
  }
};
</script>

<style lang="scss">
.mydtm-benefits {
  position: relative;
  background: $color-bg-dark;
  color: #fff;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  min-height: 60vh;
  &__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  &__overlay {
    position: absolute;
    right: -50vw;
    top: 0;
    bottom: 0;
    left: 50vw;
    background: rgba($color-bg, 0.7);
    transform: skewX(-13deg);
    backdrop-filter: blur(20px);
  }
  .container-custom {
    margin: 0 auto;
    padding-left: $gap * 2;
    padding-right: $gap * 2;
    width: 1600px;
    max-width: 100vw;
  }
  h4 {
    text-transform: initial;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      margin: 0 0 $gap * 2 $gap * 2;
      padding-left: $gap;
      .icon {
        position: absolute;
        top: 50%;
        left: -30px;
        width: 24px;
        height: 24px;
        transform: translate(0, -50%);
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 1159px) {
  .mydtm-benefits {
    &__overlay {
      left: 30vw;
    }
  }
}
@media (max-width: 768px) {
  .mydtm-benefits {
    &__overlay {
      left: 0;
      right: 0;
      transform: skewX(0);
    }
  }
}
</style>

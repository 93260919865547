<template>
    <div>
      <div class="container-fluid position-relative countdownPosition">
        <div class="countdown box-shadow bg-default border-primary--bottom text-white text-align--center padding-3 padding--left-0 padding--right-0 fade-in--bottom fade-in" v-if="!dateProcessor.countdownEnded" :class="{ 'view': dateProcessor.view }">
          <div class="row justify-content-center">
            <div class="col-auto padding--left-2 padding--right-2">
              <div class="h1">{{ (dateProcessor.dd > 99 ? dateProcessor.dd : ('00' + dateProcessor.dd).slice(-2)) }}</div>
              <div class="font-small text-primary text-uppercase">{{ $t('raceCalendar.days') }}</div>
            </div>
            <div class="col-auto padding--left-2 padding--right-2">
              <div class="h1">{{ ('00' + dateProcessor.hh).slice(-2) }}</div>
              <div class="font-small text-primary text-uppercase">{{ $t('raceCalendar.hours') }}</div>
            </div>
            <div class="col-auto padding--left-2 padding--right-2">
              <div class="h1">{{ ('00' + dateProcessor.mm).slice(-2) }}</div>
              <div class="font-small text-primary text-uppercase">{{ $t('raceCalendar.minutes') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import DateProcessor from '../../services/DateProcessor';

export default {
  name: 'Countdown',
  props: {
    date: null
  },
  data () {
    return {
      targetDate: null,
      dateProcessor: null
    };
  },
  watch: {
    // we need to watch the date prop, because receiving the event/news having the date is async
    date: function (val) {
      if (val !== null) {
        this.targetDate = new Date(val);
        this.dateProcessor.updateCountdown();
      }
    }
  },
  created () {
    this.targetDate = this.date !== null ? new Date(this.date) : null;
    this.dateProcessor = new DateProcessor({ date: this.targetDate });
    this.interval = setInterval(() => this.dateProcessor.updateCountdown(), 6000);
    this.dateProcessor.updateCountdown();
  },
  destroyed () {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
  .countdownPosition {
    margin-top: -100px;
    z-index: +1;
  }
</style>

<template>
  <div class="newsletter" v-if="config">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h3 class="text-center" v-text="config.title || 'Newsletter'"/>
          <div :data-form-block-id="config.formBlockId"/>
          <div :id="config.formId"/>
          <div class="d365-mkt-config" style="display:none" :data-website-id="config.websiteId" :data-hostname="config.hostname"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Newsletter',
  props: {
    configDe: {
      type: Object
    },
    configEn: {
      type: Object
    }
  },
  data () {
    return {
      config: null
    };
  },
  mounted () {
    let scriptExists = false;
    let trackingScriptExists = false;
    this.config = this.$i18n.locale === 'de' ? this.configDe : this.configEn;
    document.head.querySelectorAll('script').forEach((script) => {
      if (script.src === this.config.scriptSrc) {
        scriptExists = true;
      }
      if (script.src === this.config.trackingScriptSrc) {
        trackingScriptExists = true;
      }
    });
    if (!scriptExists) {
      const newScript = document.createElement('script');
      newScript.src = this.config.scriptSrc;
      document.head.appendChild(newScript);
    }
    if (!trackingScriptExists) {
      const newTrackingScript = document.createElement('script');
      newTrackingScript.src = this.config.trackingScriptSrc;
      document.head.appendChild(newTrackingScript);
    }
  }
};
</script>

<style scoped>

</style>

import * as msal from '@azure/msal-browser';
import Vue from 'vue';
import Settings from './config/settings';
import Axios from 'axios';

let msalInstance;
export let msalPluginInstance;

export default class MsalPlugin {
  constructor () {
    this.loginType = 'redirect'; // redirect, popup
    this.accountId = '';
    this.tid = '';
    this.userId = '';
    this.username = '';
    this.policies = {};
    this.loginRequest = {
      scopes: ['openid', Settings.azure.credentials.clientId]
    };
    this.tokenRequest = {
      scopes: ['openid']
    };
    this.isAuthenticated = false;
  }

  install (vue, options) {
    this.initialize(options);
    msalPluginInstance = this;
    vue.prototype.$msal = Vue.observable(msalPluginInstance);
  }

  initialize (options) {
    const msalConfig = {
      auth: {
        clientId: Settings.azure.credentials.clientId,
        authority: `https://${Settings.azure.credentials.tenantName}.b2clogin.com/${Settings.azure.authorityDomain}/${Settings.azure.policies.signUpSignIn}`, // Settings.azure.authorities.signUpSignIn.authority,
        knownAuthorities: [
          `${Settings.azure.loginDomain}`,
          `${Settings.azure.authorityDomain}/${Settings.azure.credentials.tenantName}/v2.0/.well-known/openid-configuration?p=${Settings.azure.policies.signUpSignIn}`],
        redirectUri: document.location.origin
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                window.console.error(message);
                return;
              case msal.LogLevel.Info:
                window.console.info(message);
                return;
              case msal.LogLevel.Verbose:
                window.console.debug(message);
                return;
              case msal.LogLevel.Warning:
                window.console.warn(message);
            }
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Verbose
        }
      }
    };
    msalInstance = new msal.PublicClientApplication(msalConfig);

    msalInstance.handleRedirectPromise()
      .then(response => {
        if (response) {
          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
           * from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy policies may use "acr" instead of "tfp").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (response.idTokenClaims.acr.toUpperCase() === Settings.azure.policies.signUpSignIn.toUpperCase()) {
            this.handleRedirectResponse(response);
          }
        }
      })
      .catch(error => {
        if (error instanceof msal.AuthError) {
          if (error.errorMessage.indexOf('AADB2C90118') > -1) {
            this.forgotPassword();
          }
        }
      });
    this.selectAccount();
  }

  setAccount (account) {
    this.accountId = account.homeAccountId;
    this.userId = account.idTokenClaims.sub;
    this.username = account.username || account.name;
    this.tid = account.idTokenClaims.tid;
    this.account = account;
    this.isAuthenticated = this.accountId > '';
  }

  selectAccount () {
    const currentAccounts = msalInstance.getAllAccounts();
    if (currentAccounts.length < 1) {
      //
    } else if (currentAccounts.length > 1) {
      const accounts = currentAccounts.filter(account =>
        account.homeAccountId.toUpperCase().includes(Settings.azure.policies.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss.toUpperCase().includes(Settings.azure.loginDomain.toUpperCase()) &&
        account.idTokenClaims.aud === Settings.azure.credentials.clientId);
      if (accounts.length > 1) {
        // localAccountId identifies the entity for which the token asserts information.
        if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
          // All accounts belong to the same user
          this.setAccount(accounts[0]);
        } else {
          // Multiple users detected. Logout all to be safe.
          this.signOut();
        }
      } else if (accounts.length === 1) {
        this.setAccount(accounts[0]);
      }
    } else {
      this.setAccount(currentAccounts[0]);
    }
  }

  editProfile () {
    const editProfileRequest = {
      authority: `https://${Settings.azure.credentials.tenantName}.b2clogin.com/${Settings.azure.authorityDomain}/${Settings.azure.policies.editProfile}`,
      loginHint: this.username
    };
    msalInstance.loginRedirect(editProfileRequest)
      .catch(error => {
        window.console.log(error);
      });
  }

  handleResponse (response) {
    /**
     * To see the full list of response object properties, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
     */
    if (response !== null) {
      this.setAccount(response.account);
    } else {
      this.selectAccount();
    }
  }

  handleRedirectResponse (response) {
    if (response !== null) {
      this.setAccount(response.account);
    } else {
      this.selectAccount();
    }
  }

  signIn () {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    if (this.loginType === 'popup') {
      const loginRequest = {
        scopes: ['openid', Settings.azure.credentials.clientId]
      };
      msalInstance.loginPopup(loginRequest)
        .then(this.handleResponse)
        .catch(error => {
          window.console.log('signIn error');
          window.console.log(error);
        });
    }
    if (this.loginType === 'redirect') {
      const loginRequest = {
        scopes: ['openid', Settings.azure.credentials.clientId]
      };
      msalInstance.loginRedirect(loginRequest)
        .catch(error => {
          window.console.log('signIn error', error);
        });
    }
  }

  forgotPassword () {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    if (this.loginType === 'popup') {
      const loginRequest = {
        scopes: ['openid', Settings.azure.credentials.clientId]
      };
      msalInstance.loginPopup(loginRequest)
        .then(this.handleResponse)
        .catch(error => {
          window.console.log('signIn error');
          window.console.log(error);
        });
    }
    if (this.loginType === 'redirect') {
      const loginRequest = {
        scopes: ['openid', Settings.azure.credentials.clientId]
      };
      msalInstance.loginRedirect(loginRequest)
        .catch(error => {
          window.console.log('signIn error', error);
        });
    }
  }

  signOut () {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    if (this.loginType === 'popup') {
      const logoutRequest = {
        scopes: ['openid', Settings.azure.credentials.clientId]
      };
      msalInstance.logoutPopup(logoutRequest);
    }
    if (this.loginType === 'redirect') {
      const logoutRequest = {
        scopes: ['openid', Settings.azure.credentials.clientId]
      };
      msalInstance.logoutRedirect(logoutRequest);
    }
  }

  getTokenRedirect (request) {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    request.account = msalInstance.getAccountByHomeId(this.accountId);
    /**
     *
     */
    return msalInstance.acquireTokenSilent(request)
      .then((response) => {
        // In case the response from B2C server has an empty accessToken field
        // throw an error to initiate token acquisition
        if (!response.accessToken || response.accessToken === '') {
          throw new msal.InteractionRequiredAuthError();
        } else {
          this.accessToken = response.accessToken;
          this.passTokenToApi();
        }
      }).catch(error => {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect(request);
        } else {
          return false;
        }
      });
  }

  getTokenPopup (request) {
    /**
     * See here for more information on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    request.account = msalInstance.getAccountByHomeId(this.accountId);
    /**
     *
     */
    return msalInstance.acquireTokenSilent(request)
      .then((response) => {
        // In case the response from B2C server has an empty accessToken field
        // throw an error to initiate token acquisition
        if (!response.accessToken || response.accessToken === '') {
          throw new msal.InteractionRequiredAuthError();
        }
        return response;
      })
      .catch(error => {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenPopup(request)
            .then(response => {
              return response;
            }).catch(error => {
              window.console.log(error);
            });
        } else {
          return null;
        }
      });
  }

  passTokenToApi () {
    this.getTokenPopup(this.tokenRequest)
      .then(response => {
        if (response) {
          try {
            Axios.get(`${Settings.nodeApi.uri}/token`, {
              headers: {
                Authorization: `Bearer ${response.accessToken}`,
                'Content-Type': 'application/json'
              }
            }).then(r => {
            });
          } catch (error) {
          }
        }
      });
  }
}

<template>
  <div class="driver-car-info padding--top-lg-4 padding--top-sm-2 padding--bottom-4">
    <div class="bgimg--hover position-relative">
      <div class="fade-in-child fade-in--right delay--100 box-shadow driver-car-info__background"
           :class="{ 'view': visible }"
           v-scroll:in="() => { visible = true; loaded = true }"
           v-scroll:out="() => visible = false">
        <background-image
          :scale="'16-9'"
          :overlay="true"
          :overlay-direction="'right'"
          :url="car.carInfoImage ? `https://media.graphassets.com/resize=w:2400,fit:crop/output=format:jpg/quality=value:70/compress/${car.carInfoImage.handle}` : null"
          :loaded="loaded"/>
      </div>
      <div class="d-none justify-content-center position-absolute driver-car-info__icon">
        <svg @click="isOverlayOpen === true ? isOverlayOpen = false : isOverlayOpen = true"
             class="driver-car-info__info-icon" width="78" height="78" viewBox="0 0 78 78" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="39" cy="39" r="37.875" fill="#D8D8D8" fill-opacity="0.2" stroke="#00304F" stroke-width="2.25"/>
          <circle cx="39" cy="39" r="30.375" fill="#00304F" stroke="#00304F" stroke-width="2.25"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M35.25 22.125V28.125H42V22.125H35.25ZM35.25 33.375V58.125H42V33.375H35.25Z" fill="#DEFE81"/>
        </svg>
      </div>
      <div class="driver-car-info__overlay text-default position-absolute" :class="{'driver-car-info__overlay--is-open fade-enter-active': isOverlayOpen}">
        <div class="driver-car-info__overlay__content p-3 pl-5 pr-5">
          <div class="d-flex align-items-center justify-content-between pt-1 pt-md-3 pb-4">
            <h4 class="text-italic m-0" v-text="car.name"/>
            <div class="driver-car-info__overlay-close" @click="isOverlayOpen = false">
              <svg class="p-1" width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.125 1L1 14.0667M1 1L14.125 14.0667" stroke="#00304F" stroke-width="3" stroke-linecap="round"/>
              </svg>
            </div>
          </div>
          <div class="p-5" v-if="car.description">
              <p v-text="car.description"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BackgroundImage from '@/components/layout/BackgroundImage';

export default {
  name: 'DriverCarInfo',
  components: { BackgroundImage },
  props: {
    car: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOverlayOpen: false,
      visible: false,
      loaded: false
    };
  }
};
</script>

<style scoped lang="scss">
.driver-car-info {
  &__icon {
    top: -40px;
    left: 0;
    width: 100%;
  }

  &__info-icon {
    z-index: 1;
    @media all and (max-width: map_get($grid-breakpoints, 'lg')) {
      width: 55px;
    }
  }

  &__overlay {
    top: 0;
    left: 50%;
    width: 33%;
    opacity: 0;
    transform: translate(20px, 0);
    transition: all .3s $ease-out-cubic;
    &__content {
      background: $color-primary;
    }
    @media all and (max-width: map_get($grid-breakpoints, 'lg')) {
      left: 0;
      width: 100%;
      transform: translateY(-100%);
    }

    &--is-open {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  &__overlay-close, &__info-icon {
    position: absolute;
    cursor: pointer;
  }
  &__overlay-close {
    top: 0;
    right: 0;
    padding: $gap;
  }
}

@media all and (max-width: map_get($grid-breakpoints, 'lg')) {
}
</style>

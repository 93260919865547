import Axios from 'axios';
import Settings from '@/config/settings';

export default {
  namespaced: true,
  state: {
    userPreferences: [],
    userPreferencesVoting: []
  },
  getters: {
    accessToken: state => {
      return state.accessToken;
    },
    userPreferences: state => {
      return state.userPreferences;
    },
    userPreferencesVoting: state => {
      return state.userPreferencesVoting;
    },
    preferenceSwiper: () => {
      return {
        question1: {
          initialSlide: 2,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: false,
          followFinger: false,
          longSwipes: false,
          slideToClickedSlide: true
        },
        question2: {
          initialSlide: 2,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: false,
          followFinger: false,
          longSwipes: false,
          slideToClickedSlide: true
        },
        question3: {
          initialSlide: 2,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: false,
          followFinger: false,
          longSwipes: false,
          slideToClickedSlide: true
        },
        question4: {
          initialSlide: 2,
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: false,
          followFinger: false,
          longSwipes: false,
          slideToClickedSlide: true
        }
      };
    }
  },
  mutations: {
    SET_USER_PREFERENCES: (state, preferences) => {
      state.userPreferences = preferences;
    },
    SET_USER_PREFERENCES_VOTING: (state, preferences) => {
      state.userPreferencesVoting = preferences;
    }
  },
  actions: {
    async saveUserPreferences ({ commit, state }, payload) {
      commit('SET_USER_PREFERENCES', payload);
    },
    async saveUserPreferencesVoting ({ commit, state }, payload) {
      commit('SET_USER_PREFERENCES_VOTING', payload);
    },
    async fetchUserPreferences ({ commit, state }, payload) {
      const settings = Object.assign({
        userId: '',
        key: 'MYDTM_PREFERENCE'
      }, payload);
      if (!state.userPreferences.length) {
        await Axios.get(
          `${Settings.nodeApi.uri}getPreference?userId=${settings.userId}&userPreference=${settings.key}`, {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
          if (res.data && res.data.data && res.data.data.length) {
            const savedPreferences = {};
            res.data.data.forEach((d) => {
              savedPreferences[`${d.p}.${d.q}`] = {
                id: d.i,
                userPreference: d.p,
                userPreferenceQuestion: d.q,
                tagId: d.t,
                answer: d.a
              };
            });
            this.dispatch('adb2c/saveUserPreferences', savedPreferences);
          }
        });
      }
    },
    async fetchUserPreferencesVoting ({ commit, state }, payload) {
      const settings = Object.assign({
        userId: '',
        key: ''
      }, payload);
      if (!state.userPreferences.length) {
        await Axios.get(
          `${Settings.nodeApi.uri}getPreference?userId=${settings.userId}&userPreference=${settings.key}`, {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
          if (res.data && res.data.data && res.data.data.length) {
            const savedPreferences = {};
            res.data.data.forEach((d) => {
              savedPreferences[`${d.p}.${d.q}`] = {
                id: d.i,
                userPreference: d.p,
                userPreferenceQuestion: d.q,
                tagId: d.t,
                answer: d.a
              };
            });
            this.dispatch('adb2c/saveUserPreferencesVoting', savedPreferences);
          }
        });
      }
    }
  }
};

<template>
  <div class="page-header" :class="classObject" v-scroll:in="() => headlineVisible = true">
    <background-image
      v-if="backgroundImage || willHaveBackgroundImage"
      :url="backgroundImage"
      :url-mobile="backgroundImageMobile"
      :full="true"
      :fixed="true"
      :loaded="true"
      :overlay="false"
      :overlay-color="''"
      :class-list="'page-header__image'"></background-image>
    <div v-if="video" class="position-absolute position-absolute--full video">
      <video v-if="video" muted autoplay loop playsinline="true" width="100%" height="100%" :src="video"></video>
    </div>
    <div v-if="youtubeStreamUrl" class="position-absolute position-absolute--full video">
      <youtube :streamUrl="youtubeStreamUrl"/>
    </div>
    <div class="page-header__content">
      <loader v-if="loading"></loader>
      <div v-if="!layout">
        <div class="container-fluid padding--top-3">
          <div v-if="subHeadline" class="h4 text-condensed margin--top-3 fade-in-child fade-in--bottom delay--300">
            {{ subHeadline }}
          </div>
          <h1 class="margin--bottom-1 fade-in-child fade-in--bottom delay--500">
            {{ headline }}
          </h1>
          <div v-if="subText" class="text-uppercase margin--bottom-1 fade-in-child fade-in--bottom delay--600">
            {{ subText }}
          </div>
          <div class="row">
            <div v-for="(button, index) in buttons" :key="'button' + index" class="col-auto fade-in-child fade-in--bottom delay--600">
              <a :href="button.url"
                 v-if="button.external"
                 class="button-ghost primary"
                 target="_blank">
                {{ button.title }}
              </a>
              <router-link
                v-if="!button.external"
                :to="$i18n.locale + '/' + button.url"
                class="button-ghost primary">
                {{ button.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="layout === 'home'" class="page-header__content-text">
        <div class="container-fluid padding--top-3 padding--bottom-3">
          <div v-if="subHeadline" class="h4 text-condensed margin--top-3 fade-in-child fade-in--bottom delay--200">
            {{ subHeadline }}
          </div>
          <div class="fade-in-child fade-in--bottom delay--300 margin--top-2" :class="{ 'margin--bottom-2': subText, 'margin--bottom-4': !subText }">
            <h1 class="bg-primary"><span>{{ headline }}</span></h1>
          </div>
          <div v-if="subText" class="text-uppercase font-weight--bold text-condensed text-primary margin--bottom-2 fade-in-child fade-in--bottom delay--400">
            {{ subText }}
          </div>
          <div class="row">
            <div v-for="(button, index) in buttons" :key="'button' + index" class="col-auto fade-in-child fade-in--bottom delay--500">
              <a :href="button.url" class="button-ghost primary" v-if="button.url.indexOf('http') === 0">{{ button.title }}</a>
              <router-link :to="$i18n.locale + '/' + button.url" class="button-ghost primary" v-if="button.url.indexOf('http') === -1">{{ button.title }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="layout === 'event'">
        <div class="container-fluid padding--top-3">
          <div class="row padding--top-6">
            <div class="col-12 col-md-4">
              <div v-if="flag" class="fade-in-child fade-in--bottom delay--100">
                <img v-if="flag" width="48" :src="flag" :title="subHeadline" :alt="subHeadline">
              </div>
              <h1 class="margin--bottom-1 margin--top-1 h4 text-normal fade-in-child fade-in--bottom delay--200">
                <span v-if="subHeadline" class="text-primary font-weight--bold">{{ subHeadline }}</span> {{ headline }}
              </h1>
              <div v-if="eventDays">
                <div class="h1 margin--bottom-1 fade-in-child fade-in--bottom delay--300">{{ eventDays }}</div>
                <div class="h4 font-weight--light fade-in-child fade-in--bottom delay--400">{{ eventMonths }}</div>
              </div>
            </div>
            <div v-if="eventTrack" class="col-12 col-md-5 text-align--center fade-in-child fade-in--bottom delay--500">
              <img :src="eventTrack" class="event-list__circuit" width="80%">
            </div>
          </div>
          <div class="row page-header__buttons--up">
            <div v-for="(button, index) in buttons" :key="'button' + index" class="col-auto fade-in-child fade-in--bottom delay--600">
              <a :href="button.url" class="button-ghost primary" v-if="button.url.indexOf('http') === 0">{{ button.title }}</a>
              <router-link :to="$i18n.locale + '/' + button.url" class="button-ghost primary" v-if="button.url.indexOf('http') === -1">{{ button.title }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <break-after
        v-if="breakAfter && !video && !youtubeStreamUrl"
        :to-white="breakAfterToWhite"
        :to-yellow="breakAfterToYellow"
        :to-default="breakAfterToDefault"
        :to-dark="breakAfterToDark"
        :padding="breakAfterPadding"
        :gradient="breakAfterGradient"
        :opacity="0.4"></break-after>
    </div>
  </div>
</template>

<script>
import BreakAfter from './BreakAfter';
import BackgroundImage from './BackgroundImage';
import Loader from './Loader';
import Youtube from '../../components/Youtube';

export default {
  name: 'PageHeader',
  components: {
    Youtube,
    Loader,
    BackgroundImage,
    BreakAfter
  },
  props: {
    layout: { type: String, default: '' },
    breakAfter: { type: Boolean, default: true },
    breakAfterToWhite: { type: Boolean, default: false },
    breakAfterToDefault: { type: Boolean, default: false },
    breakAfterToDark: { type: Boolean, default: false },
    breakAfterToYellow: { type: Boolean, default: false },
    breakAfterPadding: { type: Boolean, default: false },
    breakAfterGradient: { type: String, default: 'gradientTopBottom' },
    headline: { type: String, default: '' },
    subHeadline: { type: String, default: '' },
    subText: { type: String, default: '' },
    classList: { type: String, default: '' },
    backgroundImage: { type: String, default: '' },
    backgroundImageMobile: { type: String, default: '' },
    video: { type: String, default: '' },
    willHaveBackgroundImage: { type: Boolean, default: false },
    countryCode: { type: String, default: '' },
    flag: { type: String, default: '' },
    eventDays: { type: String, default: '' },
    eventMonths: { type: String, default: '' },
    eventTrack: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    buttons: { type: Array },
    youtubeStreamUrl: { type: String, required: false, default: null }
  },
  data () {
    return {
      headlineVisible: false
    };
  },
  computed: {
    classObject: function () {
      const classes = {};
      // overlay
      classes.view = this.headlineVisible;
      // own classList
      if (this.classList) {
        classes[this.classList] = true;
      }
      if (this.layout) {
        classes['page-header--layout-' + this.layout] = true;
      }
      return classes;
    }
  }
};
</script>

<style lang="scss">
  .page-header {
    $root: &;
    overflow: hidden;
    position: relative;
    z-index: +1;
    background: $color-bg;

    .button-ghost {
      background: rgba($color-bg-dark, 0.4);
      backdrop-filter: blur(5px) brightness(0.3);
    }

    &__content {
      position: relative;
      z-index: +1;
    }

    &--layout-event {
      #{$root}__content {
        min-height: 80vh;
        min-height: calc(90vh - 110px);
      }
    }

    &--layout-driver {
      #{$root}__content {
        min-height: 60vh;
        min-height: calc(70vh - 110px);
      }
    }

    &--full {
      #{$root}__content {
        min-height: 80vh;
        min-height: calc(100vh - 110px);
      }
    }

    &--16by9 {
      height: 100vw / 16 * 9;
    }

    &--home {
      #{$root}__content {
        min-height: 80vh;
        min-height: calc(100vh - 110px);
      }
      #{$root}__content-text {
        position: absolute;
        bottom: 20%;
        left: 0;
        right: 0;
      }
    }

    .video {
      video {
        object-fit: cover;
      }
    }
  }

  @media (max-width: map_get($grid-breakpoints, 'sm')) {
    .page-header {
      $root: &;

      .event-list__circuit {
        transform: translate(0, -20%);
        opacity: 0.3;
      }

      &--home {
        #{$root}__content-text {
          position: absolute;
          bottom: $gap * 2;
          left: 0;
          right: 0;
        }
      }

      &__buttons--up {
        transform: translate(0, -200%);
      }
    }
  }
</style>

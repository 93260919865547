<template>
  <div class="position-relative">
    <div class="event-banner bg-medium text-white margin--top-0 overflow-hidden"
        v-scroll:in="() => { visible = true; wasVisible = true; }"
        v-scroll:out="() => visible = false">
      <div class="container-fluid padding-1 event-banner__padding-mobile fade-in fade-in--bottom event-banner__content" v-if="dateProcessor && !dateProcessor.countdownEnded && showCountdown" :class="{ 'view': visible }">
       <div class="row align-items-center" v-if="event && event.racetrack">
         <div class="col-12 col-md-2">
           <div class="row align-items-center justify-content-center text-align--center">
             <div class="col-auto col-md-12 pb-0 pb-md-1" v-html="nextEventTitle || $t('common.nextEvent')"></div>
             <div class="col-auto col-md-12">
               <img v-if="event.racetrack.country"
                    width="48"
                    :src="event.racetrack.country.appCountryFlag.url"
                    :title="event.racetrack.country.name"
                    :alt="event.racetrack.country.name"/>
             </div>
           </div>
         </div>
         <div class="col-2 d-none d-md-flex justify-content-center">
           <img v-if="event.racetrack.circuit"
                width="80%"
                :alt="event.racetrack.name"
                :title="event.racetrack.name"
                :src="event.racetrack.circuit.url"/>
         </div>
         <div class="col-12 col-md-6 d-flex justify-content-center pt-4 pt-md-0">
           <div class="row justify-content-center">
             <div class="col-auto padding--right-2">
               <div class="font-small text-primary text-uppercase text-align--center">{{ $t('raceCalendar.days') }}</div>
               <div class="h1 text-primary text-italic">
                 {{ (dateProcessor.dd > 99 ? dateProcessor.dd : ('00' + dateProcessor.dd).slice(-2)) }}
               </div>
             </div>
             <div class="col-auto padding--right-2">
               <div class="font-small text-primary text-uppercase text-align--center">{{ $t('raceCalendar.hours') }}</div>
               <div class="h1 text-primary text-italic">
                 {{ ('00' + dateProcessor.hh).slice(-2) }}
               </div>
             </div>
             <div class="col-auto padding--right-2">
               <div class="font-small text-primary text-uppercase text-align--center">{{ $t('raceCalendar.minutes') }}</div>
               <div class="h1 text-primary text-italic">
                 {{ ('00' + dateProcessor.mm).slice(-2) }}
               </div>
             </div>
             <div class="col-auto padding--right-2">
               <div class="font-small text-primary text-uppercase text-align--center">{{ $t('raceCalendar.seconds') }}</div>
               <div class="h1 text-primary text-italic">
                 {{ ('00' + dateProcessor.ss).slice(-2) }}
               </div>
             </div>
           </div>
         </div>
         <div class="col-12 col-md-2 d-flex justify-content-center pt-4 pt-md-0">
           <router-link class="button-ghost primary" :to="{ name: $t('navigation.eventsDetail'), params: { lang: $i18n.locale, slug: event.slug} }">
             {{ $t('common.eventReadMore') }}
           </router-link>
         </div>
       </div>
      </div>
    </div>
    <div v-if="showLivetiming">
      <div class="margin--bottom-4 margin--top-4 container-fluid" v-if="youtubeStream">
        <div class="position-relative overflow-hidden scale_16-9">
          <div class="position-absolute bgimg__image bgimg__image--loaded">
            <iframe :src="`${youtubeStream}`" width="100%" height="100%"
                    frameborder="0"></iframe>
          </div>
        </div>
      </div>
      <wige-livetiming :event="'dtm'" />
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';
import DateProcessor from '../../services/DateProcessor';
import WigeLivetiming from '@/components/wige/WigeLivetiming';

export default {
  name: 'EventBanner',
  components: {
    WigeLivetiming
  },
  props: {
    status: {
      type: String,
      default: 'before'
    },
    youtubeStream: {
      type: String,
      default: ''
    },
    timeThreshold: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      loading: true,
      visible: false,
      event: null,
      dateProcessor: null,
      showCountdown: false,
      showLivetiming: false,
      nextEventTitle: '',
      useWigeLivetiming: false
    };
  },
  computed: {
    queryName: function () {
      return this.$store.getters['page/raceSeriesEventBanner'];
    }
  },
  methods: {
    async fetchEventCountdown () {
      const dateIsoString = new Date().toISOString();
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=${this.queryName}&now=${dateIsoString}`,
        lifetime: Settings.LIFETIME_SHORT
      })
        .then(response => {
          if (response && response.events && response.events.length) {
            this.event = response.events[0];
            this.event.startTime = new Date();
            this.dateProcessor = new DateProcessor({ date: this.event.startTime });
            this.interval = setInterval(() => {
              this.dateProcessor.updateCountdown();
              this.checkLivestream();
            }, 1000);
            this.dateProcessor.updateCountdown();
          }
        });
    },
    checkLivestream () {
      if (this.event.timetable && this.event.timetable.length) {
        const now = new Date();
        let showLivetiming = false;
        this.event.timetable.forEach((entry) => {
          if (entry.raceSeries) {
            const start = new Date(entry.start);
            const end = new Date(entry.end);
            if (this.nextEventTitle === '') {
              if (start > now || (start < now && end > now)) {
                this.nextEventTitle = `${entry.headline}${entry.label ? `<br/>${entry.label}` : ''}`;
                this.dateProcessor.setDate(entry.start);
              }
            }
            // 10 Minute Buffer
            start.setMinutes(start.getMinutes() - this.timeThreshold);
            end.setMinutes(end.getMinutes() + this.timeThreshold);
            if (start < now && end > now) {
              showLivetiming = true;
            }
            // console.log(start, end, now, showLivetiming);
          }
        });
        this.showLivetiming = showLivetiming;
      }
    }
  },
  watch: {
    $route () {
      this.showCountdown = true;
      this.fetchEventCountdown();
    }
  },
  created () {
    this.showCountdown = true;
    this.fetchEventCountdown();
  },
  destroyed () {
    clearInterval(this.interval);
  }
};
</script>

<style scoped lang="scss">
  .event-banner {
    transform: skewY(-2deg);
    transform-origin: 0 100%;
    padding: 20px 0 60px;
    margin-top: -2px;
    &__content {
      transform: skewY(2deg);
      transform-origin: 0 100%;
    }
  }
  @media all and (max-width: map_get($grid-breakpoints, 'md')) {
    .event-banner {

      &__padding-mobile {
        padding: 40px 15px;
      }
    }
  }
</style>

<template>
  <div class="channel-list margin--top-2 margin--bottom-8">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="text-align--center h4 font-weight--light text-italic margin--bottom-1">
            {{ $t('raceCalendar.program') }}
          </div>
          <div class="text-align--center h2 margin--bottom-2">
            {{ $t('raceCalendar.watchLive') }}
          </div>
        </div>
      </div>
      <div class="row bg-default padding-2 align-items-center">
        <div class="col-12 col-md-6 d-flex justify-content-md-start">
          <div class="h4 font-weight--light text-italic channel-list__country-label text-uppercase text-white">
            {{ $t('raceCalendar.watchFrom') }}
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-md-end">
          <div class="channel-list__country-select">
            <label>
              <select v-model="selected" name="countries" class="text-uppercase text-white"
                      @change="fetchChannelsByCountry()">
                <option v-for="(country, index) in countries" :key="index" :value="country.countryCode" v-text="country.name"/>
              </select>
              <svg class="channel-list__icon" fill="currentColor" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd"><g id="Icon-24px" transform="translate(8.000000, 5.500000)"><polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon></g></g></svg>
            </label>
          </div>
        </div>
      </div>
      <div class="row bg-gray d-flex align-items-center margin--top-1 padding--top-1 padding--bottom-1"
           v-for="(channel, index) in channels" :key="index">
        <div class="col-auto col-md-2 padding--left-2 padding--right-2">
          <img class="channel-list__img" :src="channel.image.url" :alt="channel.name" v-if="channel.image" />
        </div>
        <div class="col">
          <div class="channel-list__name text-uppercase font-weight--bold" v-text="channel.name"/>
          <div class="channel-list__description text-uppercase" v-text="channel.description"/>
        </div>
        <div class="col-12 col-md-auto padding--top-1 padding--bottom-1 " v-if="channel.link">
          <a :href="channel.link.url" :target="channel.link.newTab ? '_blank' : '_parent'" :alt="channel.link.title" class="button-primary button-primary--to-dark">{{ channel.link.title }}</a>
        </div>
      </div>
      <div class="row bg-gray d-flex align-items-center margin--top-1" v-if="channels.length <= 0">
        <div class="col-12 padding--left-2 padding--right-2 padding--top-1 padding--bottom-1 text-uppercase">
          {{ $t('raceCalendar.noBroadcastAvailable') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';

export default {
  name: 'ChannelList',
  props: {
    countryCode: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      countries: [],
      channels: [],
      selected: this.countryCode
    };
  },
  methods: {
    async fetchCountries () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=countries',
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          response.countries.sort((a, b) => a.name > b.name ? 1 : -1);
          this.countries = response.countries;
        });
    },
    async fetchChannelsByCountry () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=channelsByCountry&countryCode=${this.selected}`,
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          this.channels = response.channels;
        });
    }
  },
  created () {
    this.fetchChannelsByCountry();
    this.fetchCountries();
  }
};
</script>

<style lang="scss" scoped>
  .channel-list {
    padding-bottom: 45px;
    &__img {
      width: 80px;
      height: 80px;
    }
    &__country-select {
      position: relative;
      width: 100%;
      select {
        cursor: pointer;
        position: relative;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid #fff;
        padding: 10px;
        background: transparent;
        z-index: 1;
        width: 100%;
        &::-ms-expand {
          display: none;
        }
        &:focus {
          outline: none;
        }
      }
      option {
        color: $color-bg;
      }
    }
    &__icon {
      transform: rotate(270deg);
      position: absolute;
      top: 8px;
      right: 6px;
      color: #fff;
      z-index: 0;
    }
    &__country-label {
      margin-bottom: 15px;
      @media all and (min-width: map_get($grid-breakpoints, 'sm')) {
        margin-bottom: 0;
      }
    }
    &__name {
      margin-bottom: 10px
    }
  }
</style>

<template>
  <div class="news-highlight text-white" ref="homeHeader"
       :class="{ 'view': this.visible }"
       v-scroll:in="() => { this.visible = true; }">
    <div class="hero">
      <img
        v-if="backgroundImage && !video"
        :src="backgroundImage.url"
        width="100%"
        height="100%"
        alt="">
      <video
        v-if="video"
        ref="highlightVideo"
        :src="video"
        :width="videoInfo.svgWidth"
        :height="videoInfo.svgHeight"
        muted autoplay loop playsinline="true"></video>
    </div>
    <div class="news-highlight__title">
      <div class="container-fluid">
        <div v-if="subHeadline" class="h4 text-condensed margin--top-3 fade-in-child fade-in--bottom delay--200">
          {{ subHeadline }}
        </div>
        <div class="fade-in-child fade-in--bottom delay--300 margin--top-2" :class="{ 'margin--bottom-2': subText, 'margin--bottom-4': !subText }">
          <h1 class="bg-primary"><span>{{ headline }}</span></h1>
        </div>
        <div v-if="subText" class="text-uppercase font-weight--bold text-condensed text-primary margin--bottom-2 fade-in-child fade-in--bottom delay--400">
          {{ subText }}
        </div>
        <div class="row">
          <div v-for="(button, index) in buttons" :key="'button' + index" class="col-auto fade-in-child fade-in--bottom delay--500">
            <a
              v-if="button.external"
              target="_blank"
              :href="button.url"
              class="button-ghost primary mb-1">
                {{ button.title }}
            </a>
            <router-link
              v-if="!button.external"
              :to="$i18n.locale + '/' + button.url"
              class="button-ghost primary mb-1">
                {{ button.title }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeHeader',
  props: {
    headline: { type: String, default: '' },
    subHeadline: { type: String, default: '' },
    subText: { type: String, default: '' },
    backgroundImage: { type: Object },
    video: { type: String, default: '' },
    buttons: { type: Array },
    dtmTrophy: { type: Boolean, default: false }
  },
  data () {
    return {
      headlineVisible: false,
      visible: false,
      image: {
        ratio: 0,
        x: 0,
        y: 0,
        yTarget: 0,
        svgWidth: 0,
        svgHeight: 0
      },
      videoInfo: {
        el: null,
        canplay: false,
        width: 0,
        height: 0,
        ratio: 0,
        x: 0,
        y: 0,
        yTarget: 0,
        svgWidth: 0,
        svgHeight: 0
      },
      width: 0,
      height: 0,
      ratio: 0,
      scrollTop: 0
    };
  },
  methods: {
    // resize window
    resize (values) {
      this.width = values[0];
      this.height = this.$refs.homeHeader.clientHeight;
      this.ratio = this.width / this.height;
      let width = this.width;
      if (this.backgroundImage) {
        this.image.ratio = this.backgroundImage.width / this.backgroundImage.height;
        let height = width / this.image.ratio;
        if (height < this.height) {
          height = this.height;
          width = height * this.image.ratio;
        }
        this.image.svgWidth = width;
        this.image.svgHeight = height;
        this.image.x = (this.width - width) / 2;
        this.image.yTarget = Math.min(this.height, (this.height - height) / 2 + this.scrollTop / 5);
      }
      this.checkVideo();
    },
    checkVideo () {
      if (this.$refs.highlightVideo) {
        if (!this.videoInfo.canplay) {
          this.videoInfo.canplay = true;
          this.$refs.highlightVideo.oncanplay = () => {
            this.checkVideo();
          };
        }
        this.videoInfo.width = this.$refs.highlightVideo.videoWidth;
        this.videoInfo.height = this.$refs.highlightVideo.videoHeight;
        if (this.videoInfo.width) {
          this.videoInfo.ratio = this.videoInfo.width / this.videoInfo.height;
          let width = this.width;
          let height = width / this.videoInfo.ratio;
          if (height < this.height) {
            height = this.height;
            width = height * this.videoInfo.ratio;
          }
          this.videoInfo.svgWidth = width;
          this.videoInfo.svgHeight = height;
          this.videoInfo.x = (this.width - width) / 2;
          this.videoInfo.yTarget = (this.height - height) / 2;
        }
      }
    },
    // animationFrame
    animate () {
      // check current scrollTop and compare against stored scrollTop
      // active again if we won't use smooth-scrollbar
      if (this.backgroundImage) {
        const yTarget = Math.min(this.height, (this.height - this.image.svgHeight) / 2 + this.scrollTop / 5);
        if (yTarget !== this.image.yTarget) {
          this.image.yTarget = yTarget === 0 ? -0.01 : yTarget;
          const y = Math.round((this.width > 768 ? this.lerp(this.image.y, this.image.yTarget, 0.5) : this.image.yTarget) / 10) * 10;
          if (y !== this.image.y && this.$refs.image) {
            this.image.y = 0; // y;
            this.$refs.image.setAttribute('y', this.image.y);
          }
        }
      }
      if (this.$refs.highlightVideo) {
        if (!this.videoInfo.ratio) {
          this.checkVideo();
        }
        const yTarget = Math.min(this.height, (this.height - this.videoInfo.svgHeight) / 2 + this.scrollTop / 5);
        this.videoInfo.yTarget = yTarget === 0 ? -0.01 : yTarget;
        const y = Math.round((this.width > 768 ? this.lerp(this.videoInfo.y, this.videoInfo.yTarget, 0.5) : this.videoInfo.yTarget) / 10) * 10;
        if (y !== this.videoInfo.y) {
          this.videoInfo.y = y;
        }
      }
      // window.requestAnimationFrame(this.animate);
    },
    // lerp function
    lerp (a, b, n) {
      return (1 - n) * a + n * b;
    }
  },
  mounted () {
    this.resize(this.$store.getters['scroll/resize']);
  },
  created () {
    // watch window resize
    this.unwatchResize = this.$store.watch(() => this.$store.getters['scroll/resize'], resize => {
      // set headerScrolled true/false if threshold is reached
      this.resize(resize);
    });
    // watch scrollTop
    this.unwatchScrollTop = this.$store.watch(() => this.$store.getters['scroll/scrollTop'], scrollTop => {
      // set headerScrolled true/false if threshold is reached
      this.scrollTop = scrollTop;
    });
    window.requestAnimationFrame(this.animate);
  },
  destroyed () {
    this.unwatchResize();
    this.unwatchScrollTop();
    window.cancelAnimationFrame(this.animate);
  }
};
</script>

<style lang="scss">
  .news-highlight {
    position: relative;
    height: 100vw * 9 / 16;
    max-height: 90vh;
    overflow: hidden;
    &__svg {
      position: relative;
      z-index: 0;
    }
    &__title {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20%;
      z-index: 10;
      h1 {
        max-width: 60%;
      }
    }
    .button-ghost {
      background: rgba($color-bg-dark, 0.4);
      backdrop-filter: blur(5px) brightness(0.6);
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'lg')) {
    .news-highlight {
      height: 75vw;
      &__title {
        h1 {
          max-width: 80%;
        }
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'sm')) {
    .news-highlight {
      height: 90vh;
      &__title {
        h1 {
          max-width: 96%;
        }
      }
    }
  }
</style>

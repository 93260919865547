<template>
  <div id="page">
    <meta-data v-if="page && page.metaTitle"
      :key="page.metaTitle"
      :meta-title="page.metaTitle"
      :meta-description="page.metaDescription"
      :no-index="page.noIndex"/>
    <page-header
      class="bg-default text-white"
      v-if="!error && !infoPage && page && !page.stageHidden"
      :headline="page ? page.stageHeadline : ''"
      :class-list="page ? page.stageMedia && page.stageMedia.url && !page.stageShort ? 'page-header--full' : '' : ''"
      :sub-text="page ? page.stageSubheadline : ''"
      :break-after-to-white="!homePage"
      :video="page && page.stageVideo ? page.stageVideo.url : ''"
      :background-image-mobile="page ? page.stageMediaMobile ? `https://media.graphassets.com/resize=w:780,fit:crop/output=format:jpg/quality=value:70/compress/${page.stageMediaMobile.handle}` : '' : ''"
      :background-image="page ? page.stageMedia ? `https://media.graphassets.com/resize=w:2800,fit:crop/output=format:jpg/quality=value:70/compress/${page.stageMedia.handle}` : '' : ''"/>
    <div class="bg-white padding--top-4 padding--bottom-4" v-if="loading">
      <loader :inverted="true"></loader>
    </div>
    <div :class="{ 'bg-white': !homePage, 'padding--top-4 padding--bottom-4': page && !page.stageHidden }" v-if="page && !error">
      <div class="container-fluid">
        <template v-for="(moduleEntry, moduleIndex) in page.modules">
          <race-series-news v-if="moduleEntry.module === 'raceSeriesNews'" :key="`raceSeriesNews-${moduleIndex}`" :race-series="moduleEntry.raceSeries" :limit="moduleEntry.limit"/>
          <plain-text v-if="moduleEntry.module === 'text'" :key="`text-${moduleEntry.id}`" :id="moduleEntry.id" :class="'text-default'"/>
          <youtube v-if="moduleEntry.module === 'video'" :key="`youtube-${moduleEntry.id}`" :id="moduleEntry.id" :class="'view padding--top-4 padding--bottom-4'"/>
          <image-text v-if="moduleEntry.module === 'imageText'" :key="`image-text-${moduleEntry.id}`" :id="moduleEntry.id" :class="'text-default'"/>
          <image-gallery v-if="moduleEntry.module === 'gallery'" :key="`gallery-${moduleEntry.id}`" :id="moduleEntry.id" :slidesPerView="moduleEntry.slidesPerView || 1"/>
          <info-boxes v-if="moduleEntry.module === 'infoBoxes'" :key="`info-boxes-${moduleEntry.id}`" :ids="moduleEntry.ids"/>
          <map-image v-if="moduleEntry.module === 'map'" :key="`map-${moduleEntry.id}`" :id="moduleEntry.id" :has-container="true"/>
          <cta v-if="moduleEntry.module === 'CTA'" :key="`cta-${moduleEntry.id}`" :id="moduleEntry.id"/>
          <results v-if="moduleEntry.module === 'results'" :key="`results-${moduleIndex}`" :settings="moduleEntry"></results>
          <standings v-if="moduleEntry.module === 'standings'" :key="`standings-${moduleIndex}`" :settings="moduleEntry"></standings>
          <channel-list v-if="moduleEntry.module === 'channelList'" :key="`standings-${moduleIndex}`" :country-code="moduleEntry.countryCode || 'DE'" class="text-default"/>
          <event-benefit v-if="moduleEntry.module === 'eventBenefit'" :key="`event-benefit-${moduleIndex}`" :id="moduleEntry.id"/>
          <partner v-if="moduleEntry.module === 'partner'" :key="`partner-${moduleIndex}`" :name="moduleEntry.name" :title="moduleEntry.title" :is-supplier="moduleEntry.isSupplier"/>
          <wige-livetiming v-if="moduleEntry.module === 'live'" :key="`live-${moduleIndex}`" :event="'event' in moduleEntry ? moduleEntry.event.toString() : 'dtm'" />
          <generic v-if="moduleEntry.module === 'generic'" :key="`generic-${moduleEntry.id}`" :id="moduleEntry.id"></generic>
          <newsletter v-if="moduleEntry.module === 'newsletter'" :key="`newsletter-${moduleIndex}`" :config-de="moduleEntry.de" :config-en="moduleEntry.en" :class="'text-default'"/>
          <news-overview v-if="moduleEntry.module === 'news'" :key="`news-${moduleIndex}`"/>
          <team-list v-if="moduleEntry.module === 'teams'" :key="`teams-${moduleIndex}`" :race-series="moduleEntry.raceSeries || 'DTM'" />
        </template>
        <div v-if="page.content && page.content.html" v-html="page.content.html" class="container-fluid text-default page--infoPage"></div>
      </div>
    </div>
    <div v-if="error">
      <page-not-found></page-not-found>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import PlainText from '../components/PlainText';
import ImageText from '../components/ImageText';
import MetaData from '../components/MetaData';
import InfoBoxes from '../components/InfoBoxes';
import Cta from '../components/Cta';
import PageHeader from '../components/layout/PageHeader';
import PageNotFound from './PageNotFound';
import Loader from '../components/layout/Loader';
import ImageGallery from '../components/ImageGallery';
import MapImage from '../components/MapImage';
import Youtube from '../components/Youtube';
import Settings from '../config/settings';
import Results from '../components/Results';
import Standings from '../components/Standings';
import ChannelList from '../components/events/ChannelList';
import EventBenefit from '../components/events/EventBenefit';
import Partner from '../components/Partner';
import WigeLivetiming from '../components/wige/WigeLivetiming';
import RaceSeriesNews from '../components/news/RaceSeriesNews';
import Generic from '../components/Generic';
import Newsletter from '@/components/Newsletter';
import NewsOverview from '@/components/news/NewsOverview';
import TeamList from '@/components/driver/TeamList';

export default {
  name: 'Page',
  components: {
    Generic,
    Newsletter,
    RaceSeriesNews,
    Partner,
    EventBenefit,
    ChannelList,
    Standings,
    Results,
    Youtube,
    MapImage,
    ImageGallery,
    Loader,
    PageNotFound,
    PageHeader,
    Cta,
    InfoBoxes,
    MetaData,
    PlainText,
    ImageText,
    WigeLivetiming,
    NewsOverview,
    TeamList
  },
  data () {
    return {
      page: {},
      loading: true,
      error: false,
      infoPage: false,
      homePage: false
    };
  },
  methods: {
    async fetchPage () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      const slug = this.$route.params.pathMatch;
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=page&slug=${slug}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          if (response.pages.length) {
            this.page = response.pages[0];
            this.homePage = this.page.pageType === 'Home';
            this.loading = false;
            this.$store.commit(AppConst.ADD_LOCALIZATIONS, this.page.localizations);
            this.$store.commit('page/SET_RACESERIES', (this.page.raceSeries.length ? this.page.raceSeries : ['DTM']));
            this.$store.dispatch('page/raceSeriesLoaded', false);
            // login required?
            if (this.page.gated && !this.$msal.isAuthenticated) {
              this.$router.push(`/${this.$i18n.locale}/mydtm`);
            }
          } else {
            this.fetchInfoPage();
          }
        }).catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    async fetchInfoPage () {
      await this.$store.dispatch('page/raceSeriesLoaded', true);
      const slug = this.$route.params.pathMatch;
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=infoPages&slug=${slug}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          if (response.infoPages.length) {
            this.infoPage = true;
            this.page = response.infoPages[0];
            this.$store.commit(AppConst.ADD_LOCALIZATIONS, this.infoPage.localizations);
            this.$store.commit('page/SET_RACESERIES', (this.page.raceSeries ? this.page.raceSeries : ['DTM']));
            this.$store.dispatch('page/raceSeriesLoaded', false);
          } else {
            this.error = true;
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.error = true;
        });
    }
  },
  watch: {
    '$route.params.pathMatch': function () {
      window.scrollTo(0, 0);
      this.error = false;
      this.infoPage = false;
      this.loading = true;
      this.page = {};
      this.fetchPage();
    },
    '$msal.isAuthenticated': function () {
      if (this.page && this.page.gated && !this.$msal.isAuthenticated) {
        this.$router.push(`/${this.$i18n.locale}/mydtm`);
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    this.fetchPage();
  }
};
</script>

<style lang="scss">
  .page--infoPage {
    h1, .h1 {
      font-size: 44px;
    }
    h2, .h2 {
      font-size: 25px;
      letter-spacing: 0.8px;
    }
    h3, .h3 {
      font-size: 21px;
      letter-spacing: 0.64px;
    }
    h4, .h4 {
      font-size: 18px;
      line-height: 1.1em;
    }
  }
</style>

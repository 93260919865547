<template>
  <div class="top-products padding--top-4 padding--bottom-8"
       v-scroll:in="() => { visible = true; loaded = true }"
       v-scroll:out="() => visible = false">
    <div class="container-fluid">
      <h2 class="text-italic" :class="{ 'primary': !isDtmTrophy }">{{ $t('topProducts.title') }}</h2>
      <swiper class="row" :ref="productSwiper" :options="productSwiperOptions">
        <swiper-slide v-for="(product, index) in products" :key="index">
          <div
            :class="{'top-products__swiper-slide--second': index === 1, 'view': visible}"
            class="top-products__swiper-slide padding--top-5 padding--bottom-5">
            <div
              v-if="index % 2 === 0"
              class="col-auto top-products__name-background fade-in-child fade-in--right">
              <div class="top-products__name" :class="{ 'top-products__name--dtm-trophy': isDtmTrophy }" v-text="product.name"/>
            </div>
            <div
              class="col-auto top-products__img-box bg-default box-shadow"
              :class="{'top-products__img-box--second': index % 2 !== 0 }">
              <a
                class="top-products__img"
                :href="product.link ? product.link.url : ''"
                :target="product.link && product.link.external ? '_blank' : '_self'"
                :class="{'top-products__img--second': index % 2 !== 0 }">
                <div class="overflow--hidden position-relative">
                  <background-image
                    :url="product.image.url"
                    :scale="'1-1'"
                    :title="product.imageAlt"
                    :loaded="loaded"
                  ></background-image>
                </div>
              </a>
            </div>
            <div
              v-if="index % 2 !== 0"
              class="col-auto top-products__name-background fade-in-child fade-in--right">
              <div class="top-products__name top-products__name--second" :class="{ 'top-products__name--dtm-trophy': isDtmTrophy }" v-text="product.name"/>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import BackgroundImage from './layout/BackgroundImage';

export default {
  name: 'TopProducts',
  components: { BackgroundImage },
  data () {
    return {
      visible: false,
      loaded: false,
      products: [],
      productSwiper: false,
      productSwiperOptions: {
        breakpoints: {
          576: {
            slidesPerView: 1,
            autoplay: true
          },
          980: {
            slidesPerView: 3,
            autoplay: true
          }
        }
      }
    };
  },
  computed: {
    isDtmTrophy: function () {
      return this.$store.getters['page/raceSeries'] === 'dtm-trophy';
    }
  },
  methods: {
    async fetchTopProducts () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=topProducts'
      })
        .then(response => {
          this.products = response.products;
        });
    }
  },
  created () {
    this.fetchTopProducts();
  }
};
</script>

<style lang="scss" scoped>
  .top-products {

    &__swiper-slide {
      &--second {
        margin-top: 60px;
      }
    }

    &__name-background {
      display: block;
      -webkit-transform: skewX(-13deg);
      transform: skewX(-13deg);
      background: none;
      font-size: 20px;
      text-align: center;
    }

    &__name {
      font-style: normal;
      display: block;
      color: $color-bg;
      font-weight: 900;
      background: $color-primary;
      line-height: 1.3em;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      padding: 20px 20px 30px 20px;
      position: relative;
      z-index: 0;
      &--dtm-trophy {
        background: $color-bg;
        color: $color-primary;
      }
      &--second {
        padding: 30px 20px 20px 20px;
      }
    }

    &__img-box {
      position: relative;
      z-index: 1;
      margin: -20px 40px 0 40px;
      padding: 0 0 10px 0;
      &--second {
        margin-top: 0;
        margin-bottom: -20px;
        padding: 10px 0 0 0;
      }
    }

    &__img {
      max-width: 100%;
      height: auto;
      margin-bottom: -80px;
      cursor: pointer;
      display: block;
      &--second {
        margin-bottom: 0;
        margin-top: -80px;
      }
    }
  }
</style>

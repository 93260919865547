<template>
  <div class="mydtm-voting">
    <div class="position-relative" v-if="data">
      <div class="padding--top-6"
           :class="{ 'view': visible }"
           v-scroll:in="() => { visible = true; loaded = true }"
           v-scroll:out="() => visible = false">
        <div class="container-fluid">
          <div class="row no-gutters mydtm-preferences__row" v-if="userPreference && loadedPreferences">
            <div class="col-12 text-align--center">
              <h3 class="bg-primary h1-sm"><span>{{ data.headline }}</span></h3>
              <div v-if="data.imageOne" class="mt-5 mb-2 text-align--center">
                <img :src="data.imageOne.url" :alt="data.headline" :width="data.imageOne.width" style="max-width: 80%">
              </div>
              <div class="mt-2 mb-5 small" v-if="data.richText" v-html="data.richText.html"></div>
            </div>
            <template v-for="question in userPreference">
              <div class="col-12" :key="`question-${question.key}`">
                <div class="fade-in-child fade-in--bottom delay--100 text-align--center" v-if="question.headline">
                  <h4><span class="text-uppercase">{{ question.headline }}</span></h4>
                  <div v-if="question.possibleAnswers > 0">
                    {{ $t('mydtm.maxChoices') }}: {{ question.possibleAnswers }}
                  </div>
                </div>
                <div class="mt-5 mydtm-voting__answers">
                  <ul v-if="question.answersFormatted && question.answersFormatted.length">
                    <li v-for="(answer, answerIndex) in question.answersFormatted"
                        @click="updateAnswer(question, answer)"
                        :class="{ 'selected': question.currentAnswer.includes(answer.key) }"
                        :key="`q-${question.key}-a-${answerIndex}`">
                      <div class="mydtm-voting__checkbox"><div></div></div>
                      {{ answer.text }}
                    </li>
                  </ul>
                  <div v-if="question.freeInput">
                    <input type="text" class="input-group d-block w-100 p-3" v-model="question.currentAnswer[0]" @keyup="updateAnswer(question)">
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="userPreference && loadedPreferences" class="padding--bottom-4">
        <div v-if="consentText && consentKey">
          <div class="mt-2 mb-5 mydtm-voting__answers">
            <ul>
              <li
                @click="consent = !consent"
                :class="{ 'selected': consent }">
                <div class="mydtm-voting__checkbox"><div></div></div>
                {{ consentText }}
              </li>
            </ul>
            <div v-if="showAlertConsent" class="pt-3 pb-3 pl-2 pl-2 text-primary">
              {{ $t('mydtm.missingConsent') }}
            </div>
          </div>
        </div>

        <div class="row justify-content-center padding--bottom-1">
          <div class="col-auto">
            <div :class="{'mydtm-preferences__alert--active': showAlert || showAlertSaved }" class="mydtm-preferences__alert" role="alert">
              <div v-if="showAlert">{{ $t('mydtm.missingChoices') }}</div>
              <div v-if="showAlertSaved">{{ $t('mydtm.preferencesAlert') }}</div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-auto text-align--center">
            <button type="button" class="button-primary primary d-inline-block mb-1" @click="savePreferences" v-html="isAuthenticated ? $t('mydtm.preferencesVote') : $t('mydtm.preferencesMustBeLoggedIn')"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AppConst from '@/config/app.const';
import Settings from '@/config/settings';
import Axios from 'axios';

export default {
  name: 'MydtmSurvey',
  props: {
    id: String,
    data: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      preferenceKey: '',
      loaded: false,
      loadedPreferences: false,
      visible: false,
      changes: false,
      saving: false,
      showAlert: false,
      showAlertSaved: false,
      showAlertConsent: false,
      singleText: [],
      userPreference: null,
      dataPreferences: {
        userId: '',
        tid: '',
        preferences: []
      },
      consent: false,
      consentKey: '',
      consentText: '',
      drivers: [],
      showResults: false,
      showResultsOnly: false,
      results: {},
      teams: [],
      manufacturers: []
    };
  },
  computed: {
    lineContent: function () {
      return this.$store.getters['page/raceSeries'] === 'dtm-trophy';
    },
    isAuthenticated () {
      return this.$msal.isAuthenticated;
    },
    username () {
      return this.$msal.username;
    },
    token () {
      return this.$msal.accessToken;
    },
    savedPreferences: function () {
      return this.$store.getters['adb2c/userPreferencesVoting'];
    }
  },
  methods: {
    async signIn () {
      await this.$msal.signIn();
    },
    updateAnswer (question, answer) {
      if (question.freeInput) {
        // replace characters
        this.$set(question.currentAnswer, 0, question.currentAnswer[0].replace(/[&/\\#,+()$~%.'":*?<>{}=^°]/g, ''));
      } else {
        if (question.currentAnswer.includes(answer.key)) {
          question.currentAnswer.forEach((a, i) => {
            if (a === answer.key) {
              question.currentAnswer.splice(i, 1);
            }
          });
        } else {
          if (question.possibleAnswers === 0 || (question.possibleAnswers > 0 && question.currentAnswer.length < question.possibleAnswers)) {
            question.currentAnswer.push(answer.key);
          } else if (question.possibleAnswers === 1) {
            question.currentAnswer = [answer.key];
          }
        }
      }
    },
    async fetchUserdata () {
      if (this.isAuthenticated) {
        await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
          path: `getPreference?userId=${this.$msal.userId}&userPreference=${this.preferenceKey}`,
          lifetime: 0
        })
          .then(response => {
            if (response.data) {
              response.data.forEach((entry) => {
                if (this.userPreference && this.userPreference.length && this.preferenceKey === entry.p) {
                  this.userPreference.forEach((up) => {
                    if (up.key === entry.q) {
                      up.savedAnswer = entry.text.split(';');
                      up.currentAnswer = entry.text.split(';');
                    }
                  });
                }
              });
            }
          })
          .catch(() => {
            // console.log('got error');
          });
      }
    },
    async fetchPreferences () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=userPreference&key=${this.preferenceKey}`,
        lifetime: 0
      })
        .then(response => {
          if ('userPreferences' in response) {
            // loooooop through preferences
            const questions = response.userPreferences[0].userPreferenceQuestions;
            questions.forEach((question) => {
              if (question.freeInput) {
                question.currentAnswer = [''];
                question.savedAnswer = [''];
              } else {
                question.currentAnswer = [];
                question.savedAnswer = [];
              }
              // format answer list to have a answer key or answerIndex as key
              const answers = [];
              question.answers.forEach((answer, answerIndex) => {
                const answerAndKey = answer.split(';');
                answers.push({
                  text: answerAndKey[0],
                  key: answerAndKey.length > 1 ? answerAndKey[1] : answerIndex
                });
              });
              question.answersFormatted = answers;
            });
            this.consentKey = response.userPreferences[0].consentKey;
            this.consentText = response.userPreferences[0].consentText;
            this.userPreference = questions;
            this.loadedPreferences = true;
            this.fetchUserdata();
          }
        }).catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    async savePreferences () {
      this.showAlertConsent = false;
      if (!this.isAuthenticated) {
        // this.$router.push(`/${this.$i18n.locale}/mydtm`);
        this.signIn();
      } else {
        const data = {
          userId: this.$msal.userId,
          tid: this.$msal.accessToken,
          preferences: []
        };
        Object.keys(window.sessionStorage).forEach((key) => {
          const sess = window.sessionStorage[key];
          if (sess) {
            try {
              const json = JSON.parse(sess);
              if (json.credentialType && json.credentialType.toLowerCase() === 'idtoken') {
                data.t = json.secret;
              }
            } catch (e) {
              //
            }
          }
        });
        let allQuestionsAnswered = true;
        this.userPreference.forEach((question, i) => {
          if (question.currentAnswer.length === 0) {
            allQuestionsAnswered = false;
          }
          if (question.freeInput) {
            question.currentAnswer[0] = question.currentAnswer[0].trim();
            if (question.currentAnswer[0].length === 0) {
              allQuestionsAnswered = false;
            }
          }
          const preference = {
            userPreference: this.preferenceKey,
            userPreferenceQuestion: question.key,
            tagId: [],
            textInputs: question.currentAnswer || [],
            crmId: question.crmId
          };
          const savedPreferenceKey = `${this.preferenceKey}.${question.key}`;
          if (this.savedPreferences && savedPreferenceKey in this.savedPreferences) {
            preference.id = this.savedPreferences[savedPreferenceKey].id;
          }
          // preference.id = if user already had preference saved
          data.preferences.push(preference);
        });
        this.showAlert = !allQuestionsAnswered;
        const consentChecked = !this.consentKey || (this.consentKey && this.consent);
        if (!consentChecked) {
          // this.showAlertConsent = true;
        } else {
          data.preferences.push({
            userPreference: this.preferenceKey,
            userPreferenceQuestion: 99,
            tagId: [],
            textInputs: [this.consentKey],
            crmId: ''
          });
        }
        // const dontSave = true;
        if (!this.saving && allQuestionsAnswered && data.t) {
          this.saving = true;
          Axios.post(`${Settings.nodeApi.uri}sendPreference`, data, {
          }).then((res) => {
            this.saving = false;
            this.handleAlert();
          }).catch((e) => {
            this.saving = false;
            this.handleAlert();
          });
        }
      }
    },
    handleAlert () {
      this.showAlertSaved = true;
      setTimeout(() => {
        this.showAlertSaved = false;
      }, 4000);
    }
  },
  watch: {
    '$msal.isAuthenticated': function () {
      if (this.page && this.page.gated && !this.$msal.isAuthenticated) {
        // this.$router.push(`/${this.$i18n.locale}/mydtm`);
      }
    }
  },
  created () {
    if (this.data && this.data.jsonData) {
      if (this.data.jsonData.preferenceKey) {
        this.preferenceKey = this.data.jsonData.preferenceKey;
      }
      if (this.data.jsonData.results) {
        this.showResultsOnly = true;
      }
    }
    if (!this.$msal.isAuthenticated) {
      // this.$router.push(`/${this.$i18n.locale}/mydtm`);
    } else {
      this.dataPreferences.userId = this.$msal.userId;
      this.dataPreferences.tid = this.$msal.accessToken;
    }
    if (this.data && this.data.singleText) {
      const singleText = [];
      this.data.singleText.forEach((line) => {
        let icon = '';
        let text = line;
        if (text.substr(0, 4) === 'icon') {
          icon = line.substr(0, line.indexOf(';'));
          text = line.substr(line.indexOf(';') + 1);
        }
        singleText.push({ icon: icon, text: text });
      });
      this.singleText = singleText;
      this.$store.dispatch(
        'adb2c/fetchUserPreferencesVoting',
        { userId: this.$msal.userId || '', key: this.preferenceKey })
        .then(() => this.fetchPreferences());
    }
  }
};
</script>

<style lang="scss">
.mydtm-voting {
  $root: &;
  &__checkbox {
    position: absolute;
    left: $gap / 2;
    top: 5px;
    width: 25px;
    height: 25px;
    background: $color-bg;
    transition: all .2s;
    div {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 80%;
      height: 80%;
      background: $color-primary;
      transform: translate(-50%, -50%) scale(0);
      transition: all .3s ease-out;
    }
  }
  &__answers {
    max-width: 90vw;
    width: 500px;
    background: $color-bg-dark;
    box-shadow: 0 0 20px rgba($color-bg-dark, 0.4);
    margin: $gap auto $gap * 3;
    padding: $gap / 2;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: block;
        position: relative;
        padding: $gap / 2 0 $gap / 2 45px;
        cursor: pointer;
        &:hover {
          color: $color-primary;
          #{$root}__checkbox {
            div {
              transform: translate(-50%, -50%) scale(0.4);
              transition: all .3s $ease-out-back;
            }
          }
        }
        &.selected {
          color: $color-primary;
          #{$root}__checkbox {
            div {
              background: $color-primary;
              transform: translate(-50%, -50%) scale(0.8);
              transition: all .3s $ease-out-back;
            }
          }
        }
      }
    }
  }
}
</style>

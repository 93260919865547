<template>
  <div v-if="informations.length > 0"
    class="racetrack-info margin--top-2 margin--bottom-8"
       v-scroll:in="() => { visible = true; loaded = true }"
       v-scroll:out="() => visible = false">
    <div class="bg-white position-relative racetrack-info__z-index-12">
      <div class="container-fluid racetrack-info__border">
        <div class="row padding--top-4 padding--bottom-2">
          <div class="col-12">
            <div class="h4 text-align--center font-weight--light text-italic text-uppercase">
              {{ $t('raceCalendar.trackSections')}}
            </div>
            <h2 class="text-align--center">
              {{ $t('raceCalendar.dtmTracks')}}
            </h2>
          </div>
        </div>
        <div class="row padding--bottom-2 d-flex align-items-start justify-content-center">
          <div
            class="col-6 col-md-3"
            v-for="(info, index) in informations" :key="index">
            <div class="racetrack-info__text">
              <div v-if="info.headline" class="text-align--center h5 font-weight--bold text-uppercase"
                   v-text="info.headline"/>
              <h2 v-if="info.value" class="text-align--center margin--bottom-1"
                  v-text="info.value"/>
              <div v-if="info.subline" class="text-align--center h4 font-weight--light text-italic"
                   v-text="info.subline"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative racetrack-info__z-index-10">
      <div class="racetrack-info__fill">
        <swiper ref="poiSwiper" :options="poiSwiperOptions" class="h-100">
          <swiper-slide v-for="(poi, index) in pois" :key="index" class="h-100">
            <div class="overflow--hidden h-100 position-relative padding-5">
              <background-image
                v-if="poi.image"
                :url="`https://media.graphassets.com/resize=w:2800,fit:crop/output=format:jpg/quality=value:70/compress/${poi.image.handle}`"
                :scale="'16-9'"
                :title="poi.imageAlt"
                :full="true"
                :loaded="loaded"/>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="container-fluid position-relative racetrack-info__z-index-12">
        <div class="row justify-content-center padding--top-8 padding--bottom-8 d-flex align-items-center racetrack-info__separator">
          <div class="col-12 col-md-6 text-align--center">
            <div class="overflow--hidden position-relative box-shadow racetrack-info__background-opaque">
              <background-image
                v-if="poiMap"
                :url="`https://media.graphassets.com/resize=w:1200,fit:crop/quality=value:70/compress/${poiMap.handle}`"
                :scale="'1-1'"
                :title="poiMapAlt"
                :loaded="loaded"
                :scrollScale="false"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '../layout/BackgroundImage';
export default {
  name: 'RacetrackInformations',
  components: { BackgroundImage },
  props: {
    informations: {
      type: Array,
      required: true
    },
    poiMap: {
      type: Object,
      required: false,
      default: () => {}
    },
    poiMapAlt: {
      type: String,
      required: false
    },
    poiMapTitle: {
      type: String,
      required: false
    },
    pois: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      visible: false,
      poiSwiperOptions: {
        effect: 'fade',
        autoplay: {
          delay: 4000
        },
        navigation: {
          prevEl: '.racetrack-info__button--prev',
          nextEl: '.racetrack-info__button--next'
        },
        on: {
          slideChange: function () {
            if (this.isEnd) {
              setTimeout(() => {
                this.slideToLoop(0);
              }, 4000);
            } else {
              setTimeout(() => {
                this.slideToLoop(this.activeIndex + 1);
              }, 4000);
            }
          }
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
  .racetrack-info {
    position: relative;
    z-index: 10;
    &__z-index-12 {
      z-index: 12;
    }
    &__z-index-10 {
      z-index: 10;
    }
    &__fill {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &__background-opaque {
      background: rgba(0, 0, 0, 0.4);
    }
    &__border {
      border-top: 12px solid $color-primary;
    }
    &__button {
      position: absolute;
      top: 50%;
      margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      &--prev {
        left: 0;
        right: auto;
      }
      &--next {
        left: auto;
        right: 0;
      }
      &--disabled {
        display: none;
      }
    }

    &__icon {
      width: 24px;
      height: 24px;

      &--prev {
        transform: rotate(180deg);
      }
    }

    &__separator {
      border-top: 50px solid #fff;
    }
  }
  @media all and (max-width: map_get($grid-breakpoints, 'md')) {
    .racetrack-info {
      &__text {
        margin-bottom: 30px;
      }

      &__button {
        &--prev {
          left: 5px;
          right: auto;
        }
        &--next {
          left: auto;
          right: 5px;
        }
      }
    }
  }
</style>

<template>
  <div class="overflow--hidden position-relative youtube">
    <div v-if="!embeded && !streamUrl"
         v-scroll:in="() => loaded = true"
         @click="embeded = true"
         class="play-button__parent">
      <background-image :scale="'16-9'" :url="youtubePreview()" :loaded="loaded"/>
      <div class="play-button">
        <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
          <path d="M24,18 45,32 24,46z"></path>
        </svg>
      </div>
    </div>
    <div v-if="embeded && !streamUrl" class="youtube__iframe-holder">
      <iframe class="youtube__iframe" width="100%" height="100%" title="Youtube embed" :src="youtubeUrl()" frameborder="0" allowfullscreen></iframe>
    </div>
    <div v-if="streamUrl" class="youtube__iframe-holder">
      <iframe class="youtube__iframe" width="100%" height="100%" title="Livestream" :src="liveStreamUrl()" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
    </div>
  </div>
</template>

<script>
import BackgroundImage from './layout/BackgroundImage';
import AppConst from '../config/app.const';
import Settings from '../config/settings';

export default {
  name: 'Youtube',
  components: { BackgroundImage },
  props: {
    videoId: {
      type: String,
      default: null,
      required: false
    },
    id: {
      type: String,
      default: null,
      required: false
    },
    streamUrl: {
      type: String,
      required: false,
      default: null
    },
    dataObj: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      loaded: false,
      embeded: false,
      videoData: null,
      loadedVideoId: null
    };
  },
  computed: {
    videoIdentifier: function () {
      return this.videoId || this.loadedVideoId;
    }
  },
  methods: {
    youtubePreview: function () {
      return `https://img.youtube.com/vi/${this.videoIdentifier}/maxresdefault.jpg`;
    },
    youtubeUrl: function () {
      return `https://www.youtube.com/embed/${this.videoIdentifier}?rel=0;&autoplay=1&mute=1&loop=1`;
    },
    liveStreamUrl: function () {
      return `${this.streamUrl}?rel=0;&autoplay=1&mute=1&loop=1`;
    },
    async fetchModuleVideo () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleVideo&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.loadedVideoId = response.moduleVideo.videoId;
        });
    }
  },
  created () {
    if (this.videoId === null && this.id !== null && this.dataObj == null) {
      this.fetchModuleVideo();
    }
    if (this.dataObj != null) {
      this.loadedVideoId = this.dataObj.videoId;
    }
  }
};
</script>

<style lang="scss">
  .youtube {
    &__iframe-holder {
      position: relative;
      padding-top: 56.25%;
    }
    &__iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>

<template>
  <div class="partner bg-white text-default padding--top-4 padding--bottom-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h4 v-if="title" class="text-uppercase margin--bottom-2">{{ title }}</h4>
        </div>
      </div>
      <div class="row" :class="{ 'justify-content-between': premiumPartners.length > 2, 'justify-content-left': premiumPartners.length <= 2 }">
        <div v-for="(partner, index) in premiumPartners" :key="index" class="col-auto">
          <a :href="partner.link && partner.link.url ? partner.link.url : '#'" target="_blank" :title="partner.link ? partner.link.title : ''">
            <img
              class="partner__image"
              :alt="partner.link ? partner.link.title : ''"
              :width="partner.logo.width"
              :height="partner.logo.height"
              :src="partner.logo.url"/>
          </a>
        </div>
      </div>
      <div v-if="secondLevelPartners.length > 0" class="col-12 partner__hr"></div>
      <div class="row d-flex justify-content-center">
        <div v-for="(partner, index) in secondLevelPartners" :key="index" class="col-auto partner__second-level d-flex flex-column align-items-center">
          <a :href="partner.link && partner.link.url ? partner.link.url : '#'" target="_blank" :title="partner.link ? partner.link.title : ''">
            <img
              class="partner__image-second-level"
              :alt="partner.link ? partner.link.title : ''"
              :src="partner.logo.url"/>
          </a>
          <div v-if="partner.logo && partner.logo.title" class="text-default padding--right-1 padding--left-1" v-text="partner.logo.title"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';

export default {
  name: 'Partner',
  props: {
    name: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    isSupplier: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      partners: []
    };
  },
  computed: {
    premiumPartners: function () {
      return this.partners.filter(partner => {
        return partner.premiumPartner;
      });
    },
    secondLevelPartners: function () {
      return this.partners.filter(partner => {
        return !partner.premiumPartner;
      });
    },
    raceSeries: function () {
      return this.$store.getters['page/raceSeries'][0];
    }
  },
  methods: {
    async fetchPartners () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=partnersByRaceSeries&raceSeries=${this.raceSeries}&supplier=${this.isSupplier}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.partners = response.partners;
        });
    }
  },
  watch: {
    $route () {
      this.fetchPartners();
    }
  },
  created () {
    this.fetchPartners();
  }
};
</script>

<style lang="scss" scoped>
  .partner {
    &__image {
      width: 115px;
      height: 75px;
      max-width: 100%;
    }

    &__hr {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      border: 0;
      border-top: 1px solid $color-bg;
      opacity: 0.8;
    }

    &__image-second-level {
      width: auto;
      height: 100px;
    }
  }

  @media all and (max-width: map_get($grid-breakpoints, 'sm')) {
    .partner {
      &__second-level {
        margin: 15px 0;
      }
    }
  }
</style>

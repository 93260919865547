<template>
  <div v-if="data.headline && data.imageOne && data.imageTwo && data.links.length > 0" class="app-store-badges bg-dark py-4">
    <div class="container-fluid">
      <div class="d-flex justify-content-center">
        <h4 class="text-white" v-text="data.headline"/>
      </div>
      <div class="d-flex flex-wrap justify-content-center py-2">
        <a v-if="data.imageOne"
           :href="data.links[0].url"
           :title="data.links[0].title"
           class="m-2 py-md-0 app-store-badges__link"
           :target="{'_blank': data.links[0].external}">
          <img
            class="app-store-badges__img app-store-badges__img--ios"
            :src="data.imageOne.url"
            :alt="data.imageOne.alt ? data.imageOne.alt : 'Download in the IOS App Store'"
            :title="data.imageOne.title ? data.imageOne.title : 'Download in the IOS App Store'"/>
        </a>
        <a v-if="data.imageTwo"
           :href="data.links[1].url"
           class="m-2 py-md-0 app-store-badges__link"
           :title="data.links[1].title"
           :target="{'_blank': data.links[0].external}">
          <img
            class="app-store-badges__img app-store-badges__img--android"
            :src="data.imageTwo.url"
            :alt="data.imageTwo.alt ? data.imageTwo.alt : 'Load in the Google Play Store'"
            :title="data.imageTwo.title ? data.imageTwo.title : 'Load in the Google Play Store'"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppStoreBadges',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.app-store-badges {
  position: relative;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;

  &__img {
    width: 200px;
    height: 60px;
  }
}
</style>

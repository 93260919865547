<template>
  <div class="position-relative bg-medium pt-5 skew"
       v-scroll:in="() => { visible = true; loaded = true }"
       v-scroll:out="() => visible = false">
    <div class="skew__container">
      <div class="container-fluid" :class="{'view': visible}">
        <h2 class="text-uppercase text-italic text-primary fade-in-child fade-in--bottom delay--100"
            v-text="headline"/>
        <div class="row justify-content-center align-items-end" v-if="event.drivers && wigeData">
          <div class="position-relative col-12"
               :class="{ 'col-sm-4 col-md-3': row !== 0, 'col-sm-4': row === 0, 'order-0 order-sm-1': row === 0, 'order-1 order-sm-0': row === 1, 'order-2': row === 2 }"
               v-for="row in [1, 0, 2]"
               :key="'driver-seasonreview-' + row">
            <div class="col-10 col-sm-12 offset-2 offset-sm-0 seasonreview__image">
              <img
                :src="wigeData.getDriverImage(event.drivers[row], event, 400)"
                width="100%"
                class="position-relative"
                style="transform: translate(0, 5px)"
                :alt="event.drivers[row].name"
              >
            </div>
            <div class="standings-text">
              <div class="h1 position-absolute text-center standings-h1">{{ row + 1 }}</div>
              <router-link
                :to="`/${$i18n.locale}/${$t('navigation.driver')}/${wigeData.getDriverSlug(event.drivers[row], event)}`">
                <div class="bg-primary h3 bg-primary--hover" :class="{ 'h4': row !== 0 }">
                  <span
                    v-html="wigeData.getDriverFullname(event.drivers[row], event, true)"></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-medium">
        <div class="row justify-content-center pb-5 pt-4">
          <div class="col-auto pb-4 pb-sm-0">
            <router-link
              class="text-uppercase"
              :class="{'button-ghost--inverted' :isDtmTrophy, 'button-ghost primary': !isDtmTrophy}"
              :to="{ path: routeResults }">{{ $t('navigation.results').toUpperCase() }}</router-link>
          </div>
          <div class="col-auto">
            <router-link
              class="text-uppercase"
              :class="{'button-ghost--inverted' :isDtmTrophy, 'button-ghost primary': !isDtmTrophy}"
              :to="{ path: routeStandings }">{{ $t('navigation.standings').toUpperCase() }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AppConst from '@/config/app.const';
import Settings from '@/config/settings';
import WigeDataModel from '../entity/WigeDataModel';

export default {
  name: 'SeasonReview',
  components: { },
  props: {
    breakAfter: { type: Boolean, default: true },
    count: { type: Number, default: 20 },
    primaryHeadline: {
      type: String,
      required: false
    },
    secondaryHeadline: {
      type: String,
      required: false
    },
    season: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      native: true,
      visible: false,
      loaded: false,
      offset: '',
      cards: [],
      limit: 8,
      routeResults: '',
      routeStandings: '',
      year: '',
      event: {},
      wigeData: null,
      drivers: []
    };
  },
  computed: {
    queryName: function () {
      return this.$store.getters['page/raceSeriesDrivers'];
    },
    isDtmTrophy: function () {
      return this.$store.getters['page/raceSeries'] === 'dtm-trophy';
    },
    headline: function () {
      return this.primaryHeadline || `${this.$t('results.season')} ${this.season}`;
    }
  },
  methods: {
    async fetchDrivers () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=${this.queryName}`,
        lifetime: Settings.LIFETIME_DEFAULT
      }).then(response => {
        this.wigeData.parseDriversResponse(response.drivers);
        this.fetchStandings();
      });
    },
    async fetchStandings () {
      if (!this.year) {
        this.year = parseInt(this.season);
      }
      const startDate = new Date(this.year + '-01-01');
      const endDate = new Date(this.year + '-12-31');
      let raceSeries = 'DTM';
      if (this.raceSeries) {
        raceSeries = this.raceSeries.toUpperCase();
      }
      if (this.settings && 'raceSeries' in this.settings) {
        raceSeries = this.settings.raceSeries.toUpperCase();
      }
      this.event = {};
      const schema = raceSeries === 'DTM' ? 'wigeStandings' : 'wigeStandingsTrophy';
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=' +
          schema +
          '&raceSeries=' +
          raceSeries +
          '&startDate=' +
          (startDate.getFullYear() + '-' + ('00' + (startDate.getMonth() + 1)).slice(-2) + '-' + ('00' + startDate.getDate()).slice(-2)) +
          '&endDate=' +
          (endDate.getFullYear() + '-' + ('00' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('00' + endDate.getDate()).slice(-2)),
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.wigeData.parseStandingsResponse(response, raceSeries);
          this.event = this.wigeData.event;
        })
        .catch(() => {
          //
        });
    }
  },
  watch: {},
  mounted () {
    this.wigeData = new WigeDataModel();
    this.fetchDrivers();
    this.routeResults = `/${this.$i18n.locale}/${(this.isDtmTrophy ? 'dtm-trophy/' : '')}${this.$t('navigation.results')}?season=${this.season}`;
    this.routeStandings = `/${this.$i18n.locale}/${(this.isDtmTrophy ? 'dtm-trophy/' : '')}${this.$t('navigation.standings')}?season=${this.season}`;
  }
};
</script>

<style lang="scss">
//
.seasonreview__image {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(0deg, rgba(30, 30, 30, 1), rgba(30, 30, 30, 0));
  }
}
.standings-h1 {
  font-size: 8em;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.4)
}
.standings-text {
  position: absolute;
  bottom: 10px;
  left: 20%;
  width: 80%;
}
@media (max-width: 768px) {
  .standings-h1 {
    font-size: 4em;
  }
}
@media (max-width: 500px) {

}
</style>

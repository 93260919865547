<template>
  <div class="plain-text padding--bottom-1" v-if="textData" :class="{ 'view': visible || !fade }">
    <div class="row">
      <div class="col-12" v-scroll:in="() => visible = true" v-scroll:out="() => visible = false">
        <h3 class="fade-in-child fade-in--bottom" v-if="textData.headline" v-text="textData.headline"/>
        <div class="fade-in-child fade-in--bottom delay--100" v-if="textData.text && textData.text.html" v-html="textData.text.html"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import Settings from '../config/settings';

export default {
  name: 'PlainText',
  props: {
    id: String,
    fade: {
      type: Boolean,
      default: false
    },
    dataObj: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      visible: false,
      textData: null
    };
  },
  methods: {
    async fetchModuleText () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleText&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.textData = response.moduleText;
        });
    }
  },
  created () {
    if (this.id) {
      this.fetchModuleText();
    }
    if (this.dataObj !== null) {
      this.textData = this.dataObj;
    }
  }
};
</script>

<style lang="scss">
  .plain-text {
    h3 {
      margin-bottom: $gap * 2;
    }
    h4 {
      font-size: 18px;
      text-transform: none;
    }
    p {
      margin-bottom: $gap * 1.5;
    }
    li div p {
      margin: 0;
    }
  }
</style>

<template>
  <div>
    <div class="bg-white text-align--center">
      <div class="page-not-found__img" :style="{ 'background-image': `url(${this.svg})` }">
        <div class="page-not-found__text text-white">
          <div class="text-uppercase" v-text="$t('common.notFoundText')"></div>
          <div class="h4" v-text="$t('common.notFound')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageNotFound',
  data () {
    return {
      svg: null
    };
  },
  created () {
    this.svg = require('../assets/images/404.svg');
  }
};
</script>

<style lang="scss" scoped>
  .page-not-found {
    &__img {
      width: 100%;
      height: 800px;
      background-position: center center;
      background-size: cover;
    }

    &__text {
      padding-top: 50px;
    }
  }
</style>

export default {
  namespaced: true,
  state: {
    globals: {}
  },
  getters: {
    cookieText: state => {
      return state.globals['cookieLayer:text'];
    },
    cookieTerms: state => {
      return state.globals['cookieLayer:button:terms:label'];
    },
    cookieAccept: state => {
      return state.globals['cookieLayer:button:accept'];
    },
    cookieUrl: state => {
      return state.globals['cookieLayer:button:terms:url'];
    }
  },
  mutations: {
    ADD_GLOBALS: (state, globals) => {
      state.globals = globals;
    }
  }
};

import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import globals from './modules/globals';
import scroll from './modules/scroll';
import language from './modules/language';
import page from './modules/page';
import adb2c from './modules/adb2c';
import settings from '../config/settings';
import i18n from '../i18n';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    endpoints: {}
  },
  modules: {
    globals,
    scroll,
    language,
    page,
    adb2c
  },
  mutations: {
    ADD_ENDPOINT: (state, payload) => {
      const endpoint = Object.assign({}, {
        path: '',
        data: [],
        timeout: 60 * 1000,
        created: new Date()
      }, payload);
      if (endpoint.path in state.endpoints) {
        delete state.endpoints[endpoint.path];
      }
      state.endpoints[endpoint.path] = endpoint;
      window.localStorage.setItem('endpoints', JSON.stringify(state.endpoints));
    }
  },
  actions: {
    async fetchByPath ({ commit, state }, payload) {
      if (typeof payload !== 'object') {
        // fallback payload string to object
        payload = { path: payload };
      }
      let lifetime = payload.lifetime || settings.LIFETIME_SHORT;
      let path = payload.path || '';
      if (process.env.NODE_ENV !== 'production') {
        lifetime = settings.LIFETIME_SHORT;
      }
      // our current query key to check against queries
      let endpoint = null;
      if (path.indexOf('lang=') === -1) {
        path += '&lang=' + (i18n.locale || 'en');
      }
      const stateEndpoint = state.endpoints[path] || null;
      const storageEndpoint = window.localStorage.getItem('endpoints');
      if (stateEndpoint) {
        endpoint = stateEndpoint;
      } else if (storageEndpoint) {
        endpoint = JSON.parse(storageEndpoint)[path];
      }
      const now = new Date();
      let storedData = null;
      // do we have a stored endpoint?
      if (endpoint) {
        // if data has been loaded from localStorage the endpoint.date will be a string, not a Date() object
        if (typeof endpoint.created !== 'object') {
          endpoint.created = new Date(endpoint.created);
        }
        // lifetime check. return stored data if old enough
        if (now - endpoint.created < lifetime) {
          storedData = endpoint.data;
        }
      }

      if (storedData === null) {
        storedData = await Axios.get(settings.nodeApi.uri + path, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          commit('ADD_ENDPOINT', { path: path, data: response.data });
          return response.data;
        });
      }
      return storedData;
    }
  }
});

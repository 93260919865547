<template>
  <div v-if="moduleData" class="event-benefit margin--top-2 margin--bottom-4">
    <div class="event-benefit__wrapper container-fluid padding-4 bg-default">
      <div class="event-benefit__square"></div>
      <div class="event-benefit__square event-benefit__square--behind">
      </div>
      <div class="row">
        <div class="col-12">
          <div class="h4 font-weight--light text-italic text-uppercase text-white" v-text="moduleData.topline"/>
          <h2 class="text-white" v-text="moduleData.headline"></h2>
        </div>
      </div>
      <div class="row margin--top-2">
        <div class="col-12" v-for="(benefit, index) in moduleData.benefits" :key="index">
          <div class="event-benefit__items d-flex align-items-center">
            <svg class="text-white margin--right-1" fill="currentColor" height="20px" viewBox="0 0 36 27" width="20px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g fill="none" fill-rule="evenodd"><path d="M-6-10h48v46H-6z"></path><path fill="currentColor" fill-rule="nonzero" d="M12 20.992L3.66 13 .82 15.703 12 26.416l24-23L33.18.714z"></path></g></svg>
            <div class="text-white h4 font-weight--normal" v-text="benefit"/>
          </div>
        </div>
      </div>
      <div class="row margin--top-2">
        <div class="col-12 d-inline-flex" v-if="moduleData.link && moduleData.link.url && moduleData.link.title">
          <a class="button-primary" :href="moduleData.link.url" target="_blank" v-text="moduleData.link.title"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';

export default {
  name: 'EventBenefit',
  props: {
    module: {
      type: Object,
      required: false,
      default: null
    },
    id: {
      type: String,
      default: null,
      required: false
    }
  },
  data () {
    return {
      loadedData: null
    };
  },
  computed: {
    moduleData: function () {
      return this.module || this.loadedData;
    }
  },
  methods: {
    async fetchEventBenefitById () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleBenefits&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.loadedData = response.moduleBenefits;
        });
    }
  },
  created () {
    if (this.module === null && this.id !== null) {
      this.fetchEventBenefitById();
    }
  }
};
</script>

<style lang="scss" scoped>
  .event-benefit {
    padding-bottom: 45px;
    &__wrapper {
      position: relative;
      z-index: 1;
    }
    &__square {
      background: $color-bg;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      &--behind {
        background: $color-primary;
        top: -40px;
        left: -40px;
        width: 40%;
        z-index: -2;
      }
    }
    &__items {
      padding-bottom: 10px;
    }
  }
</style>

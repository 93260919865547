<template>
  <div class="break-after" :class="classList">
    <div class="break-after__content">
      <svg width="100%" viewBox="0 0 1440 400" :class="{ 'behind': behind }">
        <rect :class="backgroundClass" v-if="backgroundClass !== ''" x="0" y="0" width="100%" height="100%"></rect>
        <rect v-if="toWhite && !inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipNegative)" class="svg-fill-white"></rect>
        <rect v-if="toWhite && inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipInvertedNegative)" class="svg-fill-white"></rect>
        <rect v-if="toYellow && !inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipNegative)" class="svg-fill-primary"></rect>
        <rect v-if="toYellow && inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipInvertedNegative)" class="svg-fill-primary"></rect>
        <rect v-if="toDefault && !inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipNegative)" class="svg-fill-default"></rect>
        <rect v-if="toDefault && inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipInvertedNegative)" class="svg-fill-default"></rect>
        <rect v-if="toGray && !inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipNegative)" class="svg-fill-dark-gray"></rect>
        <rect v-if="toGray && inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipInvertedNegative)" class="svg-fill-dark-gray"></rect>
        <rect v-if="toDark && !inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipNegative)" class="svg-fill-dark"></rect>
        <rect v-if="toDark && inverted" x="0" y="50%" width="100%" height="100%" clip-path="url(#breakAfterClipInvertedNegative)" class="svg-fill-dark"></rect>
        <g filter="url(#shadowDefault)" opacity="0.4">
          <rect v-if="!inverted" x="-5%" width="110%" height="100%" clip-path="url(#breakAfterClip)" :fill-opacity="opacity" :fill="'url(#' + gradient + (isYellow ? 'Yellow' : '') + ')'"></rect>
          <rect v-if="inverted" x="-5%" width="110%" height="100%" clip-path="url(#breakAfterClipInverted)" :fill-opacity="opacity" :fill="'url(#' + gradient + (isYellow ? 'Yellow' : '') + ')'"></rect>
        </g>
        <rect v-if="!inverted && behind" x="-5%" width="110%" height="100%" clip-path="url(#breakAfterClip)" :fill-opacity="opacity" :fill="'url(#' + gradient + (isYellow ? 'Yellow' : '') + ')'"></rect>
        <rect v-if="inverted && behind" x="-5%" width="110%" height="100%" clip-path="url(#breakAfterClipInverted)" :fill-opacity="opacity" :fill="'url(#' + gradient + (isYellow ? 'Yellow' : '') + ')'"></rect>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreakAfter',
  props: {
    inverted: { type: Boolean, default: false },
    toWhite: { type: Boolean, default: false },
    toYellow: { type: Boolean, default: false },
    toDefault: { type: Boolean, default: false },
    toDark: { type: Boolean, default: false },
    toGray: { type: Boolean, default: false },
    gradient: { type: String, default: 'gradientTopBottom' },
    backgroundClass: { style: String, default: '' },
    classList: { type: String, default: '' },
    opacity: { type: Number, default: 1 },
    isYellow: { type: Boolean, default: false },
    behind: { type: Boolean, default: true }
  }
};
</script>

<style lang="scss">
  .break-after {
    line-height: 0;
    padding-top: 7%;
    pointer-events: none;
    &__fill {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
    }
    &__content {
      svg {
        position: absolute;
        width: 100%;
        bottom: 0;
        &.behind {
          z-index: -1;
        }
        left: 0;
      }
    }
  }
</style>

export default {
  namespaced: true,
  state: {
    language: {},
    locales: ['de', 'en'],
    localizations: []
  },
  getters: {
    slug: state => locale => {
      if (state.localizations.length) {
        const localizationByLocale = state.localizations.find(localization => {
          return localization.locale === locale;
        });
        return localizationByLocale.slug;
      }
      return null;
    },
    slugs: state => {
      return state.language;
    },
    locales: state => {
      return state.locales;
    }
  },
  mutations: {
    ADD_LANGUAGE: (state, language) => {
      state.language = language;
    },
    ADD_LOCALIZATIONS: (state, localizations) => {
      state.localizations = localizations;
    },
    ADD_LOCALE: (state, locale) => {
      if (!state.locales.includes(locale)) {
        state.locales.push(locale);
      }
    },
    REMOVE_LOCALE: (state, locale) => {
      const index = state.locales.indexOf(locale);
      if (index > -1) {
        state.locales.splice(index, 1);
      }
    }
  }
};

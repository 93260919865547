<template>
  <div class="image-gallery padding--top-4 padding--bottom-4"
       :class="{ 'view': visible }"
       v-scroll:in="() => visible = true"
       v-scroll:out="() => visible = false">
    <div class="position-relative">
      <swiper :ref="swiper" :options="options" v-if="loaded">
        <swiper-slide v-for="item in gallery.entries" :key="item.handle">
          <div>
            <div class="bgimg--hover">
              <div class="fade-in-child">
                <background-image
                  :scale="'16-9'"
                  :url="`https://media.graphassets.com/resize=w:1200,fit:crop/quality=value:70/compress/${item.handle}`"
                  :title="item.alt"
                  :loaded="loaded"/>
              </div>
            </div>
            <div v-if="item.title || item.usage" class="padding--left-4 padding--right-4">
              <div class="image-gallery__text padding--left-1 padding--top-1 padding--right-1 padding--bottom-1 bg-gray text-default">
                <div v-html="item.title"></div>
                <div v-html="item.usage" v-if="item.usage" class="text-italic small" :class="{ 'padding--top-1': item.title }"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="image-gallery__button image-gallery__button--prev"
           :class="{'image-gallery__button--disabled': this.gallery.entries.length <= 1}">
        <svg class="image-gallery__icon image-gallery__icon--prev" fill="currentColor" height="12" viewBox="0 0 8 12" width="12" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><path d="m7.41 10.59-4.58-4.59 4.58-4.59-1.41-1.41-6 6 6 6z" transform="matrix(-1 0 0 -1 8 12)"></path></svg>
      </div>
      <div class="image-gallery__button image-gallery__button--next"
           :class="{'image-gallery__button--disabled': this.gallery.entries.length <= 1}">
        <svg class="image-gallery__icon" fill="currentColor" height="12" viewBox="0 0 8 12" width="12" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><path d="m7.41 10.59-4.58-4.59 4.58-4.59-1.41-1.41-6 6 6 6z" transform="matrix(-1 0 0 -1 8 12)"></path></svg>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import BackgroundImage from './layout/BackgroundImage';
import Settings from '../config/settings';

export default {
  name: 'ImageGallery',
  components: { BackgroundImage },
  props: {
    id: {
      type: String,
      required: false
    },
    dataObj: {
      type: Object,
      default: null,
      required: false
    },
    slidesPerView: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      gallery: {
        entries: []
      },
      swiper: false,
      loaded: false,
      visible: false,
      options: {
        autoplay: {
          delay: 4000
        },
        navigation: {
          prevEl: '.image-gallery__button--prev',
          nextEl: '.image-gallery__button--next'
        },
        slidesPerView: 1,
        breakpoints: {
          480: {
            slidesPerView: this.slidesPerView
          }
        }
      }
    };
  },
  methods: {
    async fetchModuleGallery () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleGallery&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.gallery.entries = response.moduleGallery.gallery;
          this.loaded = true;
        });
    }
  },
  created () {
    if (this.id) {
      this.fetchModuleGallery();
    }
    if (this.dataObj !== null) {
      this.gallery.entries = this.dataObj.gallery;
      this.loaded = true;
    }
  }
};
</script>

<style lang="scss" scoped>
  .image-gallery {
    &__button {
      position: absolute;
      top: 50%;
      margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      &--prev {
        left: -40px;
        right: auto;
      }
      &--next {
        left: auto;
        right: -40px;
      }
      &--disabled {
        display: none;
      }
      &.swiper-button-disabled {
        opacity: 0;
        cursor: default;
      }
    }
    &__icon {
      width: 24px;
      height: 24px;
      &--prev {
        transform: rotate(180deg);
      }
    }
    &__text {
      display: inline-block;
      box-shadow: 0 0 0 $color-bg;
      transform: translate(8px, -12px);
      transition: all .3s;
      opacity: 0;
    }
  }
  .swiper-slide-active {
    .image-gallery {
      &__text {
        opacity: 1;
        transform: translate(0, -20px);
        transition: opacity .3s ease-out .4s, box-shadow .3s ease-out .6s, transform .3s ease-out .6s;
        box-shadow: 8px 8px 0 $color-bg;
      }
    }
  }
  @media all and (max-width: 1430px) {
    .image-gallery {
      &__button {
        path {
          stroke: #fff;
          stroke-width: 0.5px;
        }
        &--prev {
          left: 5px;
          right: auto;
        }
        &--next {
          left: auto;
          right: 5px;
        }
      }
    }
  }
</style>

<template>
  <div class="team-list" :class="{ 'team-list--dark': dark }">
    <div>
      <div class="pt-5 pb-5 text-white container-small"
           :class="{ 'view': !loading && view }"
           v-scroll:in="() => { view = true; }">
        <template v-for="(_team, teamI) in teams">
          <div :class="`delay--${teamI * 50}`"
               :key="`team-${teamI}`"
               class="fade-in--bottom fade-in-child">
            <div class="teams-detail__line" :class="{ 'open': _team.view }">
              <div class="row pl-2 pr-2 align-items-center justify-content-between pb-3 pt-3 teams-detail__line--click" @click="toggleTeam(_team)">
                <div class="col-10 col-md order-0">
                  <h3 class="h4 bg-primary mt-0 mb-2 mb-md-0"><span>{{ _team.name }}</span></h3>
                </div>
                <div class="col-auto text-align--center order-2 order-md-1" v-if="_team.image">
                  <img :src="_team.image.url" height="60">
                </div>
                <div class="col-auto text-align--center order-3 order-md-2" v-if="_team.manufacturer && _team.manufacturer.image">
                  <img :src="_team.manufacturer.image.url" height="40">
                </div>
                <div class="col-auto order-1 order-md-4">
                  <div class="position-relative" style="width: 20px;">
                    <svg class="results__fold__icon svg-fill-white" height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="arrow" stroke="none" stroke-width="1" fill-rule="evenodd"><g id="Icon-24px" transform="translate(8.000000, 5.500000)"><polygon id="Shape" points="7.41 11.09 2.83 6.5 7.41 1.91 6 0.5 0 6.5 6 12.5"></polygon></g></g></svg>
                  </div>
                </div>
              </div>
              <div class="row teams-detail__content">
                <div class="col pb-5">
                  <p class="pl-2 pr-2" v-html="_team.description"></p>
                  <div class="h5  pl-2 pr-2" v-html="$t('results.drivers')"></div>
                  <swiper class="team-list-slider padding--top-md-2 padding--top-4 margin--bottom-5" :ref="`team-slider-${teamI}`" :options="swiperOptions">
                    <template v-for="(driver, driverI) in _team.drivers">
                      <swiper-slide class="swiper-slide position-relative" :key="`team-slider-${teamI}-${driverI}`">
                        <div class="team-list-slider-slide position-relative">
                          <img v-if="driver.currentSeason.image || driver.image"
                               :src="driver.currentSeason.image || driver.image ? `https://media.graphassets.com/resize=w:650,fit:crop/quality=value:70/compress/${driver.currentSeason.image ? driver.currentSeason.image.handle : driver.image.handle}` : pixel"
                               width="100%">
                          <div class="team-list-slider-slide__name text-align--center">
                            <router-link :to="`/${$i18n.locale}/${$t('navigation.driver')}/${driver.slug}`">
                              <h3 class="bg-primary bg-primary--hover h5"><span>{{ driver.firstName }}<br/>{{ driver.lastName }}</span></h3>
                            </router-link>
                          </div>
                        </div>
                      </swiper-slide>
                    </template>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';
import WigeDataModel from '../../entity/WigeDataModel';

export default {
  name: 'TeamList',
  props: {
    dark: { type: Boolean, required: false, default: true },
    raceSeries: { type: String, required: false, default: 'DTM' }
  },
  data () {
    return {
      currentYear: 0,
      loading: true,
      wigeData: null,
      event: null,
      view: false,
      drivers: [],
      teams: [],
      pixel: AppConst.PIXEL,
      swiperOptions: {
        initialSlide: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        followFinger: false,
        longSwipes: false,
        slideToClickedSlide: true
      }
    };
  },
  methods: {
    async fetchDrivers () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=drivers',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          const year = new Date().getFullYear();
          // const year = 2023; // TODO: Change to current year
          const drivers = [];
          const teams = [];
          response.drivers.forEach((driver) => {
            driver.view = false;
            let age = 0;
            if (driver.appBirth) {
              const split = driver.appBirth.split('.');
              const birthDay = new Date(split[2], split[1] - 1, split[0]);
              const ageDifMs = Date.now() - birthDay.getTime();
              const ageDate = new Date(ageDifMs); // miliseconds from epoch
              age = Math.abs(ageDate.getUTCFullYear() - 1970);
            }
            driver.age = age;
            if (driver.webVisibility) {
              const season = driver.driverSeasons.find((driverSeason) => {
                if (driverSeason.raceSeries.toUpperCase() === this.raceSeries.toUpperCase() && driverSeason.season === year) {
                  return true;
                }
                return false;
              });
              if (season) {
                driver.currentSeason = season;
                drivers.push(Object.assign(driver, season));
                if (driver.team) {
                  let existingTeam = teams.find((t) => t.name === driver.team.name);
                  if (!existingTeam) {
                    existingTeam = Object.assign({}, driver.team);
                    existingTeam.key = name;
                    existingTeam.view = false;
                    existingTeam.drivers = [];
                    teams.push(existingTeam);
                  }
                  existingTeam.drivers.push(driver);
                }
              }
            }
          });
          this.drivers = drivers;
          this.wigeData.parseDriversResponse(this.drivers);
          this.teams = teams.sort((a, b) => a.name.localeCompare(b.name));
          this.$nextTick(() => {
            setTimeout(() => {
              this.loading = false;
            }, 100);
          });
        });
    },
    shuffle (a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    toggleTeam (team) {
      if (team.view) {
        team.view = false;
      } else {
        this.teams.forEach((t) => {
          t.view = false;
        });
        team.view = true;
      }
    }
  },
  mounted () {
    this.wigeData = new WigeDataModel();
    this.fetchDrivers();
  }
};
</script>

<style lang="scss">
.teams-detail {
  &__content {
    overflow: hidden;
    max-height: 0;
    transition: all .3s $ease-out-cubic;
    h3 {
      margin-left: $gap * 2;
      max-width: 80%;
    }
  }
  &__line {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: all .3s;
    &--click {
      cursor: pointer;
      transition: all .3s;
    }
    .results__fold__icon {
      right: 0;
      top: -12px;
    }
    &:hover {
      .teams-detail__line--click .h5 {
        color: $color-primary;
      }
    }
    &.open {
      .teams-detail__content {
        max-height: 500px;
        transition: all .3s $ease-in-cubic;
      }
      .results__fold__icon {
        transform: rotate(90deg);
      }
    }
  }
}
.team-list-slider {
  $root: &;
  &-slide {
    transition: all .3s;
    opacity: .6;
    transform: scale(0.5);
    &__name {
      transform: translate(0, -1.8em);
      h3 {
        font-size: 1.2em;
      }
    }
    &--card {
      background: rgba($color-bg-dark, 0.3);
      padding: $gap;
    }
    &__favorite {
      display: inline-block;
      position: relative;
      border-radius: 100%;
      width: 45px;
      height: 45px;
      border: 1px solid rgba($color-primary, .5);
      transition: all .3s;
      cursor: pointer;
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%) scale(0.9);
        transition: all .3s;
        path, use {
          fill: rgba($color-primary, 0);
          stroke: $color-primary;
          stroke-width: 1;
          transition: all .3s;
        }
      }
      &:hover {
        border: 1px solid rgba($color-primary, 1);
        svg {
          transform: translate(-50%, -50%) scale(1);
          path, use {
            stroke-width: 2;
          }
        }
      }
      &.active {
        border: 1px solid rgba($color-primary, 1);
        box-shadow: 0 0 15px rgba($color-primary, 0.6);
        svg {
          transform: translate(-50%, -50%) scale(1);
          path, use {
            fill: rgba($color-primary, 1);
            stroke-width: 1;
          }
        }
      }
    }
  }
  .swiper-slide {
    width: 200px;
    max-width: 40vw;
    &-active {
      #{$root}-slide {
        opacity: 1;
        transform: scale(1);
      }
    }
    &-next, &-prev {
      #{$root}-slide {
        transform: scale(.7);
        opacity: .6;
      }
    }
  }
  &__static-image {
    height: 120px;
  }
}
</style>

<template>
  <div class="mydtm-preferences">
    <div class="position-relative" v-if="data">
      <div class="padding--top-6 padding--bottom-6"
           :class="{ 'view': visible }"
           v-scroll:in="() => { visible = true; loaded = true }"
           v-scroll:out="() => visible = false">
        <div class="container-fluid">
          <div class="row no-gutters mydtm-preferences__row" v-if="userPreference && drivers.length && loadedPreferences">
            <div class="col-12 text-align--center">
              <h3 class="bg-primary h1-sm"><span>{{ data.subHeadline }}</span></h3>
            </div>
            <template v-for="question in userPreference.userPreferenceQuestions">
              <div class="col-12" :key="`question-${question.key}`">
                <div class="fade-in-child fade-in--bottom delay--100 text-align--center" v-if="data.headline">
                  <h4><span class="text-uppercase">{{ question.headline }}</span></h4>
                </div>
                <!-- Favourite Driver -->
                <preference-entry v-if="question.key === 1" :question="question" @update-answer="updateAnswer"/>
                <!-- Favourite Team -->
                <preference-entry v-if="question.key === 2" :question="question" @update-answer="updateAnswer"/>
                <!-- Favourite Manufacturer -->
                <preference-entry v-if="question.key === 3" :question="question" @update-answer="updateAnswer"/>
                <!-- Favourite RaceSeries -->
                <preference-entry v-if="question.key === 4" :question="question" @update-answer="updateAnswer"/>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="userPreference && drivers.length && loadedPreferences" class="padding--bottom-4">
        <div class="row justify-content-center padding--bottom-1">
          <div class="col-auto">
            <div :class="{'mydtm-preferences__alert--active': showAlert}" class="mydtm-preferences__alert" role="alert">
              {{ $t('mydtm.preferencesAlert') }}
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto">
            <button type="button" class="button-primary primary" @click="savePreferences">{{ $t('mydtm.preferencesSave') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AppConst from '@/config/app.const';
import Settings from '@/config/settings';
import Axios from 'axios';
import PreferenceEntry from '@/components/crm/PreferenceEntry';

export default {
  name: 'MydtmPreferences',
  components: { PreferenceEntry },
  props: {
    id: String,
    data: {
      type: Object,
      default: null,
      required: false
    },
    year: {
      type: Number,
      required: false,
      default: new Date().getFullYear()
    }
  },
  data () {
    return {
      loaded: false,
      loadedPreferences: false,
      visible: false,
      preferenceKey: 'MYDTM_PREFERENCE',
      changes: false,
      saving: false,
      showAlert: false,
      singleText: [],
      userPreference: null,
      dataPreferences: {
        userId: this.$msal.userId,
        tid: this.$msal.accessToken,
        preferences: []
      },
      drivers: [],
      teams: [],
      manufacturers: [],
      swiper: this.$store.getters['adb2c/preferenceSwiper']
    };
  },
  computed: {
    lineContent: function () {
      return this.$store.getters['page/raceSeries'] === 'dtm-trophy';
    },
    isAuthenticated () {
      return this.$msal.isAuthenticated;
    },
    username () {
      return this.$msal.username;
    },
    savedPreferences: function () {
      return this.$store.getters['adb2c/userPreferences'];
    }
  },
  methods: {
    updateAnswer (event) {
      const question = event.question;
      const answer = event.tag;
      const answerExists = question.tags.find(t => t.id === answer.id);
      if (answerExists) {
        if (question.currentAnswer !== answer.id) {
          question.currentAnswer = answer.id;
          question.crmId = answer.crmId || '';
        } else {
          question.currentAnswer = '';
          question.crmId = '';
        }
        this.changes = true;
      }
    },
    async fetchPreferences () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=userPreference&key=${this.preferenceKey}`,
        lifetime: 0
      })
        .then(response => {
          if (response.userPreferences) {
            // loooooop through preferences
            response.userPreferences[0].userPreferenceQuestions.forEach((question) => {
              question.savedAnswer = this.savedPreferences[`${this.preferenceKey}.${question.key}`] ? this.savedPreferences[`${this.preferenceKey}.${question.key}`].tagId : '';
              question.currentAnswer = this.savedPreferences[`${this.preferenceKey}.${question.key}`] ? this.savedPreferences[`${this.preferenceKey}.${question.key}`].tagId : '';
              if (question.currentAnswer) {
                question.crmId = this.savedPreferences[`${this.preferenceKey}.${question.key}`].crmId || '';
              }
              let initial = 0;
              // we know the keys
              if (question.key === 1) {
                let driverCount = 0;
                // favorite driver
                question.tags.forEach((tag) => {
                  this.drivers.forEach((driver) => {
                    if (driver.tags.length) {
                      const tagFound = driver.tags.find(t => t.id === tag.id);
                      if (tagFound) {
                        tag.driver = driver;
                        tag.hasImage = true;
                        tag.hasStaticImage = false;
                        tag.imageObject = driver;
                        if (tagFound.id === question.currentAnswer) {
                          initial = driverCount;
                        }
                      }
                    }
                  });
                  driverCount++;
                });
              }
              if (question.key === 2) {
                // favorite team
                let teamCount = 0;
                question.tags.forEach((tag) => {
                  this.teams.forEach((team) => {
                    if (team.tags.length) {
                      const tagFound = team.tags.find(t => t.id === tag.id);
                      if (tagFound) {
                        tag.team = team;
                        tag.hasImage = true;
                        tag.hasStaticImage = false;
                        tag.imageObject = team;
                        if (tagFound.id === question.currentAnswer) {
                          initial = teamCount;
                        }
                      }
                    }
                  });
                  teamCount++;
                });
              }
              if (question.key === 3) {
                // favorite manufacturer
                let manufacturerCount = 0;
                question.tags.forEach((tag) => {
                  this.manufacturers.forEach((manufacturer) => {
                    if (manufacturer.tags.length) {
                      const tagFound = manufacturer.tags.find(t => t.id === tag.id);
                      if (tagFound) {
                        tag.manufacturer = manufacturer;
                        tag.hasImage = true;
                        tag.hasStaticImage = false;
                        tag.imageObject = manufacturer;
                        if (tagFound.id === question.currentAnswer) {
                          initial = manufacturerCount;
                        }
                      }
                    }
                  });
                  manufacturerCount++;
                });
              }

              if (question.key === 4) {
                // favorite raceSeries
                let raceSeriesCount = 0;
                question.tags.forEach((tag) => {
                  tag.hasImage = false;
                  tag.hasStaticImage = true;
                  tag.imageObject = null;
                  if (tag.id === question.currentAnswer) {
                    initial = raceSeriesCount;
                  }
                  raceSeriesCount++;
                });
              }
              if (this.swiper['question' + question.key]) {
                this.swiper['question' + question.key].initialSlide = initial;
              }
            });
            this.userPreference = response.userPreferences[0];
            this.loadedPreferences = true;
          }
        }).catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    async fetchDrivers () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=driversShort',
        lifetime: Settings.LIFETIME_DEFAULT
      }).then(response => {
        if (response.drivers) {
          this.drivers = response.drivers;
          const teams = [];
          const manufacturers = [];
          this.drivers.forEach((driver) => {
            const driverSeason = driver.driverSeasons.find((driverSeasonData) => {
              return driverSeasonData.season === this.year;
            });
            if (driverSeason) {
              this.drivers.push(Object.assign(driver, driverSeason));
            }
            if (driver.team) {
              const existingTeam = teams.find(team => team.name === driver.team.name);
              if (!existingTeam) {
                teams.push(driver.team);
              }
              if (driver.team.manufacturer) {
                const existingManufacturer = manufacturers.find(manufacturer => manufacturer.name === driver.team.manufacturer.name);
                if (!existingManufacturer) {
                  manufacturers.push(driver.team.manufacturer);
                }
              }
            }
          });
          this.teams = teams;
          this.manufacturers = manufacturers;
        }
      });
    },
    async savePreferences () {
      const data = {
        userId: this.$msal.userId,
        tid: this.$msal.accessToken,
        preferences: []
      };
      this.userPreference.userPreferenceQuestions.forEach(question => {
        if (question.savedAnswer !== question.currentAnswer) {
          const preference = {
            userPreference: this.userPreference.key,
            userPreferenceQuestion: question.key,
            tagId: question.currentAnswer,
            crmId: question.crmId
          };
          const savedPreferenceKey = `${this.userPreference.key}.${question.key}`;
          if (this.savedPreferences && savedPreferenceKey in this.savedPreferences) {
            preference.id = this.savedPreferences[savedPreferenceKey].id;
          }
          // preference.id = if user already had preference saved
          data.preferences.push(preference);
        }
      });
      if (!this.saving) {
        this.saving = true;
        Axios.post(`${Settings.nodeApi.uri}sendPreference`, data, {
        }).then((res) => {
          this.saving = false;
          this.handleAlert();
        }).catch((e) => {
          this.saving = false;
          this.handleAlert();
        });
      }
    },
    handleAlert () {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 4000);
    }
  },
  watch: {
    '$msal.isAuthenticated': function () {
      if (this.page && this.page.gated && !this.$msal.isAuthenticated) {
        this.$router.push(`/${this.$i18n.locale}/mydtm`);
      }
    }
  },
  created () {
    if (!this.$msal.isAuthenticated) {
      this.$router.push(`/${this.$i18n.locale}/mydtm`);
    } else {
      if (this.data && this.data.singleText) {
        const singleText = [];
        this.data.singleText.forEach((line) => {
          let icon = '';
          let text = line;
          if (text.substr(0, 4) === 'icon') {
            icon = line.substr(0, line.indexOf(';'));
            text = line.substr(line.indexOf(';') + 1);
          }
          singleText.push({ icon: icon, text: text });
        });
        this.singleText = singleText;
        this.$store.dispatch('adb2c/fetchUserPreferences', { userId: this.$msal.userId }).then(() => this.fetchDrivers().then(() => this.fetchPreferences()));
      }
    }
  }
};
</script>

<style lang="scss">
.mydtm-preferences {
  position: relative;
  background: $color-bg;
  color: #fff;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  min-height: 60vh;

  &__row {
    margin: 0 -$gap;
  }

  &__alert {
    opacity: 0;
    transition: opacity 0.2s;
    color: $color-primary;
    &--active {
      opacity: 1;
    }
  }
}
</style>

<template>
  <div class="future-stage-footer text-white">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex justify-content-center future-stage-footer__headline">
          <h4 v-text="$t('footer.followUs')" />
        </div>
        <div class="col-12 d-flex justify-content-center">
          <ul class="d-inline-flex future-stage-footer__items">
            <li class="col future-stage-footer__item" v-for="(item, index) in socialItems" :key="index">
              <a :href="item.link.url" target="_blank">
                <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
                  <rect width="2902.7" height="2902.7" :mask="'url(#icon-' + item.name.toLowerCase() + ')'"></rect>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="future-stage-footer__hashtags col-12 d-flex justify-content-center">
          <h4>#DTM2021 #DTMFuture #TomorrowIsNow</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../../config/app.const';
import Settings from '../../config/settings';

export default {
  name: 'FutureStageFooter',
  data () {
    return {
      socialItems: [],
      retryMax: 1,
      retryCount: 0
    };
  },
  methods: {
    async fetchFooterSocial () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=socialChannels&raceSeries=DTM',
        lifetime: Settings.LIFETIME_STATIC
      })
        .then(response => {
          this.socialItems = response.socialchannels;
        })
        .catch(() => {
          if (this.retryCount < this.retryMax) {
            this.retryCount++;
            setTimeout(this.fetchFooterSocial, 3000);
          }
        });
    }
  },
  watch: {
    $route () {
      this.fetchFooterSocial();
    }
  },
  created () {
    this.fetchFooterSocial();
  }
};
</script>

<style lang="scss">
  .future-stage-footer {
    background: $color-bg-future-stage;
    min-height: 300px;

    &__headline {
      margin-top: 60px;
      font-style: italic;
    }

    &__items {
      list-style: none;
      padding: 0;
      margin-top: 25px;
      margin-bottom: 0;
    }

    &__item {
      svg {
        width: 40px;
        height: 40px;
        rect {
          fill: #fff;
        }
      }

      a {
        &:hover {
          svg rect {
            fill: $color-primary;
          }
        }
      }
    }

    &__hashtags {
      margin-top: 60px;
      text-align: center;
      font-style: italic;
    }
  }
</style>

<template>
  <div class="race-timeline position-relative"
       v-scroll:in="() => { visible = true; wasVisible = true; }"
       v-scroll:out="() => visible = false">
    <div class="container-fluid padding--top-4 padding--bottom-8">
      <h2 class="margin--bottom-4 text-italic primary">{{ $t('raceCalendar.title')}}</h2>
      <div v-if="!loading">
        <div class="race-timeline__container">
          <div class="row no-gutters">
            <div v-for="(month, index) in dates.range"
                 :key="'range-title-' + index"
                 :class="{ 'd-none d-md-flex': !month.events.length }"
                 class="col">
              <div v-if="index !== 0" class="font-small text-uppercase text-condensed">{{ $d(month.date, 'month') }}</div>
            </div>
          </div>
          <div class="row no-gutters race-timeline__line" :class="{ 'view': visible }">
            <div v-for="(month, index) in dates.range"
                 :key="'range-' + index"
                 :class="{ 'd-none d-md-flex': !month.events.length }"
                 class="col race-timeline__month">
              <div v-for="event in month.events"
                   class="race-timeline__dot"
                   @click="switchSelected(event.countIndex)"
                   :key="'range-dot-' + event.slug"
                   :class="{ 'race-timeline__dot--active': selected === event.countIndex, 'd-none': !event.raceSeries.includes(raceSeries) }"
                   :style="{ 'left': (event.startDate.getDate() * 100 / 31) + '%', 'transition-delay': (200 + event.countIndex * 30) + 'ms' }"
                   :title="$d(event.startDate, 'dayAndDate')"
              ><div></div></div>
            </div>
          </div>
        </div>
        <div class="row margin--top-4" :class="{ 'view': !changeSelected && visible }">
          <div class="col-12 col-md-6">
            <div class="position-relative bg-dark overflow--hidden padding-2 padding-md-3 fade-in-child box-shadow margin--bottom-2 text-white" :class="changeSelectedClass">
              <background-image
                :full="true"
                :loaded="wasVisible"
                :overlay-color="'bg'"
                :url="`https://media.graphassets.com/resize=w:1200,fit:crop/output=format:jpg/quality=value:70/blur=amount:1/compress/${events[selected].tileImage.handle}`"></background-image>
              <div class="position-relative">
                <div v-if="!events[selected].hideEventDate" class="h2 text-italic text-primary">
                  <span class="text-nowrap">{{ displayDays(events[selected].startDate, events[selected].endDate) }}</span>&nbsp;
                  <span class="text-nowrap">{{ displayMonths(events[selected].startDate, events[selected].endDate) }}</span>
                </div>
                <div class="h4 margin--top-2 margin--bottom-1 text-condensed text-italic">{{ events[selected].name }}</div>
                <svg viewBox="0 0 85 48" width="60">
                  <use :href="'#flag-' + events[selected].country.countryCode" />
                </svg>
                <div class="text-align--center" v-if="events[selected].racetrack">
                  <img :src="events[selected].racetrack.circuit.url" class="race-timeline__image" :title="events[selected].name">
                </div>
                <div class="row align-items-end margin--top-2">
                  <div v-if="events[selected].ticketUrl" class="col-auto">
                    <a :href="events[selected].ticketUrl" target="_blank" class="button-primary">{{ $t('raceCalendar.tickets') }}</a>
                  </div>
                  <div class="col text-align--right">
                    <router-link class="link--more" :to="{ name: $t('navigation.eventsDetail'), params: { lang: $i18n.locale, slug: events[selected].slug} }">
                      {{ $t('raceCalendar.moreInfo') }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 height-min-100">
            <div class="row height-min-100 flex-column">
              <div class="col">
                <div class=" padding--bottom-2">
                  <div class="box-shadow fade-in-child" :class="changeSelectedClass" v-if="events[selected].videoId && events[selected].videoId !== null">
                    <div class="position-relative overflow--hidden">
                      <youtube :video-id="events[selected].videoId"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="height-min-100">
                  <div class="box-shadow bg-default fade-in-child padding-2 padding-md-3 text-white" :class="changeSelectedClass" v-if="events[selected].racetrackEvents && events[selected].racetrackEvents.trackinformations.length">
                    <div class="row">
                      <div class="col-6 padding--bottom-0" :class="{ 'padding--bottom-sm-2': infoIndex < 2 }" v-for="(info, infoIndex) in events[selected].racetrackEvents.trackinformations" :key="'info-' + info.headline">
                        <div class="text-uppercase text-condensed text-italic font-weight--bold">{{ info.headline }}</div>
                        <h2 class="primary text-italic margin-0">{{ info.value }}</h2>
                        <div class="font-small">{{ info.subline }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <break-after></break-after>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import BreakAfter from './layout/BreakAfter';
import BackgroundImage from './layout/BackgroundImage';
import Youtube from './Youtube';
import Settings from '../config/settings';

export default {
  name: 'RaceTimeline',
  components: { Youtube, BackgroundImage, BreakAfter },
  props: {
    year: String
  },
  data () {
    return {
      loading: true,
      visible: false,
      detailsVisible: false,
      wasVisible: false,
      dates: {
        first: null,
        last: null,
        range: [],
        today: new Date()
      },
      events: [],
      selected: -1,
      changeSelected: true,
      changeSelectedClass: 'fade-in--right'
    };
  },
  computed: {
    raceSeries: function () {
      return this.$store.getters['page/raceSeries'][0];
    }
  },
  methods: {
    displayDays: function (start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return ('00' + startDate.getDate()).slice(-2) + '.' + (startDate.getDate() !== endDate.getDate() ? ' - ' + ('00' + endDate.getDate()).slice(-2) + '.' : '');
    },
    displayMonths: function (start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return (this.$i18n.d(startDate, 'monthLong') + (startDate.getMonth() !== endDate.getMonth() ? ' / ' + this.$i18n.d(endDate, 'monthLong') : ''));
    },
    switchSelected: function (val) {
      if (this.changeSelected) { return false; }
      this.changeSelected = true;
      setTimeout(() => {
        this.changeSelectedClass = 'fade-in--left';
      }, 500);
      return setTimeout(() => {
        this.selected = val;
        this.changeSelected = false;
        setTimeout(() => { this.changeSelectedClass = 'fade-in--right'; }, 500);
      }, 1000);
    },
    async fetchEvents () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=eventsRacetrack',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          let nextEvent = -1;
          const events = response.events;
          const now = new Date();
          const firstInYear = new Date(this.year ? parseInt(this.year) : now.getFullYear(), 0, 1);
          const lastInYear = new Date(this.year ? parseInt(this.year) : now.getFullYear(), 11, 31);
          const today = now.getFullYear() + ('00' + (now.getMonth() + 1)).slice(-2) + ('00' + now.getDate()).slice(-2);
          events.forEach((event, index) => {
            event.startDate = new Date(event.startTime);
            event.endDate = new Date(event.endTime);
            if (event.startDate < firstInYear || event.endDate > lastInYear) {
              // we dont need this event
            } else {
              event.countIndex = index;
              event.start = event.startDate.getFullYear() + ('00' + (event.startDate.getMonth() + 1)).slice(-2) + ('00' + event.startDate.getDate()).slice(-2);
              event.end = event.endDate.getFullYear() + ('00' + (event.endDate.getMonth() + 1)).slice(-2) + ('00' + event.endDate.getDate()).slice(-2);
              // set first race date this year
              if (!this.dates.first || (this.dates.first && this.dates.first > event.startDate)) {
                this.dates.first = event.startDate;
              }
              // set last race date this year
              if (!this.dates.last || (this.dates.last && this.dates.last < event.startDate)) {
                this.dates.last = event.startDate;
              }
              if (event.raceSeries.includes(this.raceSeries)) {
                if (nextEvent < 0 && event.start > today) {
                  nextEvent = index;
                }
                if (today >= event.start && today <= event.end) {
                  nextEvent = index;
                }
              }
            }
          });
          if (nextEvent < 0) {
            nextEvent = events.length - 1;
          }
          for (let i = this.dates.first.getMonth() - 2; i < 12; i++) {
            const tmpDate = new Date(this.dates.first.getFullYear(), i, 1);
            const month = {
              date: tmpDate,
              // name: this.$i18n.d('month', tmpDate),
              events: []
            };
            events.forEach(event => {
              if (event.startDate.getMonth() === tmpDate.getMonth() && event.startDate.getFullYear() === tmpDate.getFullYear()) {
                month.events.push(event);
              }
            });
            this.dates.range.push(month);
          }
          this.events = response.events;
          this.selected = nextEvent;
          this.changeSelected = false;
          this.loading = false;
        });
    }
  },
  watch: {
    $route () {
      this.loading = true;
      this.events = [];
      this.selected = -1;
      this.dates.range = [];
      this.fetchEvents();
    }
  },
  created () {
    this.fetchEvents();
  }
};
</script>

<style lang="scss">
  .race-timeline {
    $root: &;
    &__image {
      height: 385px;
      max-width: 100%;
    }
    &__line {
      position: relative;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        top: 50%;
        background: rgba(0, 0, 0, 0.3);
        background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
        transition: all .5s $ease-out-cubic;
      }
    }
    &__month {
      position: relative;
      height: 16px;
    }
    &__dot {
      position: absolute;
      left: 0;
      top: 50%;
      cursor: pointer;
      transform: translate(-120%, -50%);
      opacity: 0;
      padding: 6px;
      transition: all .4s $ease-out-cubic;
      z-index: +1;
      div {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #333;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 6px rgba(0, 0, 0, 0.4);
        transition: all .4s $ease-out-back;
      }
      &:hover {
        div {
          box-shadow: 0 0 0 3px rgba($color-primary, 1), 0 0 10px rgba(0, 0, 0, 0.4);
        }
      }
      &--active {
        div {
          background: $color-primary;
        }
      }
    }
    .view {
      #{$root}__dot {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
    &--dtm-trophy {
      #{$root}__line:before {
        background: rgba($color-bg, 0.3);
        background: linear-gradient(90deg, rgba($color-bg, 0), rgba($color-bg, 0.4));
      }
      #{$root}__dot {
        div {
          background: $color-bg;
        }
        &:hover {
          div {
            box-shadow: 0 0 0 3px rgba(#fff, 1), 0 0 10px rgba(0, 0, 0, 0.4);
          }
        }
        &--active {
          div {
            background: #fff;
          }
        }
      }
    }
  }
  @media (max-width: map_get($grid-breakpoints, 'md')) {
    .race-timeline {
      &__image {
        height: auto;
        width: 80%;
      }
      &__line {
        &:before {
          left: -$gap * 2;
          right: -$gap * 2;
          background: rgba(0, 0, 0, 0.3);
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
        }
      }
      &__dot {
        div {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
</style>

<template>
  <div class="autohero-searchslider mt-5 mb-5 pt-5 pb-5">
    <div ref="autohero" id="ah-search-slider"></div>
  </div>
</template>

<script>
export default {
  name: 'AutoheroSearchslider',
  props: {
    id: String,
    data: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {};
  },
  mounted () {
    let scriptExists = false;
    if (this.data.configData && this.data.configData.script) {
      document.head.querySelectorAll('script').forEach((script) => {
        if (script.src === this.data.configData.script) {
          scriptExists = true;
        }
      });
      if (!scriptExists) {
        const newScript = document.createElement('script');
        newScript.src = this.data.configData.script;
        newScript.setAttribute('defer', true);
        document.head.appendChild(newScript);
      }
    }
    const interval = setInterval(() => {
      if (this.$refs.autohero && 'AutoheroSearchSlider' in window) {
        clearInterval(interval);
        window.AutoheroSearchSlider.init(this.$refs.autohero);
      }
    }, 50);
  }
};
</script>

<style scoped>
.autohero-searchslider {
  background: #fff;
  /*position: relative;*/
  /*left: 50%;*/
  /*width: 100vw;*/
  /*transform: translate(-50vw, 0);*/
}
</style>

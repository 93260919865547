<template>
  <div class="driver-slider margin--bottom-8 position-relative">
    <div class="position-relative padding--top-4">
    <div class="container-fluid">
        <h2 class="text-italic primary">{{ $t('driverSlider.title') }}</h2>
      </div>
      <div class="driver-slider__driver position-relative overflow-hidden" :style="{ 'display': noImages ? 'none' : 'block' }">
        <div class="container-fluid">
          <swiper class="driver-slider__swiper driver-slider__top-slider padding--top-md-8 padding--top-4" v-if="drivers.length" :ref="'driversSwiper'" :options="driversSwiperOptions">
            <swiper-slide v-for="(driver, count) in drivers" :key="'driver-slider-' + count + 'images'">
              <div class="driver-slider__swiper-slide position-relative" @click="slideTo(count)">
                <img :src="driver.image ? `https://media.graphassets.com/resize=w:650,fit:crop/quality=value:70/compress/${driver.image.handle}` : pixel" width="100%" :alt="driver.imageAlt" :title="driver.imageTitle" class="driver-slider__invisible-offset">
                <div class="driver-slider__swiper-image">
                  <img :src="driver.image ? `https://media.graphassets.com/resize=w:650,fit:crop/quality=value:70/compress/${driver.image.handle}` : pixel" width="100%" :alt="driver.imageAlt" :title="driver.imageTitle">
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <svg width="100%" viewBox="0 0 1440 100" class="driver-slider__svg">
          <defs>
            <clipPath id="driver-slider__clip" clipPathUnits="userSpaceOnUse">
              <path ref="clipPath" d="M-20,80 1460,20 1460,100 -20,100z"></path>
            </clipPath>
          </defs>
          <g filter="url(#shadowDefault)" opacity="0.4">
            <rect x="-5%" width="110%" height="100%" clip-path="url(#driver-slider__clip)" class="svg-fill-default"></rect>
          </g>
          <rect x="-5%" width="110%" height="100%" clip-path="url(#driver-slider__clip)" class="svg-fill-default"></rect>
        </svg>
      </div>
      <div class="position-relative padding--top-2 padding--top-md-4 padding--bottom-4 bg-default">
        <div class="driver-slider__content container-fluid">
          <swiper class="driver-slider__swiper" v-if="drivers.length" :ref="'detailSwiper'" :options="detailSwiperOptions">
            <!-- Slides -->
            <swiper-slide v-for="(driver, count) in drivers" :key="'driver-slider-' + count">
              <div class="driver-slider__swiper-slide">
                <div class="row">
                  <div class="col-12 col-sm-5 col-md-4">
                    <div class="padding--left-1 padding--left-sm-3 margin--bottom-2">
                      <!-- TODO add additional ranking field here -->
                      <router-link
                        :to="`/${$i18n.locale}/${$t('navigation.driver')}/${driver.slug}`">
                        <div class="h3 h2-xs bg-primary bg-primary--hover"><span>{{ driver.firstName }}<br/>{{ driver.lastName }}</span></div>
                      </router-link>
                      <div v-if="driver.appDriverSocialMedia" class="margin--top-2">
                        <a :href="driver.appDriverSocialMedia.instagramUrl" v-if="driver.appDriverSocialMedia.instagramUrl" target="_blank"
                           class="driver-slider__social-media d-inline-block margin--right-1">
                          <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
                            <rect width="2902.7" height="2902.7" :mask="'url(#icon-instagram)'"></rect>
                          </svg>
                        </a>
                        <a :href="driver.appDriverSocialMedia.facebookUrl" v-if="driver.appDriverSocialMedia.facebookUrl" target="_blank"
                           class="driver-slider__social-media d-inline-block margin--right-1">
                          <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
                            <rect width="2902.7" height="2902.7" :mask="'url(#icon-facebook)'"></rect>
                          </svg>
                        </a>
                        <a :href="driver.appDriverSocialMedia.twitterUrl" v-if="driver.appDriverSocialMedia.twitterUrl" target="_blank"
                           class="driver-slider__social-media d-inline-block margin--right-1">
                          <svg width="32" height="32" viewBox="0 0 2902.7 2902.7" xmlns="http://www.w3.org/2000/svg">
                            <rect width="2902.7" height="2902.7" :mask="'url(#icon-twitter)'"></rect>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class=" padding--right-2">
                      <div class="row justify-content-between">
                        <div class="col-6 col-md-auto margin--bottom-2">
                          <div class="h3 text-italic text-primary">{{ driver.age ? driver.age : driver.driverThreeLetterCode }}</div>
                          <b class="text-condensed text-uppercase">{{ driver.age ? $t('driverSlider.age') : $t('driverSlider.short') }}</b>
                        </div>
                        <div class="col-12 col-md-auto margin--bottom-2" v-if="driver.country">
                          <div class="h3 text-italic text-primary">{{ driver.country.name.length > 12 ? driver.country.name : driver.country.name }}</div>
                          <b class="text-condensed text-uppercase">{{ $t('driverSlider.country') }}</b>
                        </div>
                        <div v-if="driver.driverNumber" class="col-12 col-md-auto margin--bottom-2">
                          <div class="h3 text-italic text-primary">{{ driver.driverNumber }}</div>
                          <b class="text-condensed text-uppercase">{{ $t('driverSlider.number') }}</b>
                        </div>
                        <div v-if="driver.appDtmDebut" class="col-12 col-md-auto margin--bottom-2">
                          <div class="h3 text-italic text-primary">{{ driver.appDtmDebut }}</div>
                          <b class="text-condensed text-uppercase">{{ $t('driverSlider.debut') }}</b>
                        </div>
                      </div>
                      <div class="margin--bottom-2" v-if="driver.team">
                        <div class="h4 text-italic text-primary">
                          <img :src="driver.team.manufacturer.image.url" height="30" v-if="driver.team && driver.team.manufacturer && driver.team.manufacturer.image" style="transform: translate(0, 5px)">
                          {{ driver.team.name }}
                        </div>
                      </div>
                      <div class="margin--bottom-2" v-if="driver.teamTrophy">
                        <div class="h4 text-italic text-primary">
                          <img :src="driver.manufacturerTrophy.image.url" height="30" v-if="driver.manufacturerTrophy && driver.manufacturerTrophy.image" style="transform: translate(0, 5px)">
                          {{ driver.teamTrophy.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
    <break-after></break-after>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import BreakAfter from './layout/BreakAfter';
import Settings from '../config/settings';

export default {
  name: 'DriverSlider',
  components: { BreakAfter },
  props: {
    noImages: {
      type: Boolean,
      required: false,
      default: false
    },
    year: {
      type: Number,
      required: false,
      default: new Date().getFullYear()
    },
    raceSeries: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      drivers: [],
      driversSwiper: false,
      driversSwiperOptions: {
        initialSlide: 2,
        slidesPerView: 3,
        centeredSlides: true,
        loop: false,
        // slideToClickedSlide: true,
        shortSwipes: false,
        longSwipes: false,
        followFinger: false,
        breakpoints: {
          500: {
            slidesPerView: 5
          }
        }
      },
      detailSwiper: false,
      detailSwiperOptions: {
        loop: true,
        initialSlide: 2,
        on: {
          slideChange: () => {
            this.$refs.driversSwiper.$swiper.slideToLoop(this.$refs.detailSwiper.$swiper.realIndex);
          }
        }
      },
      pixel: AppConst.PIXEL
    };
  },
  methods: {
    async fetchDrivers () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: 'data?query=drivers',
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          const drivers = [];
          response.drivers.forEach((driver) => {
            let age = 0;
            if (driver.appBirth) {
              const split = driver.appBirth.split('.');
              const birthDay = new Date(split[2], split[1] - 1, split[0]);
              const ageDifMs = Date.now() - birthDay.getTime();
              const ageDate = new Date(ageDifMs); // miliseconds from epoch
              age = Math.abs(ageDate.getUTCFullYear() - 1970);
            }
            driver.age = age;
            if (driver.webVisibility) {
              const season = driver.driverSeasons.find((driverSeason) => {
                if (driverSeason.raceSeries.toUpperCase() === this.raceSeries.toUpperCase() && driverSeason.season === this.year) {
                  return true;
                }
                return false;
              });
              if (season) {
                drivers.push(Object.assign(driver, season));
              }
            }
          });
          this.drivers = this.shuffle(drivers);
        });
    },
    slideTo (index) {
      this.$refs.detailSwiper.$swiper.slideToLoop(index);
    },
    shuffle (a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    }
  },
  watch: {
    $route () {
      this.fetchDrivers();
    }
  },
  created () {
    this.fetchDrivers();
  }
};
</script>

<style lang="scss">
  .driver-slider {
    $root: &;
    &__svg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 11;
    }

    &__swiper-image {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 140%;
      transform: translate(-50%, 0);
      transition: all .6s $ease-out-quart;
      opacity: 0.4;
    }

    &__invisible-offset {
      opacity: 0;
      padding-top: 60%;
    }

    .swiper-slide-prev, .swiper-slide-next {
      #{$root}__swiper-image {
        z-index: +1;
        opacity: 0.6;
        width: 160%;
      }
    }

    &__social-media {
      rect {
        fill: #fff;
        transition: all .3s;
      }
      &:hover rect {
        fill: $color-primary;
      }
      &--dtm-trophy {
        rect {
          fill: $color-bg;
        }
        &:hover {
          rect {
            fill: $color-bg-dark;
          }
        }
      }
    }

    &__top-slider {
      .swiper-slide-active {
        pointer-events: none;

        #{$root}__swiper-image {
          width: 200%;
          z-index: +2;
          opacity: 1;
        }
      }
    }
  }
</style>

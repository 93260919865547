<template>
  <div class="image-text" v-if="imageTextData">
    <div class="row padding--top-4 padding--bottom-4" v-if="imageTextData.alignImage === 'Right'">
      <div
        class="col-12 col-sm-6"
        :class="{ 'view': this.textVisible }"
        v-scroll:in="() => { this.textVisible = true; this.loaded = true }"
        v-scroll:out="() => this.textVisible = false">
        <div class="fade-in-child fade-in--left" v-html="imageTextData.text.html"/>
      </div>
      <div
        class="col-12 col-sm-6 bgimg--hover image-text__img-behind image-text__img-behind--right"
        :class="{ 'view': this.imageVisible }"
        v-scroll:in="() => { this.imageVisible = true; this.loaded = true }"
        v-scroll:out="() => this.imageVisible = false">
        <div class="fade-in-child fade-in--bottom box-shadow">
          <background-image
            v-if="imageTextData.image.url"
            :scale="'4-3'"
            :url="imageTextData.image.url"
            :width="imageTextData.image.width"
            :height="imageTextData.image.height"
            :key="imageTextData.image.url"
            :title="imageTextData.image.title || 'dtm.com'"
            :loaded="this.loaded"/>
        </div>
      </div>
    </div>
    <div class="row padding--top-4 padding--bottom-4" v-if="imageTextData.alignImage === 'Left'">
      <div
        class="col-12 col-sm-6 bgimg--hover image-text__img-behind image-text__img-behind--left"
        :class="{ 'view': this.imageVisible }"
        v-scroll:in="() => { this.imageVisible = true; this.loaded = true }"
        v-scroll:out="() => this.imageVisible = false">
        <div class="fade-in-child fade-in--bottom box-shadow">
          <background-image
            v-if="imageTextData.image.url"
            :scale="'4-3'"
            :url="`https://media.graphassets.com/resize=w:1200,fit:crop/quality=value:70/compress/${imageTextData.image.handle}`"
            :width="imageTextData.image.width"
            :height="imageTextData.image.height"
            :key="imageTextData.image.url"
            :title="imageTextData.image.title || 'dtm.com'"
            :loaded="this.loaded"/>
        </div>
      </div>
      <div
        class="col-12 col-sm-6"
        :class="{ 'view': this.textVisible }"
        v-scroll:in="() => { this.textVisible = true; this.loaded = true }"
        v-scroll:out="() => this.textVisible = false">
        <div class="fade-in-child fade-in--right" v-html="imageTextData.text.html"/>
      </div>
    </div>
  </div>
</template>

<script>
import AppConst from '../config/app.const';
import BackgroundImage from './layout/BackgroundImage';
import Settings from '../config/settings';

export default {
  name: 'ImageText',
  components: { BackgroundImage },
  props: {
    id: String,
    dataObj: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      imageTextData: null,
      loaded: false,
      imageVisible: false,
      textVisible: false
    };
  },
  methods: {
    async fetchModuleImageText () {
      await this.$store.dispatch(AppConst.FETCH_PATH_NAME, {
        path: `data?query=moduleImageText&id=${this.id}`,
        lifetime: Settings.LIFETIME_DEFAULT
      })
        .then(response => {
          this.imageTextData = response.moduleImageText;
        });
    }
  },
  created () {
    if (this.id) {
      this.fetchModuleImageText();
    }
    if (this.dataObj !== null) {
      this.imageTextData = this.dataObj;
    }
  }
};
</script>

<style lang="scss" scoped>
  .image-text {
    &__img-behind {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      &--left {
        &::after {
          bottom: -30px;
          right: 0;
          left: -15px;
        }
      }
      &--right {
        &::after {
          bottom: -30px;
          right: -15px;
        }
      }
      &::after {
        content: '';
        background: $color-bg;
        position: absolute;
        width: 40%;
        height: 90%;
        z-index: -1;
      }
    }
  }
  @media all and (max-width: map_get($grid-breakpoints, 'md')) {
    .image-text {
      &__img-behind {
        &::after {
          display: none;
        }
      }
    }
  }
</style>

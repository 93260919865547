<template>
  <div>
    <div class="container-fluid bg-white">
      <h2 class="text-uppercase text-italic text-dark" v-if="data.headline" v-html="data.headline"></h2>
      <div v-if="data.richText && data.richText.html" v-html="data.richText.html" class="prone mt-1 mb-1"></div>
      <div v-html="data.multiText"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HtmlContent',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<template>
    <div class="text-align--center padding--top-2 padding--bottom-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="180" height="49" viewBox="0 0 180 49" class="loader-svg" :class="{ 'loader-svg--inverted': inverted }">
        <rect width="100%" height="100%" mask="url(#dtm-logo)" />
      </svg>
    </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    inverted: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss">
  .loader-svg {
    rect {
      fill: rgba(255, 255, 255, 0.3);
      animation: loader-svg 2s infinite;
    }
    &--inverted {
      rect {
        fill: rgba(0, 0, 0, 0.3);
        animation: loader-svg 2s infinite;
      }
    }
  }
  @keyframes loader-svg {
    0% {
      fill-opacity: 1;
    }
    50% {
      fill-opacity: 0.2;
    }
    100% {
      fill-opacity: 1;
    }
  }
</style>
